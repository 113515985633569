import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from 'src/app/authentication/token.service';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private tokenService: TokenService,
        private notificationsService: NotificationsService,
        ) {
        }

        intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            if (!request.url.endsWith('api/login/authenticate')) {
                request = request.clone({
                    setHeaders: {
                        'Authorization': `Bearer ${this.tokenService.getToken()}`,
                        'y': ''
                    }
                });
            }

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(request, next, error);
                } else {
                    return throwError(error);
                }
            }),
            tap((event) => {
                if (event instanceof HttpResponse) {
                  if (event.headers.get('t') !== null && event.headers.get('t') !== '') {
                    this.tokenService.setToken(event.headers.get('t'));
                  }
                }
            })
        );
    }

        private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
            if (!request.url.endsWith('api/login/authenticate') &&
            !request.url.endsWith('api/login/authenticateExt')) {
                this.tokenService.setLoggedOut();
                this.router.navigate(['/authentication/login']);
                if ($('.warn').length === 0) {
                    this.notificationsService.warn('Alerta', 'El tiempo de sesion ha expirado');
                }
                return next.handle(request);
            } else {
                return throwError(error);
            }
        }
    }
    