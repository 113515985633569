import { Component, Input, OnInit, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { Observable, of } from 'rxjs';
import { FormGroup, FormGroupDirective, FormBuilder, Validators, FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import { TipoIdentificacion, TipoPersona } from 'src/app/shared/utils/general-enums-provider.enum';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { TokenService } from 'src/app/authentication/token.service';

declare var $: any;

@Component({
  selector: 'app-info-basica',
  templateUrl: './info-basica.component.html',
  styleUrls: ['./info-basica.component.css']
})
export class InfoBasicaComponent implements OnInit, OnChanges, OnDestroy {

  @Input() form: FormGroup;
  @Input() showTipoEmpresa: boolean;
  @Input() infoBasica: Observable<any>;
  @Input() isEdit: boolean;
  @Input() formDirective: FormGroupDirective;

  Data: any;
  InfoBasica: FormGroup;
  InfoBasicaArray: FormArray;
  tiposPersona$: Observable<any>;
  tiposIdentificacion$: Observable<any>;
  tiposEmpresa$: Observable<any>;

  tiposIdentificacionList: any;

  constructor(
    private dataApiClient: DataApiClientService,
    private formBuilder: FormBuilder,
    private tokenService: TokenService
  ) { }

  ngOnInit() {
    this._LoadSelectList();
    this._InitBasicsForm();
    this._RequireTipoEmpresa();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const infoBasica: SimpleChange = changes.infoBasica;

    if (infoBasica !== undefined && infoBasica.previousValue !== undefined) {
      this._PreloadData();
    }
  }

  ngOnDestroy(): void {
    this.InfoBasicaArray.removeAt(this.InfoBasicaArray.length - 1);
    this.InfoBasicaArray.reset();
  }

  get f() { return this.InfoBasica.controls; }

  IsValid(control: string): boolean {
    return this.InfoBasica.controls[control].valid;
  }

  getTiposPersona(): void {
    this.tiposPersona$ = this.dataApiClient.getTiposPersona();
  }

  getTiposIdentificacion(): void {
    this.tiposIdentificacion$ = this.dataApiClient.getTiposIdentificacion().pipe(
      map(ti => this.tiposIdentificacionList = ti)
    );
  }

  getTiposEmpresa() {
    this.tiposEmpresa$ = this.dataApiClient.getTiposEmpresa();
  }

  public onChangeTipoPersona(event: Event): void {
    const tipoPersona = Number.parseInt(this.f.TipoPersona.value, 10);
    if (tipoPersona === TipoPersona.JURIDICA) {
      this.tiposIdentificacion$ = of(this.tiposIdentificacionList.filter(f => f.IDTIPOIDENTIFICACION === TipoIdentificacion.NIT));
      this.f.PrimerNombre.setValidators([]);
      this.f.PrimerApellido.setValidators([]);
      this.f.RazonSocial.setValidators([Validators.required]);
    } else {
      this.tiposIdentificacion$ = of(this.tiposIdentificacionList.filter(f => f.IDTIPOIDENTIFICACION !== TipoIdentificacion.NIT));
      this.f.PrimerNombre.setValidators([Validators.required]);
      this.f.PrimerApellido.setValidators([Validators.required]);
      this.f.RazonSocial.setValidators([]);
    }
    this.f.PrimerNombre.updateValueAndValidity();
    this.f.PrimerApellido.updateValueAndValidity();
    this.f.RazonSocial.updateValueAndValidity();
  }

  public onChangeTipoIdentificacion(event: Event): void {
    const tipoId = Number.parseInt(this.f.TipoIdentificacion.value, 10);
    if (tipoId === TipoIdentificacion.NIT || tipoId === TipoIdentificacion.RUT) {
      this.f.DigitoVerificacion.setValidators([Validators.min(0), Validators.max(9), Validators.required]);
    } else {
      this.f.DigitoVerificacion.setValidators([Validators.min(0), Validators.max(9)]);
    }

    this.f.DigitoVerificacion.updateValueAndValidity();
  }

  habilitarSelect(isEdit: boolean, event: Event) {
    if (isEdit) {
      const el: HTMLSelectElement = event.target as HTMLSelectElement;
      $(el.options).prop('disabled', true);
      return false;
    }
  }

  onKeyPressNumber(event) {
    return (/[0-9]/.test(String.fromCharCode(event.which)));
  }

  private _LoadSelectList() {

    this.getTiposPersona();
    this.getTiposIdentificacion();
    this.getTiposEmpresa();
  }

  private _InitBasicsForm() {
    this.InfoBasicaArray = this.form.controls.InfoBasica as FormArray;
    const arrayLength = this.InfoBasicaArray.length;
    this.InfoBasica = this.formBuilder.group({
      IdInfoBasica: ['', []],
      TipoPersona: [null, [Validators.required]],
      TipoIdentificacion: [null, [Validators.required]],
      NumeroIdentificacion: ['', [
        Validators.required,
        Validators.maxLength(12)]
      ],
      DigitoVerificacion: ['', [Validators.max(9), Validators.min(0)]],
      RazonSocial: ['', []],
      Sigla: ['', []],
      PrimerNombre: ['', []],
      SegundoNombre: ['', []],
      PrimerApellido: ['', []],
      SegundoApellido: ['', []],
      NombreComercial: ['', []],
      TipoEmpresa: [null, []],
      IdUbicacion: ['', []]
    });

    this.InfoBasicaArray.insert(arrayLength, this.InfoBasica);
  }

  _RequireTipoEmpresa() {
    if (this.showTipoEmpresa) {
      this.f.TipoEmpresa.setValidators(Validators.required);
    } else {
      this.f.TipoEmpresa.clearValidators();
    }
    this.f.TipoEmpresa.updateValueAndValidity();
  }

  private _PreloadData() {
    if (this.isEdit) {
      const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
      
      this.infoBasica.subscribe(
        (resInfo: Array<any>) => {
          this.InfoBasica.setValue(
            {
              IdInfoBasica: resInfo[0].IDINFOBASICA,
              TipoPersona: Number(resInfo[0].IDTIPOPERSONA),
              TipoIdentificacion: resInfo[0].IDTIPOIDENTIFICACION,
              NumeroIdentificacion: resInfo[0].NUMEROIDENTIFICACION,
              DigitoVerificacion: resInfo[0].DIGITOVERIFICACION,
              RazonSocial: resInfo[0].RAZONSOCIAL,
              Sigla: resInfo[0].SIGLA,
              PrimerNombre: resInfo[0].PRIMERNOMBRE,
              SegundoNombre: resInfo[0].SEGUNDONOMBRE,
              PrimerApellido: resInfo[0].PRIMERAPELLIDO,
              SegundoApellido: resInfo[0].SEGUNDOAPELLIDO,
              NombreComercial: proveedor.NOMBRECOMERCIAL,
              TipoEmpresa: proveedor.IDTIPOEMPRESA,
              IdUbicacion: resInfo[0].IDUBICACION
            }
          );
          this.onChangeTipoIdentificacion(new Event('change'));
        }
      );
    }
  }
}
