export class DeclaracionConflictos {
    IDDDECLARACIONCONFLICTOS: number;
    ORGADMINENTIDAD: string;
    ORGADMINCARGO: string;
    PARTICIPACIONCAPITAL: string;
    RELNEGNOMBRE: string;
    RELNEGEMPRESA: string;
    RELNEGTIPORELACION: string;
    VINFAMNOMBRE: string;
    VINFAMCARGO: string;
    VINFAMCLASEVINCULO: string;
    USUARIO_CREACION: string;
    USUARIO_ACTUALIZACION: string;

    constructor(
        IDDDECLARACIONCONFLICTOS?: number,
        ORGADMINENTIDAD?: string,
        ORGADMINCARGO?: string,
        PARTICIPACIONCAPITAL?: string,
        RELNEGNOMBRE?: string,
        RELNEGEMPRESA?: string,
        RELNEGTIPORELACION?: string,
        VINFAMNOMBRE?: string,
        VINFAMCARGO?: string,
        VINFAMCLASEVINCULO?: string,
        USUARIO_CREACION?: string,
        USUARIO_ACTUALIZACION?: string,
    ) {
        this.IDDDECLARACIONCONFLICTOS = (IDDDECLARACIONCONFLICTOS) ? IDDDECLARACIONCONFLICTOS : null;
        this.USUARIO_CREACION = (USUARIO_CREACION) ? USUARIO_CREACION : null;
        this.ORGADMINENTIDAD = (ORGADMINENTIDAD) ? ORGADMINENTIDAD : null;
        this.ORGADMINCARGO = (ORGADMINCARGO) ? ORGADMINCARGO : null;
        this.PARTICIPACIONCAPITAL = (PARTICIPACIONCAPITAL) ? PARTICIPACIONCAPITAL : null;
        this.RELNEGNOMBRE = (RELNEGNOMBRE) ? RELNEGNOMBRE : null;
        this.RELNEGEMPRESA = (RELNEGEMPRESA) ? RELNEGEMPRESA : null;
        this.RELNEGTIPORELACION = (RELNEGTIPORELACION) ? RELNEGTIPORELACION : null;
        this.VINFAMNOMBRE = (VINFAMNOMBRE) ? VINFAMNOMBRE : null;
        this.VINFAMCARGO = (VINFAMCARGO) ? VINFAMCARGO : null;
        this.VINFAMCLASEVINCULO = (VINFAMCLASEVINCULO) ? VINFAMCLASEVINCULO : null;
        this.USUARIO_CREACION = (USUARIO_CREACION) ? USUARIO_CREACION : null;
        this.USUARIO_ACTUALIZACION = (USUARIO_ACTUALIZACION) ? USUARIO_ACTUALIZACION : null;
    }
}


