import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/app-config.service';
import { share } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CotizacionApiCLientService {
  api: any;
  cotizacionoObj: any;
  solpedObj: any;
  comentarioObj: any;

 constructor(
    private http: HttpClient,
    private configService: ConfigService) {
      this.api = this.configService.get('api');
      this.cotizacionoObj = this.api.endpoints.filter(c => c.Cotizacion)[0];
      this.solpedObj = this.api.endpoints.filter(c => c.solped)[0];
      this.comentarioObj = this.api.endpoints.filter(c => c.Comentario)[0];
    }

    getCotizacion(IDPROVEEDOR: number): Observable<any> {
      const getCotizacion = this.cotizacionoObj.Cotizacion.filter((m: any) => m.name === 'getCotizacion')[0];
      return this.http.get(`${this.api.basepath}${getCotizacion.url}?IDPROVEEDOR=${IDPROVEEDOR}`);
    }

    getVisitaProveedorSolped(form: any): Observable<any> {
      const getVisitaProveedorSolped = this.solpedObj.solped.filter((m: any) => m.name === 'getVisitaProveedorSolped')[0];
      return this.http.post(`${this.api.basepath}${getVisitaProveedorSolped.url}`, form).pipe(
        share()
      );
    }

    getCotizacionSolped(form: any): Observable<any> {
      const getCotizacionSolped = this.cotizacionoObj.Cotizacion.filter((m: any) => m.name === 'getCotizacionSolped')[0];
      return this.http.post(`${this.api.basepath}${getCotizacionSolped.url}`, form);
    }

    getDocumentosSolped(IDSOLPED: number): Observable<any> {
      const getDocumentosSolped = this.solpedObj.solped.filter((m: any) => m.name === 'getDocumentosSolped')[0];
      return this.http.get(`${this.api.basepath}${getDocumentosSolped.url}?IDSOLPED=${IDSOLPED}`);
    }
    getArchivoAdjSolpedBySolped(IDSOLPED: number): Observable<any> {
      const getArchivoAdjSolpedBySolped = this.solpedObj.solped.filter((m: any) => m.name === 'getArchivoAdjSolpedBySolped')[0];
      return this.http.get(`${this.api.basepath}${getArchivoAdjSolpedBySolped.url}?IDSOLPED=${IDSOLPED}`);
    }
    getListadoComentarios(IDSOLPED: number): Observable<any> {
      const getListadoComentarios = this.comentarioObj.Comentario.filter((m: any) => m.name === 'getListadoComentarios')[0];
      return this.http.get(`${this.api.basepath}${getListadoComentarios.url}?IDSOLPED=${IDSOLPED}`);
    }

    createCotizacion(form: any): Observable<any> {
      const createCotizacion = this.cotizacionoObj.Cotizacion.filter((m: any) => m.name === 'createCotizacion')[0];
      return this.http.post(`${this.api.basepath}${createCotizacion.url}`, form);
    }

    updateCotizacion(form: any): Observable<any> {
      const updateCotizacion = this.cotizacionoObj.Cotizacion.filter((m: any) => m.name === 'updateCotizacion')[0];
      return this.http.post(`${this.api.basepath}${updateCotizacion.url}`, form);
    }

    deleteDocumentoCotizacion(id: number): Observable<any> {
      const deleteDocumentoCotizacion = this.solpedObj.solped.filter((m: any) => m.name === 'deleteDocumentoCotizacion')[0];
      return this.http.post(`${this.api.basepath}${deleteDocumentoCotizacion.url}?iddocumentocotizacion=${id}`, id);
    }

}
