import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup
} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  /**
   * Validates email address
   *
   * @param formControl Control to validate with email RegExp
   */
  public validateEmail(formControl: FormControl): { [error: string]: any } {
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEXP.test(formControl.value) ? null : { validateEmail: { valid: false } };
  }

  /**
   * Validates required numeric values
   *
   * @param formControl Control to require numeric value
   */
  public numericRequired(formControl: FormControl): { [error: string]: any } {
    return (formControl.value && formControl.value > 0) ? null : { numericRequired: { valid: false } };
  }

}

/**
 * Validates matching string values
 *
 * @param controlKey control to match
 * @param matchingControlKey control must match
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
