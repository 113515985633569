import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { Observable, Subscription, EMPTY, of } from 'rxjs';
import { InfoBasica } from '../../shared/models/info-basica';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, FormArray } from '@angular/forms';
import { EstadoProveedor } from 'src/app/shared/utils/general-enums-provider.enum';
import { NotificationsService } from 'angular2-notifications';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { Ubicacion } from 'src/app/shared/models/ubicacion';
import { Usuario } from 'src/app/shared/models/usuario';
import { finalize, map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/app-config.service';
import { ProveedorApiClientService } from 'src/app/gestion-proveedor/proveedor-api-client.service';
import { TipoProveedor } from '../../shared/utils/general-enums-provider.enum';

declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit, OnDestroy {
  imgRoot: any;

  preRegisterForm: FormGroup;
  @ViewChild('ngForm') formDirective: FormGroupDirective;

  submitted: boolean;

  tiposProveedor: Array<any>;
  documentosTiposProveedor$: Observable<any>;

  showDatosUsuario: boolean;
  showTipoEmpresa: boolean;
  showInfoBasica: boolean;
  showUbicacion: boolean;

  telefonoCelular: any;
  getProveedorRequest$: Observable<any>;
  tiposProveedor$: Observable<any>;

  insertProveedorBasico$: Observable<any>;
  insertProveedorConUsuarioRequest$: Observable<any>;
  insertProveedorConCuentaRequest$: Observable<any>;

  updateProveedorBasico$: Observable<any>;
  sendNovedad$: Observable<any>;
  updateProveedorConUsuarioRequest$: Observable<any>;
  updateProveedorConCuentaRequest$: Observable<any>;

  isActive$: Observable<boolean>;
  InfoBasica$: Observable<InfoBasica>;
  Ubicacion$: Observable<any>;
  cuentaUsuario$: Observable<any>;
  cuentaBancaria$: Observable<any>;
  fileNames: Array<string> = [];

  private subscriptions: Array<Subscription> = [];
  textoBoton: string;
  showUsuariosForm: any;

  constructor(
    private proveedorApiClient: ProveedorApiClientService,
    private notificationsService: NotificationsService,
    private dataApiClient: DataApiClientService,
    private formBuilder: FormBuilder,
    private router: Router,
    private configService: ConfigService
  ) {
    this.imgRoot = this.configService.get('paths').images;
  }

  ngOnInit() {
    this.showInfoBasica = true;
    this.showDatosUsuario = true;
    this.showTipoEmpresa = true;
    this.showUbicacion = false;
    this.submitted = false;
    this.isActive$ = of(false);
    this.textoBoton = 'Registrar';
    this.InfoBasica$ = EMPTY;
    this._InitPreRegisterForm();
    this.getTiposProveedor();
  }

  getTiposProveedor(): void {
    this.tiposProveedor$ = this.dataApiClient.getTiposProveedor().pipe(
      map(items => items.filter(
         d => (d.IDTIPOPROVEEDOR === TipoProveedor.CLIENTE_DEUDOR ||
          d.IDTIPOPROVEEDOR === TipoProveedor.PROVEEDOR)
       ))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get f() {
    return this.preRegisterForm.controls;
  }

  IsValid(control: string): boolean {
    return this.preRegisterForm.get(control).valid;
  }

  doShowUbicacion(estadoUbicacion: boolean) {
    if (!this.f.TipoProveedor.invalid) {
      this.showUbicacion = estadoUbicacion;
      this.showInfoBasica = !estadoUbicacion;
      this.refreshWisard();
    }
    this.submitted = true;
  }

  doShowNextForm(estadoNext: boolean) {
    if (!this.f.TipoProveedor.invalid) {
      if (estadoNext) {
        this.showUsuariosForm = true;
      } else {
        this.showUsuariosForm = false;
      }
      this.showUbicacion = !estadoNext;
      this.refreshWisard();
    }
  }

  refreshWisard() {
    if (this.showInfoBasica) {
      $('#bootstrap-wizard-1').find('li').removeClass('active');
      $($('#bootstrap-wizard-1').find('li')[0]).addClass('active');
    }
    if (this.showDatosUsuario) {
      $('#bootstrap-wizard-1').find('li').removeClass('active');
      $($('#bootstrap-wizard-1').find('li')[2]).addClass('active');
    }
    if (this.showUbicacion) {
      $('#bootstrap-wizard-1').find('li').removeClass('active');
      $($('#bootstrap-wizard-1').find('li')[1]).addClass('active');
    }
  }

  AutorTratDatos() {
    if ($('#cbkAcept').is(':checked')) {
      $('#exampleModal').modal('hide');
      this.notificationsService.success('Exito', 'Debe aceptar la política de tratamiento de datos personales');
    } else {
      this.notificationsService.warn('Alerta', 'Debe aceptar la política de tratamiento de datos personales');
    }
  }

  enableButton(target: any) {
    const iddocumento = $(target).attr('id').substring(2);
    $('#btn' + iddocumento).prop('disabled', false);
  }
  createProveedor() {
    this.submitted = true;
    if (this.preRegisterForm.invalid) {
      this.submitted = false;
      this.notificationsService.warn('Alerta', 'Se encontraron errores en el formulario, por favor valide e intente de nuevo');
      return;
    }

    const PROVEEDOR: Proveedor = this._MapProveedorForm();
    const INFO_BASICA: InfoBasica = this._MapInfoBasicaForm();
    const UBICACION: Ubicacion = this._MapUbicacionForm();
    const USUARIO: Usuario = (this.showDatosUsuario) ? this._MapUsuarioForm() : null;

    this.getProveedorRequest$ = this.proveedorApiClient.GetProveedorById(INFO_BASICA.NUMEROIDENTIFICACION);
    this.getProveedorRequest$.subscribe(
      (responseIdUsusario: any) => {
        if (responseIdUsusario.length > 0) {
          this.notificationsService.error('El proveedor ya se encuentra registrado en el sistema, si no recuerda sus datos ' +
            'de acceso por favor dirigirse a la opción de <b>Olvide datos de acceso</b> para realizar el proceso');
          this.submitted = false;
          this.showInfoBasica = true;
          return;
        } else {
          this._CrearProveedor(PROVEEDOR, INFO_BASICA, UBICACION, USUARIO);
        }
      }
    );

  }

  onKeyPressNumber(event) {
    return (/[0-9]/.test(String.fromCharCode(event.which)));
  }

  resetForm() {
    $('input').each(function () {
      $($(this)[0]).val('');
    });
    $('select').not('[name="idpais"]').each(function () {
      $($(this)[0]).val('-1');
    });
    this.showInfoBasica = true;
    this.showDatosUsuario = true;
    this.showTipoEmpresa = true;
    this.formDirective.resetForm();
    this.preRegisterForm.reset();

    this.preRegisterForm.controls.InfoBasica.reset();
    this.preRegisterForm.controls.Ubicacion.reset();
    this.preRegisterForm.controls.CuentaUsuario.reset();
    this.preRegisterForm.controls.CuentaBancaria.reset();

    this.documentosTiposProveedor$ = null;
  }

  private _InitPreRegisterForm() {
    this.preRegisterForm = this.formBuilder.group({
      IdProveedor: ['', []],
      TipoProveedor: [null, [Validators.required]],
      InfoBasica: this.formBuilder.array([]),
      Ubicacion: this.formBuilder.array([]),
      CuentaUsuario: this.formBuilder.array([]),
      CuentaBancaria: this.formBuilder.array([])
    });
  }

  private _MapProveedorForm(): Proveedor {
    const INFO_BASICA_FORM = JSON.parse(JSON.stringify((this.f.InfoBasica as FormArray).getRawValue()[0]));
    const UBICACION_FORM = JSON.parse(JSON.stringify((this.f.Ubicacion as FormArray).getRawValue()[0]));

    let CUENTA_USUARIO_FORM;
    if (this.showDatosUsuario) {
      CUENTA_USUARIO_FORM = JSON.parse(JSON.stringify((this.f.CuentaUsuario as FormArray).getRawValue()[0]));
    }
    const PROVEEDOR: Proveedor = new Proveedor(
      this.f.IdProveedor.value,                                                         // IDPROVEEDOR
      this.f.TipoProveedor.value,                                   // IDTIPOPROVEEDOR
      INFO_BASICA_FORM.NombreComercial != null ?
      (INFO_BASICA_FORM.NombreComercial + '').toUpperCase() : '',                // NOMBRECOMERCIAL
      (this.showDatosUsuario) ? CUENTA_USUARIO_FORM.Email : null,   // CORREO
      UBICACION_FORM.TelefonoCelular,                               // TELEFONOCELULAR
      null,                                                         // IDINFOBASICA
      null,                                                         // IDUSUARIO
      EstadoProveedor.PREREGISTRO,                                  // IDESTADO
      null,                                                         // IDCLASEPROVEEDOR
      null,                                                         // IDINFOJURIDICA
      null,                                                         // IDINFOFINANCIERA
      null,                                                         // CODIGO_CIIU
      null,                                                         // IDCUENTABANCARIA
      0,                                                            // DECLARACIONCONFLICTOS
      null,                                                         // IDDECLARACIONCONFLICTOS
      0,                                                            // AUTORIZACIONDATOS
      null,                                                         // IDUSUARIORESPONSABLE
      0,                                                            // HABILITADOREGISTRO
      'sistema',                                                    // USUARIO_CREACION
      null,                                                         // USUARIO_ACTUALIZACION
      (this.showTipoEmpresa) ? INFO_BASICA_FORM.TipoEmpresa : null, // IDTIPOEMPRESA
      this.fileNames                                                // FILES
    );

    return PROVEEDOR;
  }

  private _MapInfoBasicaForm(): InfoBasica {
    const INFO_BASICA_FORM = JSON.parse(JSON.stringify((this.f.InfoBasica as FormArray).getRawValue()[0]));

    const INFOBASICA: InfoBasica = new InfoBasica(
      INFO_BASICA_FORM.IdInfoBasica, // IDINFOBASICA: number;
      INFO_BASICA_FORM.TipoPersona, // IDTIPOPERSONA: number;
      INFO_BASICA_FORM.TipoIdentificacion, // IDTIPOIDENTIFICACION: number;
      INFO_BASICA_FORM.NumeroIdentificacion.trim(), // NUMEROIDENTIFICACION: string;
      INFO_BASICA_FORM.DigitoVerificacion, // DIGITOVERIFICACION: string;
      INFO_BASICA_FORM.RazonSocial != null ? (INFO_BASICA_FORM.RazonSocial + '').toUpperCase() : '', // RAZONSOCIAL: string;
      INFO_BASICA_FORM.Sigla != null ? (INFO_BASICA_FORM.Sigla + '').toUpperCase() : '', // SIGLA: string;
      INFO_BASICA_FORM.PrimerNombre != null ? (INFO_BASICA_FORM.PrimerNombre + '').toUpperCase() : '', // PRIMERNOMBRE: string;
      INFO_BASICA_FORM.SegundoNombre != null ? (INFO_BASICA_FORM.SegundoNombre + '').toUpperCase() : '', // SEGUNDONOMBRE: string;
      INFO_BASICA_FORM.PrimerApellido != null ? (INFO_BASICA_FORM.PrimerApellido + '').toUpperCase() : '', // PRIMERAPELLIDO: string;
      INFO_BASICA_FORM.SegundoApellido != null ? (INFO_BASICA_FORM.SegundoApellido + '').toUpperCase() : '', // SEGUNDOAPELLIDO: string;
      INFO_BASICA_FORM.IdUbicacion, // IDUBICACION: number;
      '', // CONTACTO: string;
      'sistema', // USUARIO_CREACION: string;
      null, // FECHA_CREACION: string;
      'sistema', // USUARIO_ACTUALIZACION: string;
      null // FECHA_ACTUALIZACION: string;
    );

    return INFOBASICA;
  }

  private _MapUbicacionForm(): Ubicacion {
    const UBICACION_FORM = JSON.parse(JSON.stringify((this.f.Ubicacion as FormArray).getRawValue()[0]));
    const UBICACION: Ubicacion = new Ubicacion(
      UBICACION_FORM.IdUbicacion,                                                 // IDUBICACION
      UBICACION_FORM.Abreviatura,                           // IDABREVIATURA
      UBICACION_FORM.Numero1 != null ? (UBICACION_FORM.Numero1 + '').toUpperCase() : '',                               // NUMERO1
      UBICACION_FORM.Letra1 != null ? (UBICACION_FORM.Letra1 + '').toUpperCase() : '',                                // LETRA1
      UBICACION_FORM.Numero2 != null ? (UBICACION_FORM.Numero2 + '').toUpperCase() : '',                               // NUMERO2
      UBICACION_FORM.Letra2 != null ? (UBICACION_FORM.Letra2 + '').toUpperCase() : '',                                // LETRA2
      UBICACION_FORM.Numero3 != null ? (UBICACION_FORM.Numero3 + '').toUpperCase() : '',                               // NUMERO3
      UBICACION_FORM.Continuidad != null ? (UBICACION_FORM.Continuidad + '').toUpperCase() : '',                           // CONTINUIDAD
      UBICACION_FORM.Ciudad,                                // IDCIUDAD
      UBICACION_FORM.Departamento,                          // IDDEPARTAMENTO
      UBICACION_FORM.Pais,                                  // IDPAIS
      UBICACION_FORM.TelefonoFijo,                          // TELEFONOFIJO
      'sistema',                                            // USUARIO_CREACION
      null,                                                 // USUARIO_ACTUALIZACION
      UBICACION_FORM.Estado != null ? (UBICACION_FORM.Estado + '').toUpperCase() : '',                                // ESTADO
      UBICACION_FORM.Contacto != null ? (UBICACION_FORM.Contacto + '').toUpperCase() : ''                                // ESTADO
    );
    return UBICACION;
  }

  private _MapUsuarioForm(): Usuario {
    const INFO_BASICA_FORM = JSON.parse(JSON.stringify((this.f.InfoBasica as FormArray).getRawValue()[0]));
    const CUENTA_USUARIO_FORM = JSON.parse(JSON.stringify((this.f.CuentaUsuario as FormArray).getRawValue()[0]));
    const USUARIO: Usuario = new Usuario(
      CUENTA_USUARIO_FORM.IdUsuario,                                                 // IDUSUARIO
      INFO_BASICA_FORM.NumeroIdentificacion.trim(),                // NOM_USUARIO
      CUENTA_USUARIO_FORM.Email,                            // CORREO
      CUENTA_USUARIO_FORM.Password,                         // CONTRASENA
      1,                                                    // IDESTADOEstadoProveedor
      'sistema',                                            // USUARIO_CREACION
      null,                                                 // USUARIO_ACTUALIZACION
      INFO_BASICA_FORM.NumeroIdentificacion.trim()                 // NUMEROIDENTIFICACION
    );
    return USUARIO;
  }

  private _CrearProveedor(
    ProveedorForm: Proveedor,
    InfoBasicaForm: InfoBasica,
    UbicacionForm: Ubicacion,
    CuentaUsuarioForm: Usuario
  ) {

    if (this.showDatosUsuario) {
      ProveedorForm.UBICACION = UbicacionForm;
      ProveedorForm.USUARIO = CuentaUsuarioForm;
      ProveedorForm.INFOBASICA = InfoBasicaForm;

      this.insertProveedorConUsuarioRequest$ = this.proveedorApiClient.CreateProveedor(ProveedorForm).pipe(
        finalize(
          () => {
            this.resetForm();
          }
        )
      );

      this.subscriptions.push(this.insertProveedorConUsuarioRequest$.subscribe(
        (result: any) => {
          if (result) {
            this.notificationsService.success('Exito', 'Registro culminado con éxito');
            this.router.navigate(['/authentication/login']);
            this.resetForm();
          } else {
            this.notificationsService.success('Error', 'Se encontró un problema al insertar el proveedor');
          }
        }
      ));
    }
  }

  showModalCancel() {
    $('#CancelModal').modal('show');
  }

  ConfirmCancel() {
    $('#CancelModal').modal('hide');
    this.router.navigate(['/authentication/login']);
  }
}

