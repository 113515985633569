export class Usuario {
    IDUSUARIO: number;
    NOM_USUARIO: string;
    CORREO: string;
    CONTRASENA: string;
    IDESTADOUSUARIO: number;
    USUARIO_CREACION: string;
    USUARIO_ACTUALIZACION: string;
    NUMEROIDENTIFICACION: string;

    constructor(
        IDUSUARIO?: number,
        NOM_USUARIO?: string,
        CORREO?: string,
        CONTRASENA?: string,
        IDESTADOUSUARIO?: number,
        USUARIO_CREACION?: string,
        USUARIO_ACTUALIZACION?: string,
        NUMEROIDENTIFICACION?: string
    ) {
        this.IDUSUARIO = (IDUSUARIO) ? IDUSUARIO : null;
        this.NOM_USUARIO = (NOM_USUARIO) ? NOM_USUARIO : null;
        this.CORREO = (CORREO) ? CORREO : null;
        this.CONTRASENA = (CONTRASENA) ? CONTRASENA : null;
        this.IDESTADOUSUARIO = (IDESTADOUSUARIO) ? IDESTADOUSUARIO : null;
        this.USUARIO_CREACION = (USUARIO_CREACION) ? USUARIO_CREACION : null;
        this.USUARIO_ACTUALIZACION = (USUARIO_ACTUALIZACION) ? USUARIO_ACTUALIZACION : null;
        this.NUMEROIDENTIFICACION = (NUMEROIDENTIFICACION) ? NUMEROIDENTIFICACION : null;
    }
}
