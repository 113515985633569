import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { DataApiClientService } from 'src/app/shared/utils/data-api-client.service';
import { map } from 'rxjs/operators';
import { PersonaJuridica } from '../../shared/models/personajuridica.module';

@Component({
  selector: 'app-persona-juridica',
  templateUrl: './persona-juridica.component.html',
  styleUrls: ['./persona-juridica.component.sass']
})
export class PersonaJuridicaComponent implements OnInit {
  personaJuridicaForm: FormGroup;
  PersonaJuridicaArray: FormArray;
  tiposIdentificacion$: Observable<any>;
  tiposIdentificacionAll$: Observable<any>;
  constructor(private dataApiClient: DataApiClientService, private formBuilder: FormBuilder) { }
  controles: any;
  tiposIdentificacionList: any;

  @Input() form: FormGroup;
  @Input() formDirective: FormGroupDirective;
  @Input() formBase: any;
  @Input() personaJuridica: PersonaJuridica;
  ngOnInit() {
    this._InitPersonaJuridicaForm();
    this.getTiposIdentificacion();
  }

  updateTiposIdentificacion($event) {
    if (($event.target.value.toUpperCase()).includes('representante'.toUpperCase())) {
      this.tiposIdentificacion$ = this.tiposIdentificacion$.pipe(
        map(ti => ti.filter( t => t.IDTIPOIDENTIFICACION !== 7 && t.IDTIPOIDENTIFICACION !== 8))
      );
    } else {
      this.tiposIdentificacion$ = this.tiposIdentificacionAll$;
    }
  }

  getTiposIdentificacion(): void {
    this.tiposIdentificacion$ = this.dataApiClient.getTipoIdentificacionPersona().pipe(
      map(ti => this.tiposIdentificacionList = ti)
    );
    this.tiposIdentificacionAll$ = this.tiposIdentificacion$;
  }

  private _InitPersonaJuridicaForm() {
    this.controles = [
      'idPersonaJuridica',
      'parteInteresada',
      'nombrePersona',
      'tipoIdentificacion',
      'numeroIdentificacion',
      'porcentajeParticipacion'
      ];

    this.PersonaJuridicaArray = this.form.controls.PersonaJuridica as FormArray;
    const arrayLength = this.PersonaJuridicaArray.length;
    const ControlIdPersonaJuridica: FormControl = new FormControl(this.personaJuridica.IDPERSONAJURIDICA, []);
    const ControlNombre: FormControl = new FormControl(this.personaJuridica.NOMBRE, [Validators.required, Validators.maxLength(100)]);
    const ControlNumeroIdentificacion: FormControl = new FormControl(this.personaJuridica.NUMEROIDENTIFICACION,
      [Validators.required, Validators.maxLength(20)]);
    const ControlPorcentaje: FormControl = new FormControl(this.personaJuridica.PORCENTAJEPARTICIPACION,
       [Validators.required, Validators.maxLength(5), Validators.pattern(/^[0-9]*,?[0-9]+$/) ]);
    const ControlParteInteresada: FormControl = new FormControl(this.personaJuridica.PARTEINTERESADA, [Validators.required]);
    const ControlTipoIndentificacion: FormControl = new FormControl(this.personaJuridica.IDTIPOIDENTIFICACION, [Validators.required]);
    this.personaJuridicaForm = this.formBuilder.group({});
    this.personaJuridicaForm.addControl(`${this.controles[0]}${this.formBase}`, ControlIdPersonaJuridica);
    this.personaJuridicaForm.addControl(`${this.controles[1]}${this.formBase}`, ControlParteInteresada);
    this.personaJuridicaForm.addControl(`${this.controles[2]}${this.formBase}`, ControlNombre);
    this.personaJuridicaForm.addControl(`${this.controles[3]}${this.formBase}`, ControlTipoIndentificacion);
    this.personaJuridicaForm.addControl(`${this.controles[4]}${this.formBase}`, ControlNumeroIdentificacion);
    this.personaJuridicaForm.addControl(`${this.controles[5]}${this.formBase}`, ControlPorcentaje);

    this.PersonaJuridicaArray.insert(arrayLength, this.personaJuridicaForm);

  }

  onKeyPressNumber(event) {
    return (/[0-9]/.test(String.fromCharCode(event.which)));
  }

  onKeyPressDouble($event) {
    return (/[0-9]/.test(String.fromCharCode($event.which)) ||
    String.fromCharCode($event.which) === ',');
  }

  regionFormat(num) {
    $(num.target).val($(num.target).val().toString().replace(/\./g, ','));
  }

  get f() { return this.personaJuridicaForm.controls; }

  IsValid(control: string): boolean {
    return this.personaJuridicaForm.controls[control].valid;
  }

}
