import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/authentication/token.service';
import { Proveedor } from '../../models/proveedor';
import { ProveedorApiClientService } from 'src/app/gestion-proveedor/proveedor-api-client.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TipoProveedor, ClaseProveedor, EstadoProveedor } from 'src/app/shared/utils/general-enums-provider.enum';
import { map } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  ProveedorAuth: Proveedor;
  showFinalizar: Boolean;
  showFinalizarActAnual: Boolean;
  showRegistro: Boolean;
  showCotizacion: Boolean;
  markInfoBasica: Boolean;
  markInfoFinanciera: Boolean;
  markInfoJuridica: Boolean;
  markDeclaracion: Boolean;
  markVerCargarDocumentos: Boolean;
  markfinalizarCorreccion: Boolean;
  markfinalizarActualizacion: Boolean;
  markVerCotizaciones: Boolean;
  documentosProveedor$: Observable<any>;
  constructor(private proveedorApiClient: ProveedorApiClientService,
    private notificationsService: NotificationsService,
    private router: Router,
    private tokenService: TokenService) { }

  ngOnInit() {
    this.ProveedorAuth = this.tokenService.getLoggedUser() as Proveedor;
    if (this.ProveedorAuth.IDESTADO === EstadoProveedor.PENDIENTEPROVEEDOR) {
       this.showFinalizar = true;
    }
    if (this.ProveedorAuth.IDESTADO === EstadoProveedor.APROBADO &&
      this.ProveedorAuth.DEBEACTUALIZAR === 1) {
      this.showFinalizarActAnual = true;
    }
    this.validateMenu();
    this.validateSubMenu();
  }

  validateMenu() {
    this.showRegistro = sessionStorage.getItem('menu') === 'registro';
    this.showCotizacion = sessionStorage.getItem('menu') === 'cotizaciones';
  }

  validateSubMenu() {
    this.markInfoBasica = sessionStorage.getItem('submenu') === 'infoBasica';
    this.markInfoFinanciera = sessionStorage.getItem('submenu') === 'infoFinanciera';
    this.markInfoJuridica = sessionStorage.getItem('submenu') === 'infoJuridica';
    this.markDeclaracion = sessionStorage.getItem('submenu') === 'declaracion';
    this.markVerCargarDocumentos = sessionStorage.getItem('submenu') === 'cargardocumentos';
    this.markfinalizarCorreccion = sessionStorage.getItem('submenu') === 'finalizarCorreccion';
    this.markfinalizarActualizacion = sessionStorage.getItem('submenu') === 'finalizarActualizacion';
    this.markVerCotizaciones = sessionStorage.getItem('submenu') === 'vercotizaciones';
  }

  changeMenu(menu: string) {
    if (menu === 'registro') {
      if (!$('a[HREF="#dropdown-lvl2"]').hasClass('collapsed')) {
        $('a[HREF="#dropdown-lvl2"]').attr('aria-expanded', 'false');
        $('a[HREF="#dropdown-lvl2"]').addClass('collapsed');
        $('a[HREF="#dropdown-lvl2"]').next().removeClass('show');
      }
      sessionStorage.setItem('menu', 'registro');
    }
    if (menu === 'cotizaciones') {
      if (!$('a[HREF="#dropdown-lvl1"]').hasClass('collapsed')) {
        $('a[HREF="#dropdown-lvl1"]').attr('aria-expanded', 'false');
        $('a[HREF="#dropdown-lvl1"]').addClass('collapsed');
        $('a[HREF="#dropdown-lvl1"]').next().removeClass('show');
      }
      sessionStorage.setItem('menu', 'cotizaciones');
    }
  }

  changeSubMenu(submenu: string) {
    if (submenu === 'infoBasica') {
      sessionStorage.setItem('submenu', 'infoBasica');
    }
    if (submenu === 'infoFinanciera') {
      sessionStorage.setItem('submenu', 'infoFinanciera');
    }
    if (submenu === 'infoJuridica') {
      sessionStorage.setItem('submenu', 'infoJuridica');
    }
    if (submenu === 'declaracion') {
      sessionStorage.setItem('submenu', 'declaracion');
    }
    if (submenu === 'cargardocumentos') {
      sessionStorage.setItem('submenu', 'cargardocumentos');
    }
    if (submenu === 'vercotizaciones') {
      sessionStorage.setItem('submenu', 'vercotizaciones');
    }
    if (submenu === 'finalizarCorreccion') {
      sessionStorage.setItem('submenu', 'finalizarCorreccion');
    }
    if (submenu === 'finalizarActualizacion') {
      sessionStorage.setItem('submenu', 'finalizarActualizacion');
    }
  }

  showmodal() {
    this.ProveedorAuth = this.tokenService.getLoggedUser() as Proveedor;
    if (this.ProveedorAuth !== null) {
      this.documentosProveedor$ = this.proveedorApiClient.getDocumentosProveedorAll(this.ProveedorAuth.IDPROVEEDOR).pipe(
        map(items => items.filter(
          d => ((d.MODULO === 'CARGA-DOCUMENTOS' && (this.ProveedorAuth.IDTIPOPROVEEDOR !== TipoProveedor.PROVEEDOR || (
            this.ProveedorAuth.IDTIPOPROVEEDOR === TipoProveedor.PROVEEDOR &&
            (this.ProveedorAuth.IDCLASEPROVEEDOR !== null)
           ))) ||
           (d.MODULO === 'CARGA-DOCUMENTOS-CRITICO' && this.ProveedorAuth.IDCLASEPROVEEDOR === ClaseProveedor.CRITICO))
           && d.RUTA === null
         ))
      );

      this.documentosProveedor$.subscribe(
        (Response) => {
          if (Response.length > 0) {
            $('#DebeCargarDocumentosModal').modal('show');
          } else {
            $('#ConfirmCorreccionModal').modal('show');
          }
      });
    }
  }

  showmodalActualizacion() {
    this.ProveedorAuth = this.tokenService.getLoggedUser() as Proveedor;
    if (this.ProveedorAuth !== null) {
      this.documentosProveedor$ = this.proveedorApiClient.getDocumentosProveedorAll(this.ProveedorAuth.IDPROVEEDOR).pipe(
        map(items => items.filter(
          d => ((d.MODULO === 'CARGA-DOCUMENTOS' && (this.ProveedorAuth.IDTIPOPROVEEDOR !== TipoProveedor.PROVEEDOR || (
            this.ProveedorAuth.IDTIPOPROVEEDOR === TipoProveedor.PROVEEDOR &&
            (this.ProveedorAuth.IDCLASEPROVEEDOR !== null)
           ))) ||
           (d.MODULO === 'CARGA-DOCUMENTOS-CRITICO' && this.ProveedorAuth.IDCLASEPROVEEDOR === ClaseProveedor.CRITICO))
           && d.RUTA === null
         ))
      );

      this.documentosProveedor$.subscribe(
        (Response) => {
          if (Response.length > 0) {
            $('#DebeCargarDocumentosModal').modal('show');
          } else {
            $('#ConfirmActualizacionModal').modal('show');
          }
      });
    }
  }

  finalizarcorreccion() {
    this.proveedorApiClient.finalizarcorreccion(this.ProveedorAuth.IDPROVEEDOR).subscribe(
      (resUbicacion: any) => {
        this.ProveedorAuth.IDESTADO = EstadoProveedor.REGISTRADO;
        this.tokenService.updateLoggedUser(this.ProveedorAuth);
        this.showFinalizar = false;
        this.showFinalizarActAnual = false;
        this.notificationsService.success('Exito', 'Su información se ha registrado satisfactoriamente');
        sessionStorage.setItem('submenu', 'infoBasica');
        this.router.navigate(['/proveedor/info-basica']);
      },
      (err: any) => {
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );
    $('#ConfirmCorreccionModal').modal('hide');
  }

  finalizarActualizacion() {
    this.proveedorApiClient.FinalizarActAnual(this.ProveedorAuth.IDPROVEEDOR).subscribe(
      (resUbicacion: any) => {
        this.ProveedorAuth.IDESTADO = EstadoProveedor.REGISTRADO;
        this.tokenService.updateLoggedUser(this.ProveedorAuth);
        this.showFinalizar = false;
        this.showFinalizarActAnual = false;
        this.notificationsService.success('Exito', 'La información se ha guardado satisfactoriamente');
        sessionStorage.setItem('submenu', 'infoBasica');
        this.router.navigate(['/proveedor/info-basica']);
      },
      (err: any) => {
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );
    $('#ConfirmActualizacionModal').modal('hide');
  }

}
