import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeclaracionConflictos } from 'src/app/shared/models/declaracion-conflictos';
import { Observable, of } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { switchMap } from 'rxjs/operators';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { TokenService } from 'src/app/authentication/token.service';
import { ProveedorApiClientService } from '../proveedor-api-client.service';
import { Router } from '@angular/router';
import { EstadoProveedor } from '../../shared/utils/general-enums-provider.enum';

declare var $: any;

@Component({
  selector: 'app-declaracion-conflictos',
  templateUrl: './declaracion-conflictos.component.html',
  styleUrls: ['./declaracion-conflictos.component.sass']
})
export class DeclaracionConflictosComponent implements OnInit {
  showForm: boolean;
  showQuestion1: boolean;
  showQuestion2: boolean;
  showQuestion3: boolean;
  showQuestion4: boolean;
  showTable1: boolean;
  showTable2: boolean;
  showTable3: boolean;
  showTable4: boolean;
  showNoconflictos: boolean;
  submitted: boolean;
  declarationForm: FormGroup;
  permiteEditar: boolean;
  showspinner: boolean;
  isEdit: boolean;
  declaracionRequest$: Observable<any>;

  constructor( private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private proveedorApiClient: ProveedorApiClientService,
    private tokenService: TokenService,
    private router: Router ) {
    this._InitPreRegisterForm();
  }

  ngOnInit() {
    this.isEdit = false;
    this.showTable1 = false;
    this.showTable2 = false;
    this.showTable3 = false;
    this.showTable4 = false;
    this.showNoconflictos = false;
    this.updateInfoProveedor();
    this.resetForm();
  }

  private _PreloadData() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    if (proveedor.IDDDECLARACIONCONFLICTOS != null && proveedor.IDDDECLARACIONCONFLICTOS > 0) {
      if (proveedor.IDESTADO === EstadoProveedor.PREREGISTRO || proveedor.IDESTADO === EstadoProveedor.PENDIENTEPROVEEDOR
        || (proveedor.IDESTADO === EstadoProveedor.APROBADO && proveedor.DEBEACTUALIZAR === 1)) {
        this.permiteEditar = true;
      }
      this.consultarInformacion();
    } else {
      this.permiteEditar = true;
    }
  }

  consultarInformacion() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    const idProveedor = proveedor.IDPROVEEDOR + '';
    this.showspinner = true;

    this.proveedorApiClient.findDeclaracionConflictos(idProveedor).subscribe(
      (responseInfoJuridica: any) => {
        this.showspinner = false;
        this.declaracionRequest$ = responseInfoJuridica;
        if (responseInfoJuridica != null && responseInfoJuridica.length > 0) {
          this.isEdit = true;
          this.showTable1 = responseInfoJuridica[0].VINFAMNOMBRE != null;
          this.showTable2 = responseInfoJuridica[0].RELNEGNOMBRE != null;
          this.showTable3 = responseInfoJuridica[0].ORGADMINENTIDAD != null;
          this.showTable4 = responseInfoJuridica[0].PARTICIPACIONCAPITAL != null;
          this.showNoconflictos = !this.showTable1 && !this.showTable2 && !this.showTable3 && !this.showTable4;

          this.declarationForm.setValue(
            {
              iddeclaracion: responseInfoJuridica[0].IDDDECLARACIONCONFLICTOS,
              Declaracion: (responseInfoJuridica[0].VINFAMNOMBRE != null) || (responseInfoJuridica[0].RELNEGNOMBRE != null) ||
              (responseInfoJuridica[0].ORGADMINENTIDAD != null) || (responseInfoJuridica[0].PARTICIPACIONCAPITAL != null) ? 'SI' : 'NO',
              pregunta1: responseInfoJuridica[0].VINFAMNOMBRE != null ? 'SI' : 'NO',
              pregunta2: responseInfoJuridica[0].RELNEGNOMBRE != null ? 'SI' : 'NO',
              pregunta3: responseInfoJuridica[0].ORGADMINENTIDAD != null ? 'SI' : 'NO',
              pregunta4: responseInfoJuridica[0].PARTICIPACIONCAPITAL != null ? 'SI' : 'NO',
              nombreFuncionario: responseInfoJuridica[0].VINFAMNOMBRE,
              cargoFuncionario: responseInfoJuridica[0].VINFAMCARGO,
              claseVinculo: responseInfoJuridica[0].VINFAMCLASEVINCULO,
              nombreRelacNegocios: responseInfoJuridica[0].RELNEGNOMBRE,
              Empresa: responseInfoJuridica[0].RELNEGEMPRESA,
              tipoRelacion: responseInfoJuridica[0].RELNEGTIPORELACION,
              Entidad: responseInfoJuridica[0].ORGADMINENTIDAD,
              cargoEntidad: responseInfoJuridica[0].ORGADMINCARGO,
              participCapital: responseInfoJuridica[0].PARTICIPACIONCAPITAL
            }
          );
          this.onChangeDeclaracion();
          this.onChangeQuestion1();
          this.onChangeQuestion2();
          this.onChangeQuestion3();
          this.onChangeQuestion4();
        } else {
          this.isEdit = false;
        }
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );
  }

  updateInfoProveedor() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.showspinner = true;
    this.proveedorApiClient.FindProveedor(proveedor.IDPROVEEDOR + '').subscribe(
      ( Response: Proveedor) => {
        this.showspinner = false;
        this.tokenService.updateLoggedUser(Response);
        this._PreloadData();
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );
  }

  onChangeDeclaracion() {
    this.showForm = (this.f.Declaracion.value === 'SI');

    if (this.showForm) {
      this.f.pregunta1.setValidators([Validators.required]);
      this.f.pregunta2.setValidators([Validators.required]);
      this.f.pregunta3.setValidators([Validators.required]);
      this.f.pregunta4.setValidators([Validators.required]);
    } else {
      this.f.pregunta1.setValidators([]);
      this.f.pregunta2.setValidators([]);
      this.f.pregunta3.setValidators([]);
      this.f.pregunta4.setValidators([]);

      this.f.pregunta1.setValue(null);
      this.f.pregunta2.setValue(null);
      this.f.pregunta3.setValue(null);
      this.f.pregunta4.setValue(null);
      this.onChangeQuestion1();
      this.onChangeQuestion2();
      this.onChangeQuestion3();
      this.onChangeQuestion4();
    }

    this.f.pregunta1.updateValueAndValidity();
    this.f.pregunta2.updateValueAndValidity();
    this.f.pregunta3.updateValueAndValidity();
    this.f.pregunta4.updateValueAndValidity();
  }

  onChangeQuestion1() {
    this.showQuestion1 = (this.f.pregunta1.value === 'SI');
    if (this.showQuestion1) {
      this.f.nombreFuncionario.setValidators([Validators.required]);
      this.f.cargoFuncionario.setValidators([Validators.required]);
      this.f.claseVinculo.setValidators([Validators.required]);
    } else {
      this.f.nombreFuncionario.setValidators([]);
      this.f.cargoFuncionario.setValidators([]);
      this.f.claseVinculo.setValidators([]);

      this.f.nombreFuncionario.setValue(null);
      this.f.cargoFuncionario.setValue(null);
      this.f.claseVinculo.setValue(null);
    }

    this.f.nombreFuncionario.updateValueAndValidity();
    this.f.cargoFuncionario.updateValueAndValidity();
    this.f.claseVinculo.updateValueAndValidity();
  }

  onChangeQuestion2() {
    this.showQuestion2 = (this.f.pregunta2.value === 'SI');
    if (this.showQuestion2) {
      this.f.nombreRelacNegocios.setValidators([Validators.required]);
      this.f.Empresa.setValidators([Validators.required]);
      this.f.tipoRelacion.setValidators([Validators.required]);
    } else {
      this.f.nombreRelacNegocios.setValidators([]);
      this.f.Empresa.setValidators([]);
      this.f.tipoRelacion.setValidators([]);

      this.f.nombreRelacNegocios.setValue(null);
      this.f.Empresa.setValue(null);
      this.f.tipoRelacion.setValue(null);
    }

    this.f.nombreRelacNegocios.updateValueAndValidity();
    this.f.Empresa.updateValueAndValidity();
    this.f.tipoRelacion.updateValueAndValidity();
  }

  onChangeQuestion3() {
    this.showQuestion3 = (this.f.pregunta3.value === 'SI');

    if (this.showQuestion3) {
      this.f.Entidad.setValidators([Validators.required]);
      this.f.cargoEntidad.setValidators([Validators.required]);
    } else {
      this.f.Entidad.setValidators([]);
      this.f.cargoEntidad.setValidators([]);

      this.f.Entidad.setValue(null);
      this.f.cargoEntidad.setValue(null);
    }

    this.f.Entidad.updateValueAndValidity();
    this.f.cargoEntidad.updateValueAndValidity();
  }

  onChangeQuestion4() {
    this.showQuestion4 = (this.f.pregunta4.value === 'SI');
    if (this.showQuestion4) {
      this.f.participCapital.setValidators([Validators.required]);
    } else {
      this.f.participCapital.setValidators([]);

      this.f.participCapital.setValue(null);
    }

    this.f.participCapital.updateValueAndValidity();
  }

  get f() {
    return this.declarationForm.controls;
  }

  IsValid(control: string): boolean {
    return this.declarationForm.get(control).valid;
  }

  private _InitPreRegisterForm() {
    this.declarationForm = this.formBuilder.group({
      iddeclaracion: ['', []],
      Declaracion: [null, [Validators.required]],
      pregunta1: [null, []],
      pregunta2: [null, []],
      pregunta3: [null, []],
      pregunta4: [null, []],
      nombreFuncionario: [null, []],
      cargoFuncionario: [null, []],
      claseVinculo: [null, []],
      nombreRelacNegocios: [null, []],
      Empresa: [null, []],
      tipoRelacion: [null, []],
      Entidad: [null, []],
      cargoEntidad: [null, []],
      participCapital: [null, []]
    });
  }

  submitForm() {
    this.submitted = true;
    if (!this.declarationForm.valid) {
      this.submitted = false;
      return false;
    }

    const DECLARACION: DeclaracionConflictos = this._MapDeclaracionForm();
    if (this.f.iddeclaracion.value > 0) {
      this._UpdateDeclaracion(DECLARACION);
    } else {
      this._CrearDeclaracion(DECLARACION);
    }
  }

  private _CrearDeclaracion(
    DECLARACION: DeclaracionConflictos
  ) {
    this.showspinner = true;
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    proveedor.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();
    this.proveedorApiClient.CreateDeclaracionConflictos(DECLARACION).pipe(
      switchMap(
        (responseDeclaracion: any) => {
          proveedor.DECLARACIONCONFLICTOS = 1;
          proveedor.IDDDECLARACIONCONFLICTOS = responseDeclaracion;
          this.proveedorApiClient.UpdateProveedor(proveedor).subscribe();
          this.tokenService.updateLoggedUser(proveedor);
          return of(true);
        }
      )
    ).subscribe(
      (responseDeclaracion: any) => {
        this.showspinner = false;
          this.notificationsService.success('Exito', 'La declaración de conflictos se ha guardado correctamente');
          sessionStorage.setItem('submenu', 'infoBasica');
          this.router.navigate(['/proveedor/info-basica']);
          this.resetForm();
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );
  }

  private _UpdateDeclaracion(
    DECLARACION: DeclaracionConflictos
  ) {
    this.showspinner = true;
    this.proveedorApiClient.UpdateDeclaracionConflictos(DECLARACION).pipe(
      switchMap(
        (responseDeclaracion: any) => {
          return of(true);
        }
      )
    ).subscribe(
      (responseDeclaracion: any) => {
        this.showspinner = false;
          this.notificationsService.success('Exito', 'La declaración de conflictos se ha guardado correctamente');
          sessionStorage.setItem('submenu', 'infoBasica');
          this.router.navigate(['/proveedor/info-basica']);
          this.resetForm();
      }
    );
  }

  resetForm() {
    $('input').each(function () {
      $($(this)[0]).val('');
    });
    $('select').each(function () {
      $($(this)[0]).prop('selectedIndex', 0);
    });
    this.showForm = false;
    this.showQuestion1 = false;
    this.showQuestion2 = false;
    this.showQuestion3 = false;
    this.showQuestion4 = false;
    this.submitted = false;
  }

  private _MapDeclaracionForm(): DeclaracionConflictos {
    const DECLARACION: DeclaracionConflictos = new DeclaracionConflictos(
      this.f.iddeclaracion.value,
      this.f.Entidad.value != null ? (this.f.Entidad.value + '').toUpperCase() : '',                                 // ORGADMINENTIDAD
      this.f.cargoEntidad.value != null ? (this.f.cargoEntidad.value + '').toUpperCase() : '',                            // ORGADMINCARGO
      this.f.participCapital.value != null ?
      (this.f.participCapital.value + '').toUpperCase() : '',                         // PARTICIPACIONCAPITAL
      this.f.nombreRelacNegocios.value != null ?
      (this.f.nombreRelacNegocios.value + '').toUpperCase() : '',                     // RELNEGNOMBRE
      this.f.Empresa.value != null ? (this.f.Empresa.value + '').toUpperCase() : '',                                 // RELNEGEMPRESA
      this.f.tipoRelacion.value,                            // RELNEGTIPORELACION
      this.f.nombreFuncionario.value != null ?
      (this.f.nombreFuncionario.value + '').toUpperCase() : '',                       // VINFAMNOMBRE
      this.f.cargoFuncionario.value != null ? (this.f.cargoFuncionario.value + '').toUpperCase() : '',                        // VINFAMCARGO
      this.f.claseVinculo.value,                            // VINFAMCLASEVINCULO
      this.tokenService.getNomUser(),                                            // USUARIO_CREACION
      null                                                  // USUARIO_ACTUALIZACION
    );

    return DECLARACION;
  }

  showAlertCancel() {
    $('#CancelModal').modal('show');
  }

  ConfirmCancel() {
    $('#CancelModal').modal('hide');
    sessionStorage.setItem('submenu', 'infoBasica');
    this.router.navigate(['/proveedor/info-basica']);
  }

}
