import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { Observable } from 'rxjs';
import { FormGroup, FormGroupDirective, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ProveedorApiClientService } from '../proveedor-api-client.service';

declare var $: any;

@Component({
  selector: 'app-ubicacion',
  templateUrl: './ubicacion.component.html',
  styleUrls: ['./ubicacion.component.css']
})
export class UbicacionComponent implements OnInit, OnChanges, OnDestroy {

  @Input() form: FormGroup;
  @Input() formDirective: FormGroupDirective;
  @Input() isEdit: boolean;
  @Input() ubicacion: Observable<any>;

  UbicacionForm: FormGroup;
  UbicationArray: FormArray;

  paises$: Observable<any>;
  abreviaturas$: Observable<any>;
  departamentos$: Observable<any>;
  ciudades$: Observable<any>;

  constructor(
    private dataApiClient: DataApiClientService,
    private ProveedorApiClient: ProveedorApiClientService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.getPaises();
    this.getAbreviaturas();
    this._InitUbicationForm();
    this._InitList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const ubicacion: SimpleChange = changes.ubicacion;

    if (ubicacion !== undefined && ubicacion.currentValue !== undefined) {
      this._PreloadData();
    }
  }

  ngOnDestroy(): void {
    this.UbicationArray.removeAt(this.UbicationArray.length - 1);
    this.UbicationArray.reset();
  }

  get f() { return this.UbicacionForm.controls; }

  IsValid(control: string): boolean {
    return this.UbicacionForm.controls[control].valid;
  }

  getAbreviaturas(): void {
    this.abreviaturas$ = this.ProveedorApiClient.getAbreviaturas();
  }

  getPaises(): void {
    this.paises$ = this.dataApiClient.getPaises();
  }
  getDepartamentosPais(idPais): void {
    this.departamentos$ = this.dataApiClient.getDepartamentosPais(idPais);
  }

  getCiudadesDepartamento(idDepartamento): void {
    this.ciudades$ = this.dataApiClient.getCiudadesDepartamento(idDepartamento);
  }

  onChangePais($event) {
    const idPais = this.f.Pais.value;
    this.getDepartamentosPais(idPais);

    if (idPais === null) {
      this.f.Departamento.clearValidators();
      this.f.Ciudad.clearValidators();
      this.f.Estado.clearValidators();
    }

    if (idPais !== '169') {
      this.f.Estado.setValidators([Validators.required, Validators.maxLength(100)]);
      this.f.Departamento.clearValidators();
      this.f.Ciudad.clearValidators();
    } else {
      this.f.Estado.clearValidators();
      this.f.Departamento.setValidators(Validators.required);
      this.f.Ciudad.setValidators(Validators.required);
    }

    this.f.Departamento.updateValueAndValidity();
    this.f.Ciudad.updateValueAndValidity();
    this.f.Estado.updateValueAndValidity();
  }

  onChangeDepartamento($event, flag: boolean = false) {
    const idDepartamento = this.f.Departamento.value;
    if (flag) {
      this.f.Ciudad.setValue(null);
    }
    this.getCiudadesDepartamento(idDepartamento);
  }

  onKeyPressNumber(event) {
    return (/[0-9]/.test(String.fromCharCode(event.which)));
  }

  private _InitList() {
    this.onChangePais(new Event('change'));
  }

  private _InitUbicationForm() {
    this.UbicationArray = this.form.controls.Ubicacion as FormArray;
    const arrayLength = this.UbicationArray.length;

    this.UbicacionForm = this.formBuilder.group({
      IdUbicacion: ['', []],
      Abreviatura: [null, [Validators.required]],
      Numero1: ['', [Validators.maxLength(10)]],
      Letra1: ['', [Validators.maxLength(10)]],
      Numero2: ['', [Validators.maxLength(10)]],
      Letra2: ['', [Validators.maxLength(10)]],
      Numero3: ['', [Validators.maxLength(10)]],
      Continuidad: ['', [Validators.maxLength(100)]],
      Pais: ['169', [Validators.required]],
      Departamento: [null, []],
      Ciudad: [null, []],
      Estado: ['', []],
      TelefonoFijo: ['', [
        Validators.maxLength(7),
        Validators.max(9999999),
        Validators.min(1000000)
      ]],
      TelefonoCelular: ['', [
        Validators.maxLength(10),
        Validators.max(9999999999),
        Validators.min(1000000000)
      ]],
      Contacto: ['', [Validators.required,  Validators.maxLength(80)]]
    });

    this.UbicationArray.insert(arrayLength, this.UbicacionForm);
  }

  private _PreloadData() {
    if (this.isEdit) {
      this.ubicacion.subscribe(
        (resUbica: Array<any>) => {

          this.UbicacionForm.setValue({
            IdUbicacion: resUbica[0].IDUBICACION,
            Abreviatura: Number(resUbica[0].IDABREVIATURADIR),
            Numero1: resUbica[0].NUMERO1,
            Letra1: resUbica[0].LETRA1,
            Numero2: resUbica[0].NUMERO2,
            Letra2: resUbica[0].LETRA2,
            Numero3: resUbica[0].NUMERO3,
            Continuidad: resUbica[0].CONTINUIDAD,
            Pais: `${resUbica[0].IDPAIS}`,
            Departamento: `${resUbica[0].IDDEPARTAMENTO}`,
            Ciudad: `${resUbica[0].IDCIUDAD}`,
            Estado: `${resUbica[0].ESTADO != null ? resUbica[0].ESTADO : ''}`,
            TelefonoFijo: resUbica[0].TELEFONOFIJO,
            TelefonoCelular: resUbica[0].TELEFONOCELULAR,
            Contacto: resUbica[0].CONTACTO
          });

          this.onChangePais(new Event('change'));
          this.onChangeDepartamento(new Event('change'), false);
        }
      );
    }
  }
}
