import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationApiClientService {
  api: any;
  loginObj: any;


  constructor(private http: HttpClient,
    private configService: ConfigService) {
    this.api = this.configService.get('api');
    this.loginObj = this.api.endpoints.filter(c => c.login)[0];

  }

  Login(form: any): Observable<any> {
    const CreateProveedor = this.loginObj.login.filter((m: any) => m.name === 'authenticateExt')[0];
    return this.http.post(`${this.api.basepath}${CreateProveedor.url}`, form);
  }

}
