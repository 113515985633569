import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../shared/models/usuario';
import { TokenService } from 'src/app/authentication/token.service';
import { DataApiClientService } from 'src/app/shared/utils/data-api-client.service';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription, of, EMPTY } from 'rxjs';
import { OfertaDto } from '../../shared/models/oferta-dto';
import { CotizacionApiCLientService } from '../cotizacion-api-client.service';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { LanguageApp } from 'src/assets/utils/LanguageApp';

declare var $: any;

@Component({
  selector: 'app-listar-cotizaciones',
  templateUrl: './listar-cotizaciones.component.html',
  styleUrls: ['./listar-cotizaciones.component.sass']
})
export class ListarCotizacionesComponent implements OnInit {
  UsuarioAuth: Usuario;
  solpedRequest$: Observable<any>;
  solpedList$: Observable<Array<OfertaDto>>;
  proveedor: Proveedor;
  canViewDetail: boolean;
  dtTrigger: Subject<any> = new Subject();
  private subscriptions: Array<Subscription> = [];
  dtOptions: any = {};
  settings: any;

  constructor(
    private solpedApiClient: CotizacionApiCLientService,
    private tokenService: TokenService,
    private dataApiClient: DataApiClientService,
    private router: Router) { }

  ngOnInit() {
    this.proveedor = this.tokenService.getLoggedUser() as Proveedor;
    sessionStorage.setItem('solpedCot', null);
    this.getCotizacion();
    this._ConfigureComponents();
  }

  getCotizacion() {
    this.UsuarioAuth = this.tokenService.getLoggedUser() as Usuario;
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    if ( proveedor !== null) {
      this.canViewDetail = proveedor.IDESTADO != 0;

      this.solpedRequest$ = this.solpedApiClient.getCotizacion(proveedor.IDPROVEEDOR);

      this.solpedRequest$.subscribe(
        (response: Array<OfertaDto>) => {
          this.solpedList$ = of(response);
          this.dtTrigger.next();
        },
        (err: any) => {
          console.error('ERROR: la petición retornó un error', err);
        }
      );
    }
  }

  editSolped(solped: any) {
    this.router.navigate(['/cotizaciones/detalle-cotizacion'], solped);
  }
  private _ConfigureComponents() {

    // DataTable
    this.dtOptions = {
      pagingType: 'numbers',
      dom: 'l<"float-right"B>t<"row"<"col-lg-12"p>>',
      scrollX: true,
      scrollY: function () {
        var winHeight = window.innerHeight;
        var yValue = '400';

        if (winHeight <= 363) {
          return '252';
        }

        return yValue;
      },
      scroller: {
        loadingIndicator: true
      },
      autoWidth: false,
      buttons: [
      ],
      language: LanguageApp.spanish_datatables
    };
  }

}
