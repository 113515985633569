import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ProveedorApiClientService } from '../proveedor-api-client.service';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { Observable, Subscription, EMPTY, of } from 'rxjs';
import { InfoBasica } from '../../shared/models/info-basica';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, FormArray } from '@angular/forms';
import { TipoProveedor } from 'src/app/shared/utils/general-enums-provider.enum';
import { EstadoProveedor } from 'src/app/shared/utils/general-enums-provider.enum';
import { NotificationsService } from 'angular2-notifications';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { Ubicacion } from 'src/app/shared/models/ubicacion';
import { Usuario } from 'src/app/shared/models/usuario';
import { CuentaBancaria } from 'src/app/shared/models/cuenta-bancaria';
import { switchMap, finalize, map } from 'rxjs/operators';
import { TokenService } from 'src/app/authentication/token.service';
import { ConfigService } from 'src/app/app-config.service';

declare var $: any;

@Component({
  selector: 'app-pre-registro',
  templateUrl: './pre-registro.component.html',
  styleUrls: ['./pre-registro.component.css']
})
export class PreRegistroComponent implements OnInit, OnDestroy {

  preRegisterForm: FormGroup;
  @ViewChild('ngForm') formDirective: FormGroupDirective;

  submitted: boolean;

  tiposProveedor$: Observable<any>;
  fileNames: Array<string> = [];

  showDatosUsuario: boolean;
  showTipoEmpresa: boolean;
  showInfoBasica: boolean;
  showspinner: boolean;
  showLoadingNewProvider: boolean;
  proveedor: Proveedor;

  telefonoCelular: any;
  getProveedorRequest$: Observable<any>;

  insertProveedorBasico$: Observable<any>;
  insertProveedorConUsuarioRequest$: Observable<any>;
  insertProveedorConCuentaRequest$: Observable<any>;

  updateProveedorBasico$: Observable<any>;
  updateProveedorConUsuarioRequest$: Observable<any>;
  updateProveedorConCuentaRequest$: Observable<any>;

  isEdit$: Observable<boolean>;
  InfoBasica$: Observable<InfoBasica>;
  Ubicacion$: Observable<any>;
  cuentaUsuario$: Observable<any>;
  cuentaBancaria$: Observable<any>;
  permiteEditar: boolean;
  numSolped = 'Hello';

  private subscriptions: Array<Subscription> = [];
  textoBoton: string;
  pesoarchivos = 0;

  constructor(
    private proveedorApiClient: ProveedorApiClientService,
    private notificationsService: NotificationsService,
    private dataApiClient: DataApiClientService,
    private formBuilder: FormBuilder,
    private tokenService: TokenService,
    private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.pesoarchivos = this.configService.get('parameters').pesoarchivos;
    this.showDatosUsuario = false;
    this.showTipoEmpresa = false;
    this.submitted = false;
    this.isEdit$ = of(false);
    this.textoBoton = 'Registrar';
    this.InfoBasica$ = EMPTY;
    this.getTiposProveedor();
    this._InitPreRegisterForm();
    this._PreloadData();
    this.updateInfoProveedor();
  }

  updateInfoProveedor() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.showspinner = true;
    this.proveedorApiClient.FindProveedor(proveedor.IDPROVEEDOR + '').subscribe(
      ( Response: Proveedor) => {
        this.tokenService.updateLoggedUser(Response);
        this.showspinner = false;
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get f() {
    return this.preRegisterForm.controls;
  }

  IsValid(control: string): boolean {
    return this.preRegisterForm.get(control).valid;
  }

  onChangeTipoProveedor(event: Event) {

    this.showInfoBasica = (this.f.TipoProveedor.value !== null);

    if (this.showInfoBasica) {
      const idtipoproveedor = Number.parseInt(this.f.TipoProveedor.value, 10);

      if (idtipoproveedor === TipoProveedor.PROVEEDOR || idtipoproveedor === TipoProveedor.CLIENTE_DEUDOR) {
        this.showDatosUsuario = true;
        this.showTipoEmpresa = true;
      } else {
        this.showDatosUsuario = false;
        this.showTipoEmpresa = false;
      }
    }
  }

  getTiposProveedor(): void {
    this.tiposProveedor$ = this.dataApiClient.getTiposProveedor();
  }

  AutorTratDatos() {
    if ($('#cbkAcept').is(':checked')) {
      $('#exampleModal').modal('hide');
      this.notificationsService.success('Exito', 'Debe aceptar la política de tratamiento de datos personales');
    } else {
      this.notificationsService.warn('Alerta', 'Debe aceptar la política de tratamiento de datos personales');
    }
  }

  createProveedor() {
    this.submitted = true;
    if (this.preRegisterForm.invalid) {
      this.submitted = false;
      this.notificationsService.warn('Alerta', 'Se encontraron errores en el formulario, por favor valide e intente de nuevo');
      return;
    }

    const PROVEEDOR: Proveedor = this._MapProveedorForm();
    const INFO_BASICA: InfoBasica = this._MapInfoBasicaForm();
    const UBICACION: Ubicacion = this._MapUbicacionForm();
    const USUARIO: Usuario = (this.showDatosUsuario) ? this._MapUsuarioForm() : null;
    const CUENTABANCARIA = null;
    this.showspinner = true;
    this.isEdit$.subscribe(
      (val: boolean) => {
        this.showspinner = false;
        if (val) {
          this._EditarProveedor(PROVEEDOR, INFO_BASICA, UBICACION, USUARIO, CUENTABANCARIA);
        }
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );

  }

  onKeyPressNumber(event) {
    return (/[0-9]/.test(String.fromCharCode(event.which)));
  }

  resetForm() {
    $('input').each(function () {
      $($(this)[0]).val('');
    });
    $('select').not('[name="idpais"]').each(function () {
      $($(this)[0]).val('-1');
    });
    this.showInfoBasica = false;
    this.showDatosUsuario = false;
    this.showTipoEmpresa = false;
    this.formDirective.resetForm();
    this.preRegisterForm.reset();
    this.submitted = false;
  }

  private _InitPreRegisterForm() {
    this.preRegisterForm = this.formBuilder.group({
      IdProveedor: ['', []],
      TipoProveedor: [null, [Validators.required]],
      InfoBasica: this.formBuilder.array([]),
      Ubicacion: this.formBuilder.array([]),
      CuentaUsuario: this.formBuilder.array([]),
      CuentaBancaria: this.formBuilder.array([])
    });
  }

  private _MapProveedorForm(): Proveedor {
    const INFO_BASICA_FORM = JSON.parse(JSON.stringify((this.f.InfoBasica as FormArray).getRawValue()[0]));
    const UBICACION_FORM = JSON.parse(JSON.stringify((this.f.Ubicacion as FormArray).getRawValue()[0]));

    let CUENTA_USUARIO_FORM;
    if (this.showDatosUsuario) {
      CUENTA_USUARIO_FORM = JSON.parse(JSON.stringify((this.f.CuentaUsuario as FormArray).getRawValue()[0]));
    }
    const proveedorAuth: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    const PROVEEDOR: Proveedor = new Proveedor(
      this.f.IdProveedor.value,                                                         // IDPROVEEDOR
      this.f.TipoProveedor.value,                                   // IDTIPOPROVEEDOR
      INFO_BASICA_FORM.NombreComercial != null ?
      (INFO_BASICA_FORM.NombreComercial + '').toUpperCase() : '',                             // NOMBRECOMERCIAL
      (this.showDatosUsuario) ? CUENTA_USUARIO_FORM.Email : null,    // CORREO
      UBICACION_FORM.TelefonoCelular,                               // TELEFONOCELULAR
      proveedorAuth.IDINFOBASICA,                                   // IDINFOBASICA
      proveedorAuth.IDUSUARIO,                                      // IDUSUARIO
      proveedorAuth.IDESTADO,                                       // IDESTADO
      proveedorAuth.IDCLASEPROVEEDOR,                               // IDCLASEPROVEEDOR
      proveedorAuth.IDINFOJURIDICA,                                 // IDINFOJURIDICA
      proveedorAuth.IDINFOFINANCIERA,                               // IDINFOFINANCIERA
      proveedorAuth.CODIGO_CIIU,                                    // CODIGO_CIIU
      proveedorAuth.IDCUENTABANCARIA,                               // IDCUENTABANCARIA
      proveedorAuth.DECLARACIONCONFLICTOS,                          // DECLARACIONCONFLICTOS
      proveedorAuth.IDDDECLARACIONCONFLICTOS,                       // IDDECLARACIONCONFLICTOS
      proveedorAuth.AUTORIZACIONDATOS,                              // AUTORIZACIONDATOS
      proveedorAuth.IDUSUARIORESPONSABLE,                           // IDUSUARIORESPONSABLE
      proveedorAuth.HABILITADOREGISTRO,                             // HABILITADOREGISTRO
      proveedorAuth.USUARIO_CREACION,                               // USUARIO_CREACION
      'sistema',                                                    // USUARIO_ACTUALIZACION
      (this.showTipoEmpresa) ? INFO_BASICA_FORM.TipoEmpresa : null, // IDTIPOEMPRESA
      this.fileNames                                                // FILES
    );

    return PROVEEDOR;
  }

  private _MapInfoBasicaForm(): InfoBasica {
    const INFO_BASICA_FORM = JSON.parse(JSON.stringify((this.f.InfoBasica as FormArray).getRawValue()[0]));

    const INFOBASICA: InfoBasica = new InfoBasica(
      INFO_BASICA_FORM.IdInfoBasica, // IDINFOBASICA: number;
      INFO_BASICA_FORM.TipoPersona, // IDTIPOPERSONA: number;
      INFO_BASICA_FORM.TipoIdentificacion, // IDTIPOIDENTIFICACION: number;
      INFO_BASICA_FORM.NumeroIdentificacion, // NUMEROIDENTIFICACION: string;
      INFO_BASICA_FORM.DigitoVerificacion, // DIGITOVERIFICACION: string;
      INFO_BASICA_FORM.RazonSocial != null ? (INFO_BASICA_FORM.RazonSocial + '').toUpperCase() : '', // RAZONSOCIAL: string;
      INFO_BASICA_FORM.Sigla != null ? (INFO_BASICA_FORM.Sigla + '').toUpperCase() : '', // SIGLA: string;
      INFO_BASICA_FORM.PrimerNombre != null ? (INFO_BASICA_FORM.PrimerNombre + '').toUpperCase() : '', // PRIMERNOMBRE: string;
      INFO_BASICA_FORM.SegundoNombre != null ? (INFO_BASICA_FORM.SegundoNombre + '').toUpperCase() : '', // SEGUNDONOMBRE: string;
      INFO_BASICA_FORM.PrimerApellido != null ? (INFO_BASICA_FORM.PrimerApellido + '').toUpperCase() : '', // PRIMERAPELLIDO: string;
      INFO_BASICA_FORM.SegundoApellido != null ? (INFO_BASICA_FORM.SegundoApellido + '').toUpperCase() : '', // SEGUNDOAPELLIDO: string;
      INFO_BASICA_FORM.IdUbicacion, // IDUBICACION: number;
      '', // CONTACTO: string;
      'sistema', // USUARIO_CREACION: string;
      null, // FECHA_CREACION: string;
      'sistema', // USUARIO_ACTUALIZACION: string;
      null // FECHA_ACTUALIZACION: string;
    );

    return INFOBASICA;
  }

  private _MapUbicacionForm(): Ubicacion {
    const UBICACION_FORM = JSON.parse(JSON.stringify((this.f.Ubicacion as FormArray).getRawValue()[0]));
    const UBICACION: Ubicacion = new Ubicacion(
      UBICACION_FORM.IdUbicacion,                                                 // IDUBICACION
      UBICACION_FORM.Abreviatura,                           // IDABREVIATURA
      UBICACION_FORM.Numero1 != null ? (UBICACION_FORM.Numero1 + '').toUpperCase() : '',                 // NUMERO1
      UBICACION_FORM.Letra1 != null ? (UBICACION_FORM.Letra1 + '').toUpperCase() : '',                  // LETRA1
      UBICACION_FORM.Numero2 != null ? (UBICACION_FORM.Numero2 + '').toUpperCase() : '',                 // NUMERO2
      UBICACION_FORM.Letra2 != null ? (UBICACION_FORM.Letra2 + '').toUpperCase() : '',                  // LETRA2
      UBICACION_FORM.Numero3 != null ? (UBICACION_FORM.Numero3 + '').toUpperCase() : '',                 // NUMERO3
      UBICACION_FORM.Continuidad != null ? (UBICACION_FORM.Continuidad + '').toUpperCase() : '',             // CONTINUIDAD
      UBICACION_FORM.Ciudad,                                // IDCIUDAD
      UBICACION_FORM.Departamento,                          // IDDEPARTAMENTO
      UBICACION_FORM.Pais,                                  // IDPAIS
      UBICACION_FORM.TelefonoFijo,                          // TELEFONOFIJO
      'sistema',                                            // USUARIO_CREACION
      null,                                                 // USUARIO_ACTUALIZACION
      UBICACION_FORM.Estado != null ? (UBICACION_FORM.Estado + '').toUpperCase() : '',                                // ESTADO
      UBICACION_FORM.Contacto != null ? (UBICACION_FORM.Contacto + '').toUpperCase() : '' // CONTACTO
    );
    return UBICACION;
  }

  private _MapUsuarioForm(): Usuario {
    const INFO_BASICA_FORM = JSON.parse(JSON.stringify((this.f.InfoBasica as FormArray).getRawValue()[0]));
    const CUENTA_USUARIO_FORM = JSON.parse(JSON.stringify((this.f.CuentaUsuario as FormArray).getRawValue()[0]));
    const USUARIO: Usuario = new Usuario(
      CUENTA_USUARIO_FORM.IdUsuario,                                                 // IDUSUARIO
      CUENTA_USUARIO_FORM.User,                             // NOM_USUARIO
      CUENTA_USUARIO_FORM.Email,                            // CORREO
      CUENTA_USUARIO_FORM.Password,                         // CONTRASENA
      1,                                                    // IDESTADOUSUARIO
      'sistema',                                            // USUARIO_CREACION
      null,                                                 // USUARIO_ACTUALIZACION
      INFO_BASICA_FORM.NumeroIdentificacion                 // NUMEROIDENTIFICACION
    );
    return USUARIO;
  }

  private _EditarProveedor(
    ProveedorForm: Proveedor,
    InfoBasicaForm: InfoBasica,
    UbicacionForm: Ubicacion,
    CuentaUsuarioForm: Usuario,
    CuentaBancariaForm: CuentaBancaria
  ) {
    if (ProveedorForm) {
      ProveedorForm.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();
    }
    if (InfoBasicaForm) {
      InfoBasicaForm.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();
    }
    if (UbicacionForm) {
      UbicacionForm.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();
    }
    if (CuentaUsuarioForm) {
      CuentaUsuarioForm.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();
    }
    if (CuentaBancariaForm) {
      CuentaBancariaForm.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();
    }

    if (this.showDatosUsuario) {
      // Si es cuenta de usuario, inserta todo el flujo
      this.updateProveedorConUsuarioRequest$ = this.dataApiClient.UpdateUsuario(CuentaUsuarioForm).pipe(
        // Insert de cuentas de usuario
        switchMap(responseUsuario => {
          // Insert de Ubicacion
          return this.dataApiClient.UpdateUbicacion(UbicacionForm).pipe(
            switchMap(responseUbicacion => {
              InfoBasicaForm.IDUBICACION = Number.parseInt(UbicacionForm.IDUBICACION, 10);
              // Insert de Info Basica
              return this.proveedorApiClient.UpdateInfoBasica(InfoBasicaForm).pipe(
                switchMap(responseInfoBasica => {
                  // Insert de Proveedor
                  return this.proveedorApiClient.UpdateProveedor(ProveedorForm).pipe(
                    finalize(
                      () => {
                        // this.resetForm();
                      }
                    )
                  );
                })
              );
            })
          );
        })
      );
      this.showspinner = true;
      this.subscriptions.push(this.updateProveedorConUsuarioRequest$.subscribe(
        (result: any) => {
          this.showspinner = false;
          if (result) {
            this.notificationsService.success('Exito', 'Actualización culminada con éxito');
            this.submitted = false;
          } else {
            this.notificationsService.success('Error', 'Se encontró un problema al insertar el proveedor');
          }
        },
        (err: any) => {
          this.showspinner = false;
          this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
        }
      ));
    }
  }

  showModalCancel() {
    $('#CancelModal').modal('show');
  }

  ConfirmCancel() {
    this.showspinner = true;
    this.isEdit$.subscribe(
      (data: boolean) => {
        this.showspinner = false;
        if (data) {
          $('#CancelModal').modal('hide');
          this._PreloadData();
        } else {
          $('#CancelModal').modal('hide');
        }
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );
  }

  habilitarSelect(event: Event) {
    this.showspinner = true;
    this.isEdit$.subscribe(
      (data: boolean) => {
        this.showspinner = false;
        if (data) {
          const el: HTMLSelectElement = event.target as HTMLSelectElement;
          $(el.options).prop('disabled', true);
          return false;
        }
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
      }
    );
  }

  private _PreloadData() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    if (proveedor !== null) {
      
      if (proveedor.IDPROVEEDOR) {
        this.isEdit$ = of(true);
        this.textoBoton = 'Actualizar';
        if (proveedor.IDESTADO === EstadoProveedor.PREREGISTRO || proveedor.IDESTADO === EstadoProveedor.PENDIENTEPROVEEDOR
          || (proveedor.IDESTADO === EstadoProveedor.APROBADO && proveedor.DEBEACTUALIZAR === 1)) {
          this.permiteEditar = true;
        }

        if (proveedor.IDESTADO === 0) {
          this.proveedorApiClient.getNumSolpedByProvider(proveedor.IDPROVEEDOR).subscribe(
            (data) => {
              this.numSolped = data;
              $('#newProviderModal').modal('show');
            }
          );
        }

        this.showspinner = true;
        this.proveedorApiClient.proveedorGetAllData(proveedor.IDPROVEEDOR).pipe(
          map((x: any) => {
            if (x.IDUSUARIO !== null && x.IDUSUARIO !== 0) {
              this.showspinner = true;
              this.dataApiClient.getInfoCuentaUsuario(`${x.IDUSUARIO}`).subscribe(
                (resUsuario: any) => {
                  this.showspinner = false;
                  this.cuentaUsuario$ = of(resUsuario);
                },
                (err: any) => {
                  this.showspinner = false;
                  this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
                }
              );
            } else {
              this.cuentaUsuario$ = EMPTY;
            }

            if (x.IDCUENTABANCARIA !== null && x.IDCUENTABANCARIA !== 0) {
              this.showspinner = true;
              this.dataApiClient.getCuentaBancaria(`${x.IDCUENTABANCARIA}`).subscribe(
                (resCuentaBco: any) => {
                  this.showspinner = false;
                  this.cuentaBancaria$ = of(resCuentaBco);
                },
                (err: any) => {
                  this.showspinner = false;
                  this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
                }
              );
            } else {
              this.cuentaBancaria$ = EMPTY;
            }

            return x;
          }),
          switchMap((resProveedor: Proveedor) => {
            this.preRegisterForm.controls.TipoProveedor.setValue(resProveedor.IDTIPOPROVEEDOR);
            this.preRegisterForm.controls.IdProveedor.setValue(resProveedor.IDPROVEEDOR);
            this.onChangeTipoProveedor(new Event('change'));
            this.telefonoCelular = resProveedor.TELEFONOCELULAR;
            if (resProveedor.IDINFOBASICA !== null) {
              return this.dataApiClient.getInfoBasica(`${resProveedor.IDINFOBASICA}`).pipe(
                switchMap(
                  (resInfoBasica: InfoBasica) => {
                    this.InfoBasica$ = of(resInfoBasica);
                    if (resInfoBasica[0]['IDUBICACION'] !== 0) {
                      return this.dataApiClient.getUbicacion(resInfoBasica[0]['IDUBICACION']);
                    } else {
                      return EMPTY;
                    }
                  }
                )
              );

            } else {
              return EMPTY;
            }
          })
        ).subscribe(
          (resUbicacion: any) => {
            resUbicacion[0].TELEFONOCELULAR = this.telefonoCelular;
            this.Ubicacion$ = of(resUbicacion);
            this.showspinner = false;
          },
          (err: any) => {
            this.showspinner = false;
            this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
          }
        );
      } else {
        this.isEdit$ = of(false);
        this.permiteEditar = true;

        // Asigna el tipo de proveedor
        this.preRegisterForm.controls.TipoProveedor.setValue(proveedor.IDTIPOPROVEEDOR);
        this.onChangeTipoProveedor(new Event('change'));

        // Asigna la información de usuario
        this.showspinner = true;
        this.dataApiClient.getInfoCuentaUsuario(`${proveedor.IDUSUARIO}`).subscribe(
          (resUsuario: any) => {
            this.showspinner = false;
            this.cuentaUsuario$ = of(resUsuario);
          },
          (err: any) => {
            this.showspinner = false;
            this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
          }
        );
        this.showInfoBasica = true;
        this.textoBoton = 'Registrar';
        return EMPTY;
      }
    } else {
      this.isEdit$ = of(false);
      this.showInfoBasica = true;
      this.textoBoton = 'Registrar';
    }
  }

  public accept() {
    this.showLoadingNewProvider = true;

    this.proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.proveedor.IDESTADO = EstadoProveedor.HABILITADOCOTIZAR;
    this.proveedor.USUARIO_ACTUALIZACION = 'sistema';

    this.proveedorApiClient.UpdateProveedor(this.proveedor).subscribe(
      () => {
        this.showLoadingNewProvider = false;
        $('#newProviderModal').modal('hide');
        this.notificationsService.success('Exito', 'Se encuentra habilitado para cotizar');

        this.tokenService.updateLoggedUser(this.proveedor);
      },
      err => {
        this.showLoadingNewProvider = false;
        this.notificationsService.error('Error', 'Ocurrio un error actualizando proveedor habilitado cotizar');
      }
    );
  }
}

