import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators,  } from '@angular/forms';
import { TokenService } from 'src/app/authentication/token.service';
import { DataApiClientService } from 'src/app/shared/utils/data-api-client.service';
import { Router, NavigationEnd } from '@angular/router';
import { Observable,  Subscription, of } from 'rxjs';
import { CotizacionApiCLientService } from '../cotizacion-api-client.service';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { SolpedDocumentodto } from 'src/app/shared/models/solped-documentodto';
import { ProveedorApiClientService } from '../../gestion-proveedor/proveedor-api-client.service';
import { Comentario} from '../../shared/models/comentario';
import { NotificationsService } from 'angular2-notifications';
import { OfertaDto } from 'src/app/shared/models/oferta-dto';
import * as moment from 'moment';
import { map, filter } from 'rxjs/operators';
import { ConfigService } from 'src/app/app-config.service';
import { Cotizacion } from '../../shared/models/cotizacion';
import { UtilityService } from '../../shared/utils/utility.service';

declare var $: any;

@Component({
  selector: 'app-detalle-cotizacion',
  templateUrl: './detalle-cotizacion.component.html',
  styleUrls: ['./detalle-cotizacion.component.css']
})
export class DetalleCotizacionComponent implements OnInit {
  solpedRequest$: Observable<any>;
  solpedDocRequest$: Observable<any>;
  createRequest$: Observable<any>;
  comentariosRequest$: Observable<any>;
  solpedList$: Observable<Array<OfertaDto>>;
  visitaList$: Observable<Array<OfertaDto>>;
  solpedDocList$: Observable<Array<SolpedDocumentodto>>;
  comentariosList$: Observable<Array<Comentario>>;
  proveedor: Proveedor;
  RegistrarSolpedForm: FormGroup;
  CotizacionForm: FormGroup;
  iddocumentodelete: number;
  solpedEdit: OfertaDto;
  cotizacionEdit: Cotizacion;
  documentosCategoriaSolped$: Observable<any>;
  visualizarcotizaciones: string;
  visualizarcot: boolean;
  pesoarchivos = 0;
  showspinner: boolean;
  showspinnerDelete: boolean;
  fileNames: Array<string> = [];
  cotizacionSubmitted: Boolean;
  formasPago$: Observable<any>;
  showAnticipo: Boolean;
  showFormaPago: Boolean;
  adendasSolped$: Observable<any>;
  submitInProgress: Boolean;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private formBuilder: FormBuilder,
    private solpedApiClient: CotizacionApiCLientService,
    private configService: ConfigService,
    private tokenService: TokenService,
    private dataApiClient: DataApiClientService,
    private router: Router,
    private utilsService: UtilityService,
    private proveedorApiClient: ProveedorApiClientService,
    private notificationsService: NotificationsService
  ) {
    if (this.router.getCurrentNavigation().previousNavigation != null) {
      const prevUrl = this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
      if (prevUrl.trim() === '/cotizaciones/listar-cotizaciones') {
        this.solpedEdit = this.router.getCurrentNavigation().extras as OfertaDto;
        sessionStorage.setItem('solpedCot', JSON.stringify(this.solpedEdit));
      }
    } else {
      this.solpedEdit = (JSON.parse(sessionStorage.getItem('solpedCot'))) as OfertaDto;
    }
  }

  ngOnInit() {
    this.proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.pesoarchivos = this.configService.get('parameters').pesoarchivos;
    this._InitRegistrarSolepdForm();
    this._InitCotizacionForm();
    this.getSolpedDocumento();
    this.getCotizacion();
    this.getVisita();
    this.getComentarios();
    this.getFormasPago();
    this._InitSolpedForm();
  }

  onChangeFormaPago($event, formaPago = '') {
    this.showAnticipo = false;
    this.showFormaPago = false;
    this.fcot.porcentaje.setValidators([]);
    this.fcot.descripcion.setValidators([]);
    const valFormaPago = $($event.target).val() !== undefined ? $($event.target).val().substring(3) : '';

    if ( valFormaPago.trim()  === '6' || formaPago.trim() === '6') {
      this.showAnticipo = true;
      this.fcot.porcentaje.setValidators([Validators.required]);
    } else {
      if (valFormaPago.trim() === '7' || formaPago.trim() === '7') {
        this.showFormaPago = true;
        this.fcot.descripcion.setValidators([Validators.required]);
      }
    }

    this.fcot.porcentaje.updateValueAndValidity();
    this.fcot.descripcion.updateValueAndValidity();

  }

  private getCotizacionSolped() {
    const cotizacionReq = {
      'idsolped': this.solpedEdit.IDSOLPED,
      'idproveedor': this.proveedor.IDPROVEEDOR
    };

    let idcotizacion = 0;
    this.solpedApiClient.getCotizacionSolped(cotizacionReq).subscribe(
      (Response) => {
        if (Response.length > 0) {
          this.cotizacionEdit = Response[0];
          this.CotizacionForm.controls.garantia.setValue(Response[0].GARANTIA);
          this.CotizacionForm.controls.tiempoentrega.setValue(Response[0].TIEMPOENTREGA);
          this.CotizacionForm.controls.plazo.setValue(Response[0].PLAZOVALIDEZ);
          this.CotizacionForm.controls.experiencia.setValue(Response[0].EXPERIENCIACOMPAÑIA);
          this.CotizacionForm.controls.formapago.setValue(Response[0].IDFORMAPAGO);
          this.CotizacionForm.controls.porcentaje.setValue(Response[0].PORCENTAJEANTICIPO);
          this.CotizacionForm.controls.descripcion.setValue(Response[0].DESCRIPCIONFORMAPAGO);
          idcotizacion = Response[0].IDCOTIZACION;
          this.onChangeFormaPago(new Event('change'), Response[0].IDFORMAPAGO + '');
        }
        this.getDocumentosCategoriaSolped(this.solpedEdit.IDCATEGORIA, idcotizacion);
      }
    );

  }

  private getFormasPago() {
    this.formasPago$ = this.dataApiClient.getFormasPago();
  }

  private _InitSolpedForm() {
    if (moment(this.solpedEdit.FECHACIERRE, 'DD/MM/YYYY').add(1, 'day').toDate() > (new Date)) {
      this.visualizarcotizaciones = 'Se pueden cargar las cotizaciones';
      this.visualizarcot = true;
      $('#formapago').prop('disabled', false);

    } else {
      this.visualizarcot = false;
      this.visualizarcotizaciones = 'No se pueden cargar las cotizaciones,' +
      ' debido a que la fecha de cierre de la oferta no se encuentra vigente.';
      $('#formapago').prop('disabled', true);
    }
    this.getCotizacionSolped();
  }

  showModalDeleteFile($event) {
    this.iddocumentodelete = Number($event.target.id.substring(3));
    $('#ModalDelete').modal('show');
  }

  getDocumentosCategoriaSolped(idcategoriasolped: number, idcotizacion: number): void {
    const documentoCategoriaRequest = {
      'idcategoria': idcategoriasolped,
      'idcotizacion': idcotizacion
    };

    this.documentosCategoriaSolped$ = this.dataApiClient.getDocumentosCategoriaSolpedProv(documentoCategoriaRequest);
  }

  confirmDeleteFile() {
    this.showspinnerDelete = true;
    this.solpedApiClient.deleteDocumentoCotizacion(this.iddocumentodelete).subscribe(
      (response) => {
        this.showspinnerDelete = false;
        $('#ModalDelete').modal('hide');
        this.notificationsService.success('Exito', 'El documento se ha eliminado correctamente');
        this.ngOnInit();
      },
      (err: any) => {
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
        this.showspinnerDelete = false;
      }
    );
  }

  get f() {
    return this.RegistrarSolpedForm.controls;
  }

  IsValid(control: string): boolean {
    return this.RegistrarSolpedForm.get(control).valid;
  }

  get fcot() {
    return this.CotizacionForm.controls;
  }

  IsValidCot(control: string): boolean {
    return this.CotizacionForm.get(control).valid;
  }

  private _InitCotizacionForm() {
    this.CotizacionForm = this.formBuilder.group({
      garantia: [null, [Validators.required]],
      tiempoentrega: [null, [Validators.required]],
      plazo: [null, [Validators.required]],
      experiencia: [null, [Validators.required]],
      formapago: [null, [Validators.required]],
      porcentaje: [null, []],
      descripcion: [null, []],
    });
  }

  private _InitRegistrarSolepdForm() {
    this.RegistrarSolpedForm = this.formBuilder.group({
      COMENTARIOS: [null, [Validators.required]]
    });
  }

  getSolpedDocumento() {
    this.solpedDocRequest$ = this.solpedApiClient.getDocumentosSolped(this.solpedEdit.IDSOLPED);

    this.solpedDocRequest$.subscribe(
      (response: Array<SolpedDocumentodto>) => {

        this.solpedDocList$ = of(response);
      },
      (err: any) => {
        this.notificationsService.warn('Alerta', 'La Petición retorno un error ' + err);
      }
    );

    this.adendasSolped$ = this.solpedApiClient.getArchivoAdjSolpedBySolped(this.solpedEdit.IDSOLPED).pipe(
      map(items => items.filter(
         d =>  (
           d.ADENDA === '1')
       ))
    );
  }

  handleFileInput(target: any) {
    if (!this.submitInProgress) {
      this.submitInProgress = true;
      $(target).addClass('submitted');
      const iddocumento = $(target).attr('id') != undefined ? $(target).attr('id').substring(3) : '';
      const files: FileList = $('#fu' + iddocumento)[0].files;

      const formData: FormData = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'application/pdf' ||
          files[i].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          files[i].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          files[i].type === 'image/jpeg' ||
          files[i].type === 'application/vnd.ms-excel.sheet.macroEnabled.12' ||
          files[i].type === 'application/msword' ||
          files[i].type === 'application/vnd.ms-excel'
        ) {
          if (files[i].size <= this.pesoarchivos) {
            let new_name = (files.item(i).name).toLowerCase();
            new_name = new_name.replace(new RegExp('\\s', 'g'), '');
            new_name = new_name.replace(new RegExp('[àáâãäå]', 'g'), 'a');
            new_name = new_name.replace(new RegExp('æ', 'g'), 'ae');
            new_name = new_name.replace(new RegExp('ç', 'g'), 'c');
            new_name = new_name.replace(new RegExp('[èéêë]', 'g'), 'e');
            new_name = new_name.replace(new RegExp('[íìíîï]', 'g'), 'i');
            new_name = new_name.replace(new RegExp('ñ', 'g'), 'n');
            new_name = new_name.replace(new RegExp('[òóóôõö]', 'g'), 'o');
            new_name = new_name.replace(new RegExp('œ', 'g'), 'oe');
            new_name = new_name.replace(new RegExp('[ùúûü]', 'g'), 'u');
            new_name = new_name.replace(new RegExp('[ýÿ]', 'g'), 'y');

            formData.append('file', files.item(i), new_name);
            this.dataApiClient.UploadFile(formData).subscribe(
              (response: any) => {
                this.submitInProgress = false;
                $(target).removeClass('submitted');
                this.fileNames.push(response + '/' + iddocumento);
                $('#dv_error' + iddocumento).addClass('d-none');
                $('#dv_inputdoc' + iddocumento).addClass('d-none');
                $('#dv_succes' + iddocumento).removeClass('d-none');
                $('#dv_succes' + iddocumento).html('Se cargó el documento ' + files.item(i).name);
              },
              (err: any) => {
                this.submitInProgress = false;
                $(target).removeClass('submitted');
                $('#dv_error' + iddocumento).removeClass('d-none');
                $('#dv_error' + iddocumento).html('El archivo no ha sido cargado, inténtenlo de nuevo');
              }
            );
          } else {
            this.submitInProgress = false;
            $(target).removeClass('submitted');
            $('#dv_error' + iddocumento).removeClass('d-none');
            $('#dv_error' + iddocumento).html('Tamaño no válido, el tamaño máximo permitido es ' +
            Math.round((this.pesoarchivos / 1024000) * 10) / 10 + ' MB');
          }
        } else {
          this.submitInProgress = false;
          $(target).removeClass('submitted');
          $('#dv_error' + iddocumento).removeClass('d-none');
          $('#dv_error' + iddocumento).html('Los documentos permitidos son PDF, Word, Excel, Imágenes en JPG');
        }
      }
    }
  }

  checkSubmmited(idcategoriasolped: any) {
    return $('#btn' + idcategoriasolped).hasClass('submitted');
  }

  guardarcotizacion() {
    if (this.showspinner !== true) {
      this.showspinner = true;
      this.cotizacionSubmitted = true;

      let errorDocumentos = false;

      this.documentosCategoriaSolped$.subscribe(
        (response) => {
          response.forEach( d => {
            if ($('#fu' + d.IDDOCUMENTOCATEGORIASOLPED).length > 0) {
              const notUploadFile = $('#dv_succes' + d.IDDOCUMENTOCATEGORIASOLPED).hasClass('d-none');
              if (d.OBLIGATORIO == 1 && notUploadFile) {
                $('#dv_error' + d.IDDOCUMENTOCATEGORIASOLPED).removeClass('d-none');
                $('#dv_error' + d.IDDOCUMENTOCATEGORIASOLPED).html('Campo obligatorio');
                errorDocumentos = true;
              } else {
                $('#dv_error' + d.IDDOCUMENTOCATEGORIASOLPED).addClass('d-none');
              }
            }
          });
          if (errorDocumentos || this.CotizacionForm.invalid) {
            this.showspinner = false;
            return;
          }

          if (this.cotizacionEdit === null || this.cotizacionEdit === undefined) {
            const cotizacionForm = {
              'IDPROVEEDOR': this.proveedor.IDPROVEEDOR,
              'IDSOLPED': this.solpedEdit.IDSOLPED,
              'REQ_EVALUACION': 0,
              'USUARIO_CREACION': this.tokenService.getNomUser(),
              'GARANTIA': this.CotizacionForm.controls.garantia.value != null ?
              (this.CotizacionForm.controls.garantia.value + '').toUpperCase() : '',
              'TIEMPOENTREGA': this.CotizacionForm.controls.tiempoentrega.value != null ?
              (this.CotizacionForm.controls.tiempoentrega.value + '').toUpperCase() : '',
              'PLAZOVALIDEZ': this.CotizacionForm.controls.plazo.value != null ?
              (this.CotizacionForm.controls.plazo.value + '').toUpperCase() + '' : '',
              'EXPERIENCIACOMPAÑIA': this.CotizacionForm.controls.experiencia.value != null ?
                (this.CotizacionForm.controls.experiencia.value + '').toUpperCase() : '',
              'IDFORMAPAGO': this.CotizacionForm.controls.formapago.value,
              'PORCENTAJEANTICIPO': this.showAnticipo ? this.CotizacionForm.controls.porcentaje.value : '',
              'DESCRIPCIONFORMAPAGO': this.showFormaPago ?
              (this.CotizacionForm.controls.descripcion.value != null ?
                (this.CotizacionForm.controls.descripcion.value + '').toUpperCase() : '') : '',
              'FILES': this.fileNames
            };
            this.solpedApiClient.createCotizacion(cotizacionForm).subscribe(
              (val: any) => {
                this.showspinner = false;
                this.cotizacionSubmitted = false;
                this.fileNames = [];
                this.notificationsService.success('Exito', 'La información se guardo correctamente');
                this._InitSolpedForm();
              },
              (err: any) => {
                this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
                this.showspinner = false;
                this.cotizacionSubmitted = false;
              }
            );
          } else {
            const cotizacionForm = {
              'IDCOTIZACION': this.cotizacionEdit.IDCOTIZACION,
              'IDPROVEEDOR': this.proveedor.IDPROVEEDOR,
              'IDSOLPED': this.solpedEdit.IDSOLPED,
              'REQ_EVALUACION': 0,
              'USUARIO_ACTUALIZACION': this.tokenService.getNomUser(),
              'GARANTIA': this.CotizacionForm.controls.garantia.value != null ?
              (this.CotizacionForm.controls.garantia.value + '').toUpperCase() : '',
              'TIEMPOENTREGA': this.CotizacionForm.controls.tiempoentrega.value != null ?
              (this.CotizacionForm.controls.tiempoentrega.value + '').toUpperCase() : '',
              'PLAZOVALIDEZ': this.CotizacionForm.controls.plazo.value != null ?
              (this.CotizacionForm.controls.plazo.value + '').toUpperCase() + '' : '',
              'EXPERIENCIACOMPAÑIA': this.CotizacionForm.controls.experiencia.value != null ?
              (this.CotizacionForm.controls.experiencia.value + '').toUpperCase() : '',
              'IDFORMAPAGO': this.CotizacionForm.controls.formapago.value,
              'PORCENTAJEANTICIPO': this.showAnticipo ? this.CotizacionForm.controls.porcentaje.value : '',
              'DESCRIPCIONFORMAPAGO': this.showFormaPago ?
              (this.CotizacionForm.controls.descripcion.value != null ?
                (this.CotizacionForm.controls.descripcion.value + '').toUpperCase() : '') : '',
              'FILES': this.fileNames
            };
    
            this.solpedApiClient.updateCotizacion(cotizacionForm).subscribe(
              (val: any) => {
                this.showspinner = false;
                this.cotizacionSubmitted = false;
                this.fileNames = [];
                this.notificationsService.success('Exito', 'La información se guardo correctamente');
                this._InitSolpedForm();
              },
              (err: any) => {
                this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
                this.showspinner = false;
                this.cotizacionSubmitted = false;
              }
            );
          }
        }
      );
    }
  }

  enableButton(target: any) {
    const iddocumento = $(target).attr('id').substring(2);
    $('#btn' + iddocumento).prop('disabled', false);
    $('#lbl' + iddocumento).html($('#' + $(target).attr('id'))[0].files[0].name);
  }

  getComentarios() {
    this.comentariosRequest$ = this.solpedApiClient.getListadoComentarios(this.solpedEdit.IDSOLPED);

    this.comentariosRequest$.subscribe(
      (response: Array<Comentario>) => {

        this.comentariosList$ = of(response);
      },
      (err: any) => {
        this.notificationsService.warn('Alerta', 'La Petición retorno un error ' + err);
      }
    );
  }

  downloadFile(documento, repositorio) {
    const datos = {
      'documento' : documento
    };
    let downloadFile = null;
    if (repositorio === 1) {
      downloadFile = this.proveedorApiClient.DownloadFileSolped(datos);
    }
    if (repositorio === 2) {
      downloadFile = this.proveedorApiClient.DownloadFileCotizacion(datos);
    }
    if (repositorio === 5) {
      downloadFile = this.proveedorApiClient.DownloadFileAdjSolped(datos);
    }
    

    downloadFile.subscribe(
      (res) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {

          window.navigator.msSaveOrOpenBlob(res.file, res.name);
        } else {
          const byteArrFile = this.utilsService.base64ToArrayBuffer(res.file);
          this.utilsService.saveByteArray(res.name, byteArrFile, {
            type: 'application/octet-stream'
          });
        }
      }
    );
  }

  getCotizacion() {

    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    if ( proveedor !== null) {
      this.solpedRequest$ = this.solpedApiClient.getCotizacion(proveedor.IDPROVEEDOR);

      this.solpedRequest$.subscribe(
        (response: Array<OfertaDto>) => {

          this.solpedList$ = of(response.filter(f => f.IDSOLPED === this.solpedEdit.IDSOLPED));
        },
        (err: any) => {
          this.notificationsService.warn('Alerta', 'La Petición retorno un error ' + err);
        }
      );
    }
  }

  getVisita() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    if ( proveedor !== null) {
      const visitaProveedorSolped = {
        'idproveedor': proveedor.IDPROVEEDOR,
        'idsolped': this.solpedEdit.IDSOLPED
      };
      this.solpedApiClient.getVisitaProveedorSolped(visitaProveedorSolped).subscribe(
        (response: Array<OfertaDto>) => {
          this.visitaList$ = of(response);
        },
        (err: any) => {
          this.notificationsService.warn('Alerta', 'La Petición retorno un error ' + err);
        }
      );
    }
  }
}
