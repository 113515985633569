export enum TipoProveedor {
    PROVEEDOR = 11,
    CLIENTE_DEUDOR = 12,
    EMPLEADO = 13,
    CONCESION_VIAL = 17
}

export enum EstadoProveedor {
    NUEVOPROVEEDOR = 0,
    PREREGISTRO = 1,
    REGISTRADO = 2,
    PENDIENTEPROVEEDOR = 3,
    ACEPTADO = 4,
    REVISIONLISTAS = 5,
    PENDIENTESAP = 10,
    APROBADO = 11,
    INACTIVO = 12,
    VETADO = 13,
    ANULADO = 14,
    HABILITADOCOTIZAR = 15,
    HABILITADOREGISTRO = 16
}

export enum TipoIdentificacion {
    Cédula_de_ciudadanía = 1,
    Cédula_de_extranjería = 2,
    NIT = 3,
    RUT = 4,
    Pasaporte = 5,
    Visa = 6
}

export enum TipoPersona {
    NATURAL = 1,
    JURIDICA = 2
}

export enum ClaseProveedor {
    HABITUAL = 3,
    CRITICO = 4,
    OCACIONAL = 5
}
