// Angular core modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEsCo from '@angular/common/locales/es-CO';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEsCo, 'es-CO');

// Routes
import { AppRoutingModule } from './app-routing.module';

// Services
import { ConfigService } from './app-config.service';

// third party modules
// import { DataTablesModule } from 'angular-datatables';
import {
  SimpleNotificationsModule,
  NotificationsService
} from 'angular2-notifications';

// Modules
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { GestionProveedorModule } from './gestion-proveedor/gestion-proveedor.module';
import { CotizacionesModule } from '../app/cotizaciones/cotizaciones.module';
import { AuthGuard } from './shared/guards/auth.guard';
import { TokenInterceptor } from './shared/HttpInterceptor/token-interceptor';


export function configServiceFactory(config: ConfigService) {
  return () => config.load();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    SimpleNotificationsModule.forRoot(),
    // DataTablesModule,

    AuthenticationModule,
    GestionProveedorModule,
    CotizacionesModule,
    AppRoutingModule
  ],
  providers: [
    AuthGuard,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
