import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  saveByteArray(reportName, byte, contentType) {
    const blob = new Blob([byte], contentType);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    link.click();
  }

  base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  // Devuelve la fecha sin ajuste de zona horaria
  convertDateToUTC(fecha: string) {
    return new Date(
      new Date(fecha).getUTCFullYear(),
      new Date(fecha).getUTCMonth(),
      new Date(fecha).getUTCDate(),
      0,
      0,
      1
    );
  }
}
