import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/app-config.service';
import { share } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DataApiClientService {

  api: any;
  maestrosObj: any;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
    this.api = this.configService.get('api');
    this.maestrosObj = this.api.endpoints.filter(c => c.maestros)[0];
  }

  getTiposEmpresa(): Observable<any> {
    const getTiposEmpresa = this.maestrosObj.maestros.filter((m: any) => m.name === 'getTiposEmpresa')[0];
    return this.http.get(`${this.api.basepath}${getTiposEmpresa.url}`);
  }

  getTiposProveedor(): Observable<any> {
    const getTiposProveedor = this.maestrosObj.maestros.filter((m: any) => m.name === 'getTiposProveedor')[0];
    return this.http.get(`${this.api.basepath}${getTiposProveedor.url}`);
  }
  getTiposPersona(): Observable<any> {
    const getTiposPersona = this.maestrosObj.maestros.filter((m: any) => m.name === 'getTiposPersona')[0];
    return this.http.get(`${this.api.basepath}${getTiposPersona.url}`);
  }
  getPaises(): Observable<any> {
    const getPaises = this.maestrosObj.maestros.filter((m: any) => m.name === 'getPaises')[0];
    return this.http.get(`${this.api.basepath}${getPaises.url}`);
  }
  getDepartamentosPais(idpais: number): Observable<any> {
    const getDepartamentosPais = this.maestrosObj.maestros.filter((m: any) => m.name === 'getDepartamentosPais')[0];
    return this.http.get(`${this.api.basepath}${getDepartamentosPais.url}?idpais=${idpais}`);
  }
  getCiudadesDepartamento(iddepartamento: number): Observable<any> {
    const getCiudadesDepartamento = this.maestrosObj.maestros.filter((m: any) => m.name === 'getCiudadesDepartamento')[0];
    return this.http.get(`${this.api.basepath}${getCiudadesDepartamento.url}?iddepartamento=${iddepartamento}`);
  }
  getTiposIdentificacion(): Observable<any> {
    const getTiposIdentificacion = this.maestrosObj.maestros.filter((m: any) => m.name === 'getTiposIdentificacion')[0];
    return this.http.get(`${this.api.basepath}${getTiposIdentificacion.url}`);
  }
  getTipoIdentificacionPersona(): Observable<any> {
    const getTipoIdentificacionPersona = this.maestrosObj.maestros.filter((m: any) => m.name === 'getTipoIdentificacionPersona')[0];
    return this.http.get(`${this.api.basepath}${getTipoIdentificacionPersona.url}`);
  }
  getEntidadesFinancieras(): Observable<any> {
    const getEntidadesFinancieras = this.maestrosObj.maestros.filter((m: any) => m.name === 'getEntidadesFinancieras')[0];
    return this.http.get(`${this.api.basepath}${getEntidadesFinancieras.url}`);
  }

  getFormasPago(): Observable<any> {
    const getFormasPago = this.maestrosObj.maestros.filter((m: any) => m.name === 'getFormasPago')[0];
    return this.http.get(`${this.api.basepath}${getFormasPago.url}`);
  }
  getInfoCuentaUsuario(idusuario: string): Observable<any> {
    const getInfoCuentaUsuario = this.maestrosObj.maestros.filter((m: any) => m.name === 'getInfoCuentaUsuario')[0];
    return this.http.get(`${this.api.basepath}${getInfoCuentaUsuario.url}?idusuario=${idusuario}`);
  }

  getUbicacion(idubicacion: string): Observable<any> {
    const getUbicacion = this.maestrosObj.maestros.filter((m: any) => m.name === 'getUbicacion')[0];
    return this.http.get(`${this.api.basepath}${getUbicacion.url}?idubicacion=${idubicacion}`);
  }

  getInfoBasica(idinfobasica: string): Observable<any> {
    const getInfoBasica = this.maestrosObj.maestros.filter((m: any) => m.name === 'getInfoBasica')[0];
    return this.http.get(`${this.api.basepath}${getInfoBasica.url}?idinfobasica=${idinfobasica}`);
  }

  getCuentaBancaria(idcuentabancaria: string): Observable<any> {
    const getCuentaBancaria = this.maestrosObj.maestros.filter((m: any) => m.name === 'getCuentaBancaria')[0];
    return this.http.get(`${this.api.basepath}${getCuentaBancaria.url}?idcuentabancaria=${idcuentabancaria}`);
  }

  UpdateUbicacion(form: any): Observable<any> {
    const updateUbicacion = this.maestrosObj.maestros.filter((m: any) => m.name === 'updateUbicacion')[0];
    return this.http.put(`${this.api.basepath}${updateUbicacion.url}`, form);
  }

  UpdateUsuario(form: any): Observable<any> {
    const updateUsuario = this.maestrosObj.maestros.filter((m: any) => m.name === 'updateUsuario')[0];
    return this.http.put(`${this.api.basepath}${updateUsuario.url}`, form);
  }

  CreateUsuario(form: any): Observable<any> {
    const createUsuario = this.maestrosObj.maestros.filter((m: any) => m.name === 'createUsuario')[0];
    return this.http.post(`${this.api.basepath}${createUsuario.url}`, form);
  }
  CambiarContrasenia(form: any): Observable<any> {
    const Contrasenia = this.maestrosObj.proveedores.filter((m: any) => m.name === 'Usuarios_CambiarContrasena')[0];
    return this.http.post(`${this.api.basepath}${Contrasenia.url}`, form);
  }
  recordarContrasenia(form: any): Observable<any> {
    const RecordarContrasenia = this.maestrosObj.proveedores.filter((m: any) => m.name === 'Usuarios_RecordarContrasena')[0];
    return this.http.post(`${this.api.basepath}${RecordarContrasenia.url}`, form);
  }
  ListarUsuario(form: any): Observable<any> {
    const listarUsuario = this.maestrosObj.proveedores.filter((m: any) => m.name === 'Usuarios_ListarUsuarios')[0];
    return this.http.post(`${this.api.basepath}${listarUsuario.url}`, form);
  }
  CreateUbicacion(form: any): Observable<any> {
    const createUbicacion = this.maestrosObj.maestros.filter((m: any) => m.name === 'createUbicacion')[0];
    return this.http.post(`${this.api.basepath}${createUbicacion.url}`, form);
  }

  getDocumentosTipoProveedor(idtipoproveedor: number): Observable<any> {
    const getDocumentosTipoProveedor = this.maestrosObj.maestros.filter((m: any) => m.name === 'getDocumentosTipoProveedor')[0];
    return this.http.get(`${this.api.basepath}${getDocumentosTipoProveedor.url}?idtipoproveedor=${idtipoproveedor}`);
  }

  UploadFile(form: any): Observable<any> {
    const UploadFile = this.maestrosObj.maestros.filter((m: any) => m.name === 'UploadFile')[0];
    return this.http.post(`${this.api.basepath}${UploadFile.url}`, form).pipe(
      share()
    );
  }

  getDocumentosCategoriaSolpedProv(form: any): Observable<any> {
    const getDocumentosCategoriaSolpedProv = this.maestrosObj.maestros.filter((m: any) => m.name === 'getDocumentosCategoriaSolpedProv')[0];
    return this.http.post(`${this.api.basepath}${getDocumentosCategoriaSolpedProv.url}`, form);
  }
}
