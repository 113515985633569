import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';


import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { MsgAppComponent } from './msg-app/msg-app.component';
import { FooterComponent } from './footer/footer.component';

import { DatepickerComponent } from './datepicker/datepicker.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { OrderByPipe } from '../pipes/order-by.pipe';

export const COMPONENTS = [
    HeaderComponent,
    NavigationComponent,
    MsgAppComponent,
    FooterComponent,

    DatepickerComponent,
    SpinnerComponent,
    BreadcrumbComponent,
    OrderByPipe
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class ComponentsModule { }
