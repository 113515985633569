import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, SimpleChange, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ValidationService, MustMatch } from 'src/app/shared/utils/validation.service';
import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-datos-usuario-register',
  templateUrl: './datos-usuario-register.component.html',
  styleUrls: ['./datos-usuario-register.component.sass']
})
export class DatosUsuarioRegisterComponent  implements OnInit, OnChanges, OnDestroy {

  @Input() form: FormGroup;
  @Input() formDirective: FormGroupDirective;
  @Input() isEdit: boolean;
  @Input() cuentaUsuario: Observable<any>;
  @Output() createProveedor: EventEmitter<boolean> = new EventEmitter<boolean>();

  CuentaUsuarioForm: FormGroup;
  CuentaUsuarioArray: FormArray;
  usuarioFormSubmitted: boolean;
  usuarioFormValid: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public validationService: ValidationService
  ) { }

  ngOnInit() {
    this._InitUserAccountForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const cuentaUsuario: SimpleChange = changes.cuentaUsuario;

    if (cuentaUsuario !== undefined && cuentaUsuario.currentValue !== undefined) {
      this._PreloadData();
    }
  }

  ngOnDestroy() {
    this.CuentaUsuarioArray.removeAt(this.CuentaUsuarioArray.length - 1);

    this.CuentaUsuarioArray.reset();
  }

  doCreateProveedor(event: Event) {
    this.usuarioFormSubmitted = true;
    if (this.CuentaUsuarioForm.valid) {
      this.usuarioFormValid = true;
      this.createProveedor.emit(true);
    } else {
      this.usuarioFormValid = false;
      this.createProveedor.emit(false);
    }
  }

  get f() { return this.CuentaUsuarioForm.controls; }

  IsValid(control: string): boolean {
    return this.CuentaUsuarioForm.controls[control].valid;
  }

  showHidePassword(event, source: string) {
    const inputPassword = $('[name=\'' + source + '\']');
    if (inputPassword.attr('type') === 'password') {
      inputPassword.attr('type', 'text');
    } else {
      inputPassword.attr('type', 'password');
    }
  }

  showModalCancel() {
    $('#CancelModal').modal('show');
  }

  private _InitUserAccountForm() {
    this.CuentaUsuarioArray = this.form.controls.CuentaUsuario as FormArray;
    const arrayLength = this.CuentaUsuarioArray.length;
    this.CuentaUsuarioForm = this.formBuilder.group({
      IdUsuario: ['', []],
      User: ['', []],
      Email: ['', [Validators.required,
Validators.pattern(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/)]],
      RepeatEmail: ['', [Validators.required]],
      Password: ['', [Validators.required,
Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,12}$/)]],
      RepeatPassword: ['', [Validators.required]]
    },
      {
        validators: [
          MustMatch('Email', 'RepeatEmail'),
          MustMatch('Password', 'RepeatPassword')
        ]
      }
    );

    this.CuentaUsuarioArray.insert(arrayLength, this.CuentaUsuarioForm);
  }

  private _PreloadData() {
    if (this.isEdit) {
      this.cuentaUsuario.subscribe(
        (resCuenta: Array<any>) => {

          this.CuentaUsuarioForm.setValue({
            IdUsuario: resCuenta[0].IDUSUARIO,
            User: resCuenta[0].NOM_USUARIO,
            Email: resCuenta[0].CORREO,
            RepeatEmail: resCuenta[0].CORREO,
            Password: '',
            RepeatPassword: ''
          });
        }
      );
    }
  }
}
