import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { Observable, of } from 'rxjs';
import { FormGroup, FormGroupDirective, FormBuilder, Validators, FormArray, ValidatorFn, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { TipoIdentificacion, TipoPersona, TipoProveedor } from 'src/app/shared/utils/general-enums-provider.enum';

declare var $: any;

@Component({
  selector: 'app-info-basica-register',
  templateUrl: './info-basica-register.component.html',
  styleUrls: ['./info-basica-register.component.sass']
})
export class InfoBasicaRegisterComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() showTipoEmpresa: boolean;
  @Input() infoBasica: Observable<any>;
  @Input() formDirective: FormGroupDirective;
  @Output() showUbicacion: EventEmitter<boolean> = new EventEmitter<boolean>();

  InfoBasica: FormGroup;
  InfoBasicaArray: FormArray;
  tiposPersona$: Observable<any>;
  tiposIdentificacion$: Observable<any>;
  tiposEmpresa$: Observable<any>;
  tiposIdentificacionList: any;
  infoBasicaSubmitted: boolean;

  constructor(
    private dataApiClient: DataApiClientService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this._LoadSelectList();
    this._InitBasicsForm();
    this._RequireFields();
  }

  ngOnDestroy(): void {
    this.InfoBasicaArray.removeAt(this.InfoBasicaArray.length - 1);
    this.InfoBasicaArray.reset();
  }

  doShowUbicacion(event: Event) {
    this.infoBasicaSubmitted = true;
    if (this.InfoBasica.valid) {
      this.showUbicacion.emit(true);
    } else {
      this.showUbicacion.emit(false);
    }
  }

  get f() { return this.InfoBasica.controls; }

  IsValid(control: string): boolean {
    return this.InfoBasica.controls[control].valid;
  }

  getTiposPersona(): void {
    this.tiposPersona$ = this.dataApiClient.getTiposPersona();
  }

  getTiposIdentificacion(): void {

    this.tiposIdentificacion$ = this.dataApiClient.getTiposIdentificacion().pipe(
      map(ti => this.tiposIdentificacionList = ti)
    );
  }

  getTiposEmpresa() {
    this.tiposEmpresa$ = this.dataApiClient.getTiposEmpresa();
  }

  changeNumeroIdent( event) {
    $('#nomusuario').val(event.target.value);
  }

  validarnumeroidentificacion(event) {
  }

  public onChangeTipoPersona(event: Event): void {
    const tipoPersona = Number.parseInt(this.f.TipoPersona.value, 10);
    if (tipoPersona === TipoPersona.JURIDICA) {
      this.tiposIdentificacion$ = of(this.tiposIdentificacionList.filter(f => f.IDTIPOIDENTIFICACION === TipoIdentificacion.NIT));
      this.f.PrimerNombre.setValidators([]);
      this.f.PrimerApellido.setValidators([]);
      this.f.RazonSocial.setValidators([Validators.required]);
      this.f.PrimerNombre.setValue('');
      this.f.SegundoNombre.setValue('');
      this.f.PrimerApellido.setValue('');
      this.f.SegundoApellido.setValue('');
    } else {
      if(this.form.controls.TipoProveedor.value === TipoProveedor.CLIENTE_DEUDOR)
      {
        console.log(this.tiposIdentificacionList)
        this.tiposIdentificacion$ = of(this.tiposIdentificacionList.filter(f => f.IDTIPOIDENTIFICACION === TipoIdentificacion.RUT || 
                                                                           f.IDTIPOIDENTIFICACION === TipoIdentificacion.Cédula_de_ciudadanía || 
                                                                           f.IDTIPOIDENTIFICACION === TipoIdentificacion.Cédula_de_extranjería));
      }
      if(this.form.controls.TipoProveedor.value === TipoProveedor.PROVEEDOR)
      {
        console.log(this.tiposIdentificacionList)
        this.tiposIdentificacion$ = of(this.tiposIdentificacionList.filter(f => f.IDTIPOIDENTIFICACION === TipoIdentificacion.RUT));
      }
      this.f.PrimerNombre.setValidators([Validators.required]);
      this.f.PrimerApellido.setValidators([Validators.required]);
      this.f.RazonSocial.setValidators([]);
      this.f.RazonSocial.setValue('');
      this.f.Sigla.setValue('');
    }
    this.f.PrimerNombre.updateValueAndValidity();
    this.f.PrimerApellido.updateValueAndValidity();
    this.f.RazonSocial.updateValueAndValidity();
  }

  public onChangeTipoIdentificacion(event: Event): void {
    const tipoId = Number.parseInt(this.f.TipoIdentificacion.value, 10);
    if (tipoId === TipoIdentificacion.NIT || tipoId === TipoIdentificacion.RUT) {
      this.f.DigitoVerificacion.setValidators([Validators.min(0), Validators.max(9), Validators.required]);
    } else {
      this.f.DigitoVerificacion.setValidators([Validators.min(0), Validators.max(9)]);
      this.f.DigitoVerificacion.setValue('');
    }

    this.f.DigitoVerificacion.updateValueAndValidity();
  }

  habilitarSelect(isEdit: boolean, event: Event) {
    if (isEdit) {
      const el: HTMLSelectElement = event.target as HTMLSelectElement;
      $(el.options).prop('disabled', true);
      return false;
    }
  }

  onKeyPressNumber(event) {
    return (/[0-9]/.test(String.fromCharCode(event.which)));
  }

  showModalCancel() {
    $('#CancelModal').modal('show');
  }

  private _LoadSelectList() {
    this.getTiposPersona();
    this.getTiposIdentificacion();
    this.getTiposEmpresa();
  }

  private _InitBasicsForm() {
    this.InfoBasicaArray = this.form.controls.InfoBasica as FormArray;
    const arrayLength = this.InfoBasicaArray.length;
    this.InfoBasica = this.formBuilder.group({
      IdInfoBasica: ['', []],
      TipoPersona: [null, [Validators.required]],
      TipoIdentificacion: [null, [Validators.required]],
      NumeroIdentificacion: ['', [
        Validators.required,
        Validators.maxLength(12),
        Validators.pattern('[A-ZA-Z0-9]*')]
      ],

      validarnumeroidentificacion: ['', [
        Validators.required,
        Validators.maxLength(12),
        Validators.pattern('[A-ZA-Z0-9]*'),
        InfoBasicaRegisterComponent.alertavalidarnumeroidentificacion]
      ],
      DigitoVerificacion: ['', [Validators.max(9), Validators.min(0)]],
      RazonSocial: ['', []],
      Sigla: ['', []],
      PrimerNombre: ['', []],
      SegundoNombre: ['', []],
      PrimerApellido: ['', []],
      SegundoApellido: ['', []],
      NombreComercial: ['', []],
      TipoEmpresa: [null, []],
      IdUbicacion: ['', []]
    });

    this.InfoBasicaArray.insert(arrayLength, this.InfoBasica);
  }


  static alertavalidarnumeroidentificacion(control: AbstractControl): ValidationErrors | null {
    return $("#validarnumeroidentificacion").val() === $('#numeroidentificacion').val() ? null : { ['alertavalidarnumeroidentificacion']: true };
  }


  _RequireFields() {
    if (this.showTipoEmpresa) {
      this.f.TipoEmpresa.setValidators(Validators.required);
    } else {
      this.f.TipoEmpresa.clearValidators();
    }
    this.f.TipoEmpresa.updateValueAndValidity();
  }
}
