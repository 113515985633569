import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProveedorApiClientService {

  api: any;
  proveedoresObj: any;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
    this.api = this.configService.get('api');
    this.proveedoresObj = this.api.endpoints.filter(c => c.proveedores)[0];
  }

  UpdateProveedor(form: any): Observable<any> {
    const UpdateProveedor = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'updateProveedor')[0];
    return this.http.put(`${this.api.basepath}${UpdateProveedor.url}`, form);
  }

  CreateProveedor(form: any): Observable<any> {
    const CreateProveedor = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'CreateProveedor')[0];
    return this.http.post(`${this.api.basepath}${CreateProveedor.url}`, form);
  }

  CargarAdjuntosProveedor(form: any): Observable<any> {
    const cargarAdjuntosProveedor = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'cargarAdjuntosProveedor')[0];
    return this.http.post(`${this.api.basepath}${cargarAdjuntosProveedor.url}`, form);
  }

  CreateInfoBasica(form: any): Observable<any> {
    const CreateInfoBasica = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'CreateInfoBasica')[0];
    return this.http.post(`${this.api.basepath}${CreateInfoBasica.url}`, form);
  }

  CreateInfoJuridica(form: any): Observable<any> {
    const CreateInfoJuridica = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'CreateInfoJuridica')[0];
    return this.http.post(`${this.api.basepath}${CreateInfoJuridica.url}`, form);
  }

  CreatePersonaJuridica(form: any): Observable<any> {
    const CreatePersonaJuridica = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'CreatePersonaJuridica')[0];
    return this.http.post(`${this.api.basepath}${CreatePersonaJuridica.url}`, form);
  }

  UpdatePersonaJuridica(form: any): Observable<any> {
    const updatePersonaJuridica = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'updatePersonaJuridica')[0];
    return this.http.post(`${this.api.basepath}${updatePersonaJuridica.url}`, form);
  }

  RemovePersonasJuridicas(form: any): Observable<any> {
    const deletePersonasJuridicas = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'deletePersonasJuridicas')[0];
    return this.http.post(`${this.api.basepath}${deletePersonasJuridicas.url}`, form);
  }

  CreateCuentaBancaria(form: any): Observable<any> {
    const createCuentaBancaria = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'createCuentaBancaria')[0];
    return this.http.post(`${this.api.basepath}${createCuentaBancaria.url}`, form);
  }

  FindProveedor(id: string): Observable<any> {
    const getProveedorById = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'findProveedor')[0];
    return this.http.get(`${this.api.basepath}${getProveedorById.url}?id=${id}`);
  }

  GetProveedorById(numeroidentificacion: string): Observable<any> {
    const getProveedorById = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'getProveedorById')[0];
    return this.http.get(`${this.api.basepath}${getProveedorById.url}?numeroidentificacion=${numeroidentificacion}`);
  }

  getAbreviaturas(): Observable<any> {
    const getAbreviaturas = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'getAbvrsDir')[0];
    return this.http.get(`${this.api.basepath}${getAbreviaturas.url}`);
  }

  proveedorGetAllData(id: number): Observable<any> {
    const proveedor = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'proveedorGetAllData')[0];
    return this.http.get(`${this.api.basepath}${proveedor.url}?id=${id}`);
  }

  UpdateInfoBasica(form: any): Observable<any> {
    const updateInfoBasica = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'updateInfoBasica')[0];
    return this.http.put(`${this.api.basepath}${updateInfoBasica.url}`, form);
  }

  UpdateCuentaBancaria(form: any): Observable<any> {
    const updateCuentaBancaria = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'updateCuentaBancaria')[0];
    return this.http.put(`${this.api.basepath}${updateCuentaBancaria.url}`, form);
  }

  CreateInfoFinanciera(form: any): Observable<any> {
    const createInfoFinanciera = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'createInfoFinanciera')[0];
    return this.http.post(`${this.api.basepath}${createInfoFinanciera.url}`, form);
  }

  UpdateInfoFinanciera(form: any): Observable<any> {
    const updateInfoFinanciera = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'updateInfoFinanciera')[0];
    return this.http.put(`${this.api.basepath}${updateInfoFinanciera.url}`, form);
  }

  FindInfoFinanciera(idproveedor: string): Observable<any> {
    const findInfoFinanciera = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'findInfoFinanciera')[0];
    return this.http.get(`${this.api.basepath}${findInfoFinanciera.url}?idproveedor=${idproveedor}`);
  }

  getDocumentosProveedorAll(idtipoproveedor: number): Observable<any> {
    const getDocumentosProveedorAll = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'getDocumentosProveedorAll')[0];
    return this.http.get(`${this.api.basepath}${getDocumentosProveedorAll.url}?idproveedor=${idtipoproveedor}`);
  }

  FindInfoBasica(idproveedor: string): Observable<any> {
    const findInfoBasica = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'findInfoBasica')[0];
    return this.http.get(`${this.api.basepath}${findInfoBasica.url}?idproveedor=${idproveedor}`);
  }

  findDeclaracionConflictos(idproveedor: string): Observable<any> {
    const findDeclaracionConflictos = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'findDeclaracionConflictos')[0];
    return this.http.get(`${this.api.basepath}${findDeclaracionConflictos.url}?idproveedor=${idproveedor}`);
  }

  GetPeronasJuridicas(idproveedor: string): Observable<any> {
    const getPeronasJuridicas = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'getPeronasJuridicas')[0];
    return this.http.get(`${this.api.basepath}${getPeronasJuridicas.url}?idproveedor=${idproveedor}`);
  }

  DownloadFileSolped(form: any): Observable<any> {
    const DownloadFileSolped = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'DownloadFileSolped')[0];
    return this.http.post(`${this.api.basepath}${DownloadFileSolped.url}`, form);
  }

  DownloadFileCotizacion(form: any): Observable<any> {
    const DownloadFileCotizacion = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'DownloadFileCotizacion')[0];
    return this.http.post(`${this.api.basepath}${DownloadFileCotizacion.url}`, form);
  }

  DownloadFileProveedor(form: any): Observable<any> {
    const DownloadFileProveedor = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'DownloadFileProveedor')[0];
    return this.http.post(`${this.api.basepath}${DownloadFileProveedor.url}`, form);
  }

  DownloadFileAdjSolped(form: any): Observable<any> {
    const DownloadFileAdjSolped = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'DownloadFileAdjSolped')[0];
    return this.http.post(`${this.api.basepath}${DownloadFileAdjSolped.url}`, form);
  }

  downloadPDFInfoProveedor(idproveedor: number): Observable<any> {
    const getInfoDiligenciada = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'downloadPDFInfoProveedor')[0];
    return this.http.post(`${this.api.basepath}${getInfoDiligenciada.url}?idproveedor=${idproveedor}`, idproveedor);
  }

  CreateDeclaracionConflictos(form: any): Observable<any> {
    const CreateDeclaracionConflictos = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'createDeclaracionConflictos')[0];
    return this.http.post(`${this.api.basepath}${CreateDeclaracionConflictos.url}`, form);
  }

  UpdateDeclaracionConflictos(form: any): Observable<any> {
    const updateDeclaracionConflictos = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'updateDeclaracionConflictos')[0];
    return this.http.post(`${this.api.basepath}${updateDeclaracionConflictos.url}`, form);
  }

  deleteDocumentoProveedor(id: number): Observable<any> {
    const deleteDocumentoProveedor = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'deleteDocumentoProveedor')[0];
    return this.http.post(`${this.api.basepath}${deleteDocumentoProveedor.url}?iddocumentoproveedor=${id}`, id);
  }

  finalizarcorreccion(id: number): Observable<any> {
    const finalizarCorreccionInfo = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'finalizarCorreccionInfo')[0];
    return this.http.put(`${this.api.basepath}${finalizarCorreccionInfo.url}?idproveedor=${id}`, id);
  }

  FinalizarActAnual(id: number): Observable<any> {
    const FinalizarActAnual = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'FinalizarActAnual')[0];
    return this.http.put(`${this.api.basepath}${FinalizarActAnual.url}?idproveedor=${id}`, id);
  }
  getUsuariosByIdentificacion(identificacion: string): Observable<any> {
    const getUsuariosByIdentificacion = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'getUsuariosByIdentificacion')[0];
    return this.http.get(`${this.api.basepath}${getUsuariosByIdentificacion.url}?identificacion=${identificacion}`);
  }

  getNumSolpedByProvider(idProvider: number): Observable<any> {
    const getNumSolpedByProvider = this.proveedoresObj.proveedores.filter((m: any) => m.name === 'getNumSolpedByProvider')[0];
    return this.http.get(`${this.api.basepath}${getNumSolpedByProvider.url}?idProvider=${idProvider}`);
  }
}
