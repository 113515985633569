import { Injectable } from '@angular/core';
import { InfoBasicaDTO } from '../shared/models/info-basica-dto';
import { Localizacion } from '../shared/models/localizacion';
import { CuentaBancaria } from '../shared/models/cuentaBancaria';
import { CuentaUsuario } from '../shared/models/cuentaUsuario';
import { Proveedor } from '../shared/models/proveedor';
import { InfoFinanciera } from '../shared/models/info-financiera';

@Injectable({
  providedIn: 'root'
})
export class ProveedorDataService {
  proveedorInfo: Proveedor;
  infoBasicaFormData: InfoBasicaDTO;
  localizacionFormData: Localizacion;
  cuentaBancariaFormData: CuentaBancaria;
  cuentaUsuarioFormData: CuentaUsuario;
  infoFinancieraFormData: InfoFinanciera;
  esConsulta: boolean;
  esEdicion: boolean;
  entidadesFinancieras: any[];
  paises: any[];
  departamentos: any[];
  ciudades: any[];
  constructor() {
    this.esConsulta = false;
    this.esEdicion = false;
  }
}
