import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListarCotizacionesComponent } from '../cotizaciones/listar-cotizaciones/listar-cotizaciones.component';
import { DetalleCotizacionComponent } from '../cotizaciones/detalle-cotizacion/detalle-cotizacion.component';

const routes: Routes = [
  {
    path: 'cotizaciones',
    children: [
      { path: 'listar-cotizaciones', component: ListarCotizacionesComponent },
      { path: 'detalle-cotizacion', component: DetalleCotizacionComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CotizacionesRoutingModule { }
