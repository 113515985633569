import { Component, OnInit,  } from '@angular/core';
import { ProveedorApiClientService } from '../proveedor-api-client.service';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { Observable,  } from 'rxjs';
import { TipoProveedor, ClaseProveedor, EstadoProveedor } from 'src/app/shared/utils/general-enums-provider.enum';
import { NotificationsService } from 'angular2-notifications';
import { Proveedor } from 'src/app/shared/models/proveedor';
import {  map,  } from 'rxjs/operators';
import {  Router } from '@angular/router';
import { TokenService } from 'src/app/authentication/token.service';
import { ConfigService } from 'src/app/app-config.service';
import { UtilityService } from '../../shared/utils/utility.service';

declare var $: any;

@Component({
  selector: 'app-cargar-documentacion',
  templateUrl: './cargar-documentacion.component.html',
  styleUrls: ['./cargar-documentacion.component.sass']
})
export class CargarDocumentacionComponent implements OnInit {

  constructor(
    private dataApiClient: DataApiClientService,
    private notificationsService: NotificationsService,
    private tokenService: TokenService,
    private router: Router,
    private utilsService: UtilityService,
    private configService: ConfigService,
    private proveedorApiClient: ProveedorApiClientService) { }

  ProveedorAuth: Proveedor;
  documentosProveedor$: Observable<any>;
  documentosProveedortr$: Observable<any>;
  documentosTiposProveedor$: Observable<any>;
  crearDocumentosProveedor$: Observable<any>;
  crearDocumentosNovProveedor$: Observable<any>;
  fileNames: Array<string> = [];
  submitted: boolean;
  isEdit: boolean;
  proveedor: Proveedor;
  pesoarchivos = 0;
  iddocumentodelete: number;
  permiteEditar: boolean;
  showspinner: boolean;
  showDescargarInfo: boolean;
  showPolitica: boolean;
  showGuardar = false;

  ngOnInit(showMessage = false) {
    this.resetForm();
    this.showPolitica = false;
    this.pesoarchivos = this.configService.get('parameters').pesoarchivos;
    this.updateInfoProveedor();
    this.proveedor = this.tokenService.getLoggedUser() as Proveedor;

    this.validarTratamientoDatos(showMessage);

    if (!showMessage) {
      this.getdocumentosProveedor();
    }
  }

  resetForm() {
    this.fileNames = [];
    this.submitted = null;
    this.isEdit = null;
    this.proveedor = null;
    this.pesoarchivos = 0;
    this.iddocumentodelete = null;
    this.permiteEditar = null;
    this.showspinner = null;
    this.showDescargarInfo = null;
    this.showPolitica = null;
    this.showGuardar = false;
  }

  downloadFile(documento) {
    const datos = {
      'documento' : documento
    };

    this.proveedorApiClient.DownloadFileProveedor(datos).subscribe(
      (res) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {

          window.navigator.msSaveOrOpenBlob(res.file, res.name);
        } else {
          const byteArrFile = this.utilsService.base64ToArrayBuffer(res.file);
          this.utilsService.saveByteArray(res.name, byteArrFile, {
            type: 'application/octet-stream'
          });
        }
      }
    );
  }

  PDFInfo() {
    this.proveedorApiClient.downloadPDFInfoProveedor(this.proveedor.IDPROVEEDOR).subscribe(
      (res) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {

          window.navigator.msSaveOrOpenBlob(res.file, 'FORMULARIO UNICO DE CONOCIMIENTO DE PROVEEDORES Y CLIENTES.pdf');
        } else {
          const byteArrFile = this.utilsService.base64ToArrayBuffer(res.file);
          this.utilsService.saveByteArray('FORMULARIO UNICO DE CONOCIMIENTO DE PROVEEDORES Y CLIENTES.pdf', byteArrFile, {
            type: 'application/pdf'
          });
        }
      }
    );
  }

  validarTratamientoDatos(showMessage: Boolean) {
    this.ProveedorAuth = this.tokenService.getLoggedUser() as Proveedor;
    const claseProveedor = this.ProveedorAuth.IDCLASEPROVEEDOR;
    const tipoProveedor = this.ProveedorAuth.IDTIPOPROVEEDOR;
    const infoCompleta = this.ProveedorAuth.IDINFOBASICA !== null && this.ProveedorAuth.IDINFOFINANCIERA !== null &&
                         this.ProveedorAuth.IDINFOJURIDICA !== null && this.ProveedorAuth.DECLARACIONCONFLICTOS === 1;
    const faltaPolitica = infoCompleta &&  this.ProveedorAuth.AUTORIZACIONDATOS === 0;
    const puedeEditar = this.ProveedorAuth.IDESTADO === EstadoProveedor.PENDIENTEPROVEEDOR ||
        this.ProveedorAuth.IDESTADO === EstadoProveedor.PREREGISTRO
        || (this.ProveedorAuth.IDESTADO === EstadoProveedor.APROBADO && this.ProveedorAuth.DEBEACTUALIZAR === 1);
    const preregistro = this.ProveedorAuth.IDESTADO === EstadoProveedor.PREREGISTRO;

    if (this.ProveedorAuth !== null) {
      this.documentosProveedortr$ = this.proveedorApiClient.getDocumentosProveedorAll(this.ProveedorAuth.IDPROVEEDOR).pipe(
        map(items => items.filter(
          d => ((d.MODULO === 'CARGA-DOCUMENTOS' && (tipoProveedor !== TipoProveedor.PROVEEDOR || (
            tipoProveedor === TipoProveedor.PROVEEDOR &&
            (claseProveedor !== null)
           ))) ||
           (d.MODULO === 'CARGA-DOCUMENTOS-CRITICO' && claseProveedor === ClaseProveedor.CRITICO))
           && d.RUTA === null
         ))
      );

      this.documentosProveedortr$.subscribe(
        (Response) => {
            let showWaitClasification = false;
            let showMsgFormUnico = false;
            const esProveedor = tipoProveedor === TipoProveedor.PROVEEDOR;
            const esClienteDeudor = tipoProveedor === TipoProveedor.CLIENTE_DEUDOR;
            const clasificado = (esProveedor && claseProveedor !== null) || esClienteDeudor;
            if ( esProveedor || esClienteDeudor) {
              if (faltaPolitica) {
                if (Response.length === 0 && clasificado) {
                    $('#PoliticaModal').modal('show');
                    this.showPolitica = true;
                } else {
                  if (!clasificado && preregistro) {
                      $('#confirmRegistroModal').modal('show');
                      showWaitClasification = true;
                  }
                }
              }
            }

            if  (!this.showPolitica && !showWaitClasification) {
              if (infoCompleta && puedeEditar) {
                let faltaFormUnico = false;
                if (Response.length === 1) {
                  if (Response[0].NOMBREDOCUMENTO.toUpperCase().includes('FORMULARIO UNICO')) {
                    faltaFormUnico = true;
                  }
                }
                this.showDescargarInfo = faltaFormUnico && ((esProveedor && claseProveedor !== null) || esClienteDeudor);
                if (showMessage) {
                  this.getdocumentosProveedor();
                  if (preregistro && this.showDescargarInfo) {
                    $('#ModalMessage').modal('show');
                    showMsgFormUnico = true;
                  }
                }
              }
            }

            if (!showMsgFormUnico && showMessage) {
              this.getdocumentosProveedor();
            }
        }
      );
    }
  }

  getdocumentosProveedor() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    if ( proveedor !== null) {
      this.documentosProveedor$ = this.proveedorApiClient.getDocumentosProveedorAll(proveedor.IDPROVEEDOR).pipe(
        map(items => items.filter(
          d => ((d.MODULO === 'CARGA-DOCUMENTOS' && (this.proveedor.IDTIPOPROVEEDOR !== TipoProveedor.PROVEEDOR || (
            this.proveedor.IDTIPOPROVEEDOR === TipoProveedor.PROVEEDOR &&
            (this.proveedor.IDCLASEPROVEEDOR !== null)
           ))) ||
           (d.MODULO === 'CARGA-DOCUMENTOS-CRITICO' && this.proveedor.IDCLASEPROVEEDOR === ClaseProveedor.CRITICO))
           && ((!d.NOMBREDOCUMENTO.toUpperCase().includes('FORMULARIO UNICO') ||
           (d.RUTA !== null ) ) || this.showDescargarInfo)
         ))
      );

      this.showspinner = true;
      this.documentosProveedor$.subscribe(
        (Response) => {
          this.showspinner = false;
          if (Response.length === 0) {
            this.isEdit = false;
            this.permiteEditar = true;
          } else {
            this.isEdit = true;
            if (proveedor.IDESTADO === EstadoProveedor.PREREGISTRO || proveedor.IDESTADO === EstadoProveedor.PENDIENTEPROVEEDOR
              || (proveedor.IDESTADO === EstadoProveedor.APROBADO && proveedor.DEBEACTUALIZAR === 1)) {
              this.permiteEditar = true;
            }
          }
        },
        (err: any) => {
          this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
          this.showspinner = false;
        }
      );
    }
  }
  updateInfoProveedor() {
    this.showspinner = true;
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.proveedorApiClient.FindProveedor(proveedor.IDPROVEEDOR + '').subscribe(
      ( Response: Proveedor) => {
        this.showspinner = false;
        this.tokenService.updateLoggedUser(Response);
        this.proveedor = Response;
      },
      (err: any) => {
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
        this.showspinner = false;
      }
    );
  }

  getDocumentosTipoProveedor(): void {
    if ( this.proveedor !== null) {
      this.documentosTiposProveedor$ = this.dataApiClient.getDocumentosTipoProveedor(this.proveedor.IDTIPOPROVEEDOR).pipe(
        map(items => items.filter(
           d => ((d.MODULO === 'CARGA-DOCUMENTOS' && (this.proveedor.IDTIPOPROVEEDOR !== TipoProveedor.PROVEEDOR || (
            this.proveedor.IDTIPOPROVEEDOR === TipoProveedor.PROVEEDOR &&
            (this.proveedor.IDCLASEPROVEEDOR !== null)
           ))) ||
           (d.MODULO === 'CARGA-DOCUMENTOS-CRITICO' && this.proveedor.IDCLASEPROVEEDOR === ClaseProveedor.CRITICO))
         ))
      );
    }
  }

  showModalDeleteFile($event) {
    this.iddocumentodelete = Number($event.target.id.substring(3));
    $('#ModalDelete').modal('show');
  }

  confirmDeleteFile() {
    this.showspinner = true;
    this.proveedorApiClient.deleteDocumentoProveedor(this.iddocumentodelete).subscribe(
      (response) => {
        this.showspinner = false;
        $('#ModalDelete').modal('hide');
        this.showGuardar = false;
        this.notificationsService.success('Exito', 'El documento se ha eliminado correctamente');
        this.ngOnInit(true);
      },
      (err: any) => {
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
        this.showspinner = false;
      }
    );
  }

  handleFileInput(target: any, novedad: boolean) {
    const iddocumentotipoproveedor = $(target).attr('data-iddocumentotipoproveedor');
    const iddocumentoidproveedor = $(target).attr('data-iddocumentoidproveedor');
    const files: FileList = $('#fu' + iddocumentotipoproveedor)[0].files;
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      if ( files[i].type === 'application/pdf' ||
      files[i].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      files[i].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      files[i].type === 'image/jpeg' ||
      files[i].type === 'application/vnd.ms-excel.sheet.macroEnabled.12' ||
      files[i].type === 'application/msword' ||
      files[i].type === 'application/vnd.ms-excel'
      ) {
        if (files[i].size <= this.pesoarchivos) {
          let new_name = (files.item(i).name).toLowerCase();
          new_name = new_name.replace(new RegExp('\\s', 'g'), '');
          new_name = new_name.replace(new RegExp('[àáâãäå]', 'g'), 'a');
          new_name = new_name.replace(new RegExp('æ', 'g'), 'ae');
          new_name = new_name.replace(new RegExp('ç', 'g'), 'c');
          new_name = new_name.replace(new RegExp('[èéêë]', 'g'), 'e');
          new_name = new_name.replace(new RegExp('[íìíîï]', 'g'), 'i');
          new_name = new_name.replace(new RegExp('ñ', 'g'), 'n');
          new_name = new_name.replace(new RegExp('[òóóôõö]', 'g'), 'o');
          new_name = new_name.replace(new RegExp('œ', 'g'), 'oe');
          new_name = new_name.replace(new RegExp('[ùúûü]', 'g'), 'u');
          new_name = new_name.replace(new RegExp('[ýÿ]', 'g'), 'y');

          formData.append('file', files.item(i), new_name);
          this.showspinner = true;
          this.dataApiClient.UploadFile(formData).subscribe(
            (response: any) => {
              this.showspinner = false;
              this.fileNames.push(response + '/' + iddocumentotipoproveedor + '/' + iddocumentoidproveedor);
              this.showGuardar = true;
              $('#dv_error' + iddocumentotipoproveedor).addClass('d-none');
              $('#dv_input' + iddocumentotipoproveedor).addClass('d-none');
              $('#dv_succes' + iddocumentotipoproveedor).removeClass('d-none');
              $('#dv_succes' + iddocumentotipoproveedor).html('Se cargó el documento ' + files.item(i).name);
            },
            (err: any) => {
              this.showspinner = false;
              $('#dv_error' + iddocumentotipoproveedor).removeClass('d-none');
              $('#dv_error' + iddocumentotipoproveedor).html('El archivo no ha sido cargado, inténtenlo de nuevo');
            }
          );
        } else {
          this.showspinner = false;
          $('#dv_error' + iddocumentotipoproveedor).removeClass('d-none');
          $('#dv_error' + iddocumentotipoproveedor).html('Tamaño no válido, el tamaño máximo permitido es ' +
          Math.round((this.pesoarchivos / 1024000) * 10) / 10 + ' MB');
        }
      } else {
        this.showspinner = false;
        $('#dv_error' + iddocumentotipoproveedor).removeClass('d-none');
        $('#dv_error' + iddocumentotipoproveedor).html('Los documentos permitidos son PDF, Word, Excel, Imágenes en JPG');
      }
    }
  }
  
  enableButton(target: any) {
    const iddocumento = $(target).attr('id').substring(2);
    $('#btn' + iddocumento).prop('disabled', false);
    $('#lbl' + iddocumento).html($('#' + $(target).attr('id'))[0].files[0].name);
  }

  showModalCancel() {
    $('#CancelModal').modal('show');
  }

  ConfirmCancel() {
    $('#CancelModal').modal('hide');
    sessionStorage.setItem('submenu', 'infoBasica');
    this.router.navigate(['/proveedor/info-basica']);
  }

  cargarDocumentos() {
    this.proveedor.FILES = this.fileNames;
    this.crearDocumentosProveedor$ = this.proveedorApiClient.CargarAdjuntosProveedor(this.proveedor);
    this.showspinner = true;
    this.crearDocumentosProveedor$.subscribe(
      (val: boolean) => {
        this.showspinner = false;
        this.notificationsService.success('Exito', 'Registro culminado con éxito');
        this.ngOnInit(true);
      },
      (err: any) => {
        this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
        this.showspinner = false;
      }
    );
  }
}
