import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective, FormArray } from '@angular/forms';
import { Observable, forkJoin,  of } from 'rxjs';
import { ProveedorApiClientService } from '../proveedor-api-client.service';
import { switchMap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { TokenService } from 'src/app/authentication/token.service';
import { PersonaJuridica } from 'src/app/shared/models/personajuridica.module';
import { Router } from '@angular/router';
import { EstadoProveedor } from 'src/app/shared/utils/general-enums-provider.enum';

declare var $: any;

@Component({
  selector: 'app-info-juridica',
  templateUrl: './info-juridica.component.html',
  styleUrls: ['./info-juridica.component.sass']
})
export class InfoJuridicaComponent implements OnInit {
  form: FormGroup;
  showForm: boolean;
  showFormNot: boolean;
  showQuestion1: boolean;
  isEdit: boolean;
  submitted: boolean;
  permiteEditar: boolean;
  infoJuridicaForm: FormGroup;
  personaJuridicaForm: FormGroup;
  PersonaJuridicaArray: FormArray;
  declaracionRequest$: Observable<any>;
  persons: Array<PersonaJuridica>;
  personsToRemove: Array<PersonaJuridica>;
  showspinner: boolean;

  constructor( private formBuilder: FormBuilder, private tokenService: TokenService, private router: Router,
    private notificationsService: NotificationsService, private proveedorApiClient: ProveedorApiClientService,
    ) { }

  ngOnInit() {
    this.updateInfoProveedor();
    this._InitInfoJuridicaForm();
  }

  updateInfoProveedor() {
    let proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.showspinner = true;
    this.proveedorApiClient.FindProveedor(proveedor.IDPROVEEDOR + '').subscribe(
      ( Response: Proveedor) => {
        this.tokenService.updateLoggedUser(Response);
        this.showspinner = false;
        this._PreloadData();
      },
      (err: any) => {
        this.notificationsService.error('Error', 'Ocurrio un error al leer la información');
        this.showspinner = false;
      }
    );
  }

  private _PreloadData() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    if (proveedor.IDINFOJURIDICA) {
      if (proveedor.IDESTADO === EstadoProveedor.PREREGISTRO || proveedor.IDESTADO === EstadoProveedor.PENDIENTEPROVEEDOR
         || (proveedor.IDESTADO === EstadoProveedor.APROBADO && proveedor.DEBEACTUALIZAR === 1)) {
        this.permiteEditar = true;
      }
      this.consultarInformacion();
    } else {
      this.permiteEditar = true;
      this.initPersons();
    }
  }

  consultarInformacion() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    const idProveedor = proveedor.IDPROVEEDOR + '';
    this.showspinner = true;

    this.proveedorApiClient.GetPeronasJuridicas(idProveedor).subscribe(
      (responsePersonasJuridicas: any) => {
        this.showspinner = false;
        this.persons = [];
        this.personsToRemove = [];
        if (responsePersonasJuridicas != null && responsePersonasJuridicas.length > 0) {
          this.showQuestion1 = true;
          this.showFormNot = false;
          responsePersonasJuridicas.forEach((key, index) => {
            const PERSON: PersonaJuridica = new PersonaJuridica(
              key.IDINFOJURIDICA, // IDINFOJURIDICA: number;
              key.IDPERSONAJURIDICA, //  IDPERSONAJURIDICA: number;
              key.IDTIPOIDENTIFICACION, //  IDTIPOIDENTIFICACION: string;
              key.NOMBRE, //  NOMBRE: number;
              key.NUMEROIDENTIFICACION, //  NUMEROIDENTIFICACION: number;
              key.PARTEINTERESADA, // PARTEINTERESADA: string;
              key.PORCENTAJEPARTICIPACION // PORCENTAJEPARTICIPACION: string;
            );
            this.persons.push(PERSON);
          });
        }
      },
      (err: any) => {
        this.notificationsService.error('Error', 'Ocurrio un error al leer la información');
        this.showspinner = false;
      }
    );

  }

  initPersons() {
    this.persons = [new PersonaJuridica];
  }

  resetPersons() {
    this.persons = [new PersonaJuridica];
  }

  addPersonaJuridica() {
    this.persons.push(new PersonaJuridica);
  }

  removePersonaJuridica() {
    if (this.personsToRemove !== undefined) {
      this.personsToRemove.push(this.persons[this.persons.length - 1]);
    }
    this.PersonaJuridicaArray.removeAt(this.PersonaJuridicaArray.length - 1);
    this.persons.pop();
  }

  private _InitInfoJuridicaForm() {
    this.infoJuridicaForm = this.formBuilder.group({
      PersonaJuridica: this.formBuilder.array([]),
      SociedadAnonima : this.formBuilder.control(null, []),
      pregunta1 : this.formBuilder.control(null, [])
    });
    this.PersonaJuridicaArray = this.infoJuridicaForm.controls.PersonaJuridica as FormArray;
  }

  submitForm() {
    this.showspinner = true;
    this.submitted = true;
    if (!this.infoJuridicaForm.valid) {
      this.submitted = false;
      this.showspinner = false;
      return false;
    }

    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    const infoJuridica = {
      'SOCIEDADANONIMA': this.infoJuridicaForm.controls['SociedadAnonima'].value,
      'USUARIO_CREACION': this.tokenService.getNomUser()
    };

    proveedor.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();

    if (proveedor != null) {
      if (proveedor.IDINFOJURIDICA == null) {
        this.proveedorApiClient.CreateInfoJuridica(infoJuridica).pipe(
          switchMap(
            (responseInfoJuridica: any) => {
              if (responseInfoJuridica != null) {
                proveedor.IDINFOJURIDICA = responseInfoJuridica;
                this.proveedorApiClient.UpdateProveedor(proveedor).subscribe();
                this.tokenService.updateLoggedUser(proveedor);
                this.PersonaJuridicaArray.controls.forEach((key, index) => {
                  const personaJuridica: FormGroup = key as FormGroup;
                    const personaJuridicaForm = {
                      'IDINFOJURIDICA' : responseInfoJuridica,
                      'PARTEINTERESADA' : personaJuridica.controls['parteInteresada' + index].value,
                      'NOMBRE' : (personaJuridica.controls['nombrePersona' + index].value + '').toUpperCase(),
                      'IDTIPOIDENTIFICACION' : personaJuridica.controls['tipoIdentificacion' + index].value,
                      'NUMEROIDENTIFICACION' : personaJuridica.controls['numeroIdentificacion' + index].value,
                      'PORCENTAJEPARTICIPACION' : personaJuridica.controls['porcentajeParticipacion' + index].value
                    };
                    this.proveedorApiClient.CreatePersonaJuridica(personaJuridicaForm).subscribe();
                 });
                  return of(true);
              }
              this.isEdit = true;
              this.resetForm();
              this._InitInfoJuridicaForm();
              this._PreloadData();
            }
          )
        ).subscribe(
          (response: any) => {
            this.isEdit = true;
            this.showspinner = false;
            this.notificationsService.success('Exito', 'Se ha guardado correctamente');
            sessionStorage.setItem('submenu', 'infoBasica');
            this.router.navigate(['/proveedor/info-basica']);
          },
          (err: any) => {
            this.notificationsService.error('Error', 'Ocurrio un error al guardar la información');
            this.showspinner = false;
          }
        );
      } else {
        let all_obs = [];
        this.PersonaJuridicaArray.controls.forEach((key, index) => {
          const personaJuridica: FormGroup = key as FormGroup;
          let idpersonajuridica = null;
          let parteinteresada = null;
          let nombre = null;
          let idtipoidentificacion = null;
          let numeroidentificacion = null;
          let porcentajeparticipacion = null;
          Object.keys(personaJuridica.controls).forEach((k, i) => {
            if (i === 0) {idpersonajuridica = personaJuridica.get(k).value; }
            if (i === 1) {parteinteresada = personaJuridica.get(k).value; }
            if (i === 2) {nombre = personaJuridica.get(k).value; }
            if (i === 3) {idtipoidentificacion = personaJuridica.get(k).value; }
            if (i === 4) {numeroidentificacion = personaJuridica.get(k).value; }
            if (i === 5) {porcentajeparticipacion = personaJuridica.get(k).value; }
          });
          if (idpersonajuridica !== undefined) {
            if (idpersonajuridica == null) {
              const personaJuridicaForm = {
                'IDINFOJURIDICA' : proveedor.IDINFOJURIDICA,
                'PARTEINTERESADA' : parteinteresada,
                'NOMBRE' : (nombre + '').toUpperCase(),
                'IDTIPOIDENTIFICACION' : idtipoidentificacion,
                'NUMEROIDENTIFICACION' : numeroidentificacion,
                'PORCENTAJEPARTICIPACION' : porcentajeparticipacion
              };
              all_obs.push(this.proveedorApiClient.CreatePersonaJuridica(personaJuridicaForm));
            } else {
              const personaJuridicaForm = {
                'IDPERSONAJURIDICA' : idpersonajuridica,
                'IDINFOJURIDICA' : proveedor.IDINFOJURIDICA,
                'PARTEINTERESADA' : parteinteresada,
                'NOMBRE' : (nombre + '').toUpperCase(),
                'IDTIPOIDENTIFICACION' : idtipoidentificacion,
                'NUMEROIDENTIFICACION' : numeroidentificacion,
                'PORCENTAJEPARTICIPACION' : porcentajeparticipacion
              };
              all_obs.push(this.proveedorApiClient.UpdatePersonaJuridica(personaJuridicaForm));
            }
          }
         });
         if (this.personsToRemove.length > 0) {
            all_obs.push(this.proveedorApiClient.RemovePersonasJuridicas(this.personsToRemove));
         }
        forkJoin(all_obs).subscribe(
          (response: any) => {
            this.isEdit = true;
            this.notificationsService.success('Exito', 'Se ha guardado correctamente');
            sessionStorage.setItem('submenu', 'infoBasica');
            this.router.navigate(['/proveedor/info-basica']);
            this.showspinner = false;
            this.resetForm();
          },
          (err: any) => {
            this.notificationsService.error('Error', 'Ocurrio un error al guardar la información');
            this.showspinner = false;
          }
        );
      }
    }
  }

  get f() {
    return this.infoJuridicaForm.controls;
  }

  IsValid(control: string): boolean {
    return this.infoJuridicaForm.controls[control].valid;
  }

  showAlertCancel() {
    $('#CancelModal').modal('show');
  }

  ConfirmCancel() {
    $('#CancelModal').modal('hide');
    this.ngOnInit();
  }

  resetForm() {
    $('input').each(function () {
      $($(this)[0]).val('');
    });
    $('select').each(function () {
      $($(this)[0]).prop('selectedIndex', 0);
    });
    this.infoJuridicaForm.reset();

    this.showForm = false;
    this.submitted = false;
  }
}
