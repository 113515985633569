import { Component } from '@angular/core';
import { Location, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ConfigService } from './app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
  providers: [
    Location, { provide: LocationStrategy, useClass: HashLocationStrategy }
  ]
})
export class AppComponent {

  constructor(
    private configService: ConfigService
  ) { }

  /**
   * Returns global notification options
   */
  public getNotificationOptions(): any {
    return this.configService.get('notifications').options;
  }}
