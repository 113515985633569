import { Component, OnInit } from '@angular/core';
import { ProveedorDataService } from '../proveedor-data.service';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { EMPTY } from 'rxjs';
import { FormArray } from '@angular/forms';
import { ProveedorApiClientService } from '../proveedor-api-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { retry, switchMap, map } from 'rxjs/operators';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { TokenService } from 'src/app/authentication/token.service';
import { InfoBasica } from 'src/app/shared/models/info-basica';
import { Ubicacion } from 'src/app/shared/models/ubicacion';
import { NotificationsService } from 'angular2-notifications';
import { EstadoProveedor } from '../../shared/utils/general-enums-provider.enum';

declare var $: any;

@Component({
  selector: 'app-informacion-financiera',
  templateUrl: './informacion-financiera.component.html',
  styleUrls: ['./informacion-financiera.component.css']
})

export class InformacionFinancieraComponent implements OnInit {
  entidadesFinancieras: any[];
  registroRequest$: Observable<any>;
  pais: string;
  isEdit: boolean;
  cuentaBancaria$: Observable<any>;
  submitted: boolean;
  showspinner: boolean;
  permiteEditar: boolean;
  CuentaBancariaArray: FormArray;
  ProveedorAuth: Proveedor;
  infoFinancieraForm: FormGroup;

  constructor(private service: ProveedorDataService, private dataApiClient: DataApiClientService,
    private proveedorApiClient: ProveedorApiClientService, private route: ActivatedRoute,
    private router: Router,
    private notificationsService: NotificationsService,
    private formBuilder: FormBuilder,
    private tokenService: TokenService
  ) { }

  ngOnInit() {
    this.updateInfoProveedor();
    this._InitInfoFinancieraForm();
    this._PreloadData();
    this._ShowPais();
  }

  updateInfoProveedor() {
    let proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.showspinner = true;
    this.proveedorApiClient.FindProveedor(proveedor.IDPROVEEDOR + '').subscribe(
      ( Response: Proveedor) => {
        this.showspinner = false;
        this.tokenService.updateLoggedUser(Response);
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error al leer la información');
      }
    );
  }

  get f() { return this.infoFinancieraForm.controls; }

  IsValid(control: string): boolean {
    return this.infoFinancieraForm.controls[control].valid;
  }

  consultarInformacion(): void {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    const idProveedor = proveedor.IDPROVEEDOR + '';
    this.showspinner = true;
    this.proveedorApiClient.FindInfoFinanciera(idProveedor).subscribe(
      (responseInfoFinanciera: any) => {
        this.showspinner = false;
        if (responseInfoFinanciera != null && responseInfoFinanciera.length > 0) {
          const infoFinanciera = responseInfoFinanciera[0];
          this.infoFinancieraForm.controls.IdInfoFinanciera.setValue(this.convertCurrencyFormat(infoFinanciera.IDINFOFINANCIERA));
          this.infoFinancieraForm.controls.TotalActivos.setValue(this.convertCurrencyFormat(infoFinanciera.TOTALACTIVOS));
          this.infoFinancieraForm.controls.TotalActivoCorriente.setValue(this.convertCurrencyFormat(infoFinanciera.TOTALACTIVOCORRIENTE));
          this.infoFinancieraForm.controls.UtilidadAntesImp.setValue(this.convertCurrencyFormat(infoFinanciera.UTILIDADANTESIMP));
          this.infoFinancieraForm.controls.TotalPasivos.setValue(this.convertCurrencyFormat(infoFinanciera.TOTALPASIVOS));
          this.infoFinancieraForm.controls.TotalPasivoCorriente.setValue(this.convertCurrencyFormat(infoFinanciera.TOTALPASIVOCORRIENTE));
          this.infoFinancieraForm.controls.TotalPatrimonio.setValue(this.convertCurrencyFormat(infoFinanciera.TOTALPATRIMONIO));
          this.infoFinancieraForm.controls.UnidadNeta.setValue(this.convertCurrencyFormat(infoFinanciera.UNIDADNETA));
          this.infoFinancieraForm.controls.Usuario_Creacion.setValue(this.convertCurrencyFormat(infoFinanciera.USUARIO_CREACION));
          this.infoFinancieraForm.controls.Usuario_Actualizacion.setValue(this.convertCurrencyFormat(infoFinanciera.USUARIO_ACTUALIZACION));
        }
      },
      (err: any) => {
        this.showspinner = false;
        this.notificationsService.error('Error', 'Ocurrio un error al leer la información');
      }
    );
    if (proveedor.IDCUENTABANCARIA != null) {
      if (proveedor.IDCUENTABANCARIA > 0) {
        const idCuentaBancaria = proveedor.IDCUENTABANCARIA + '';
        this.showspinner = true;
        this.dataApiClient.getCuentaBancaria(idCuentaBancaria).subscribe(
          (responseCuentaBancaria: any) => {
            this.showspinner = false;
            if (responseCuentaBancaria != null && responseCuentaBancaria.length > 0) {
              const cuentaBancaria = responseCuentaBancaria[0];
              const formCuenta = this.CuentaBancariaArray.controls[0] as FormGroup;

              formCuenta.setValue({
                EntidadFinanciera: cuentaBancaria.ID_ENTIDAD_FINANCIERA,
                IdCuentaBancaria: cuentaBancaria.IDCUENTABANCARIA,
                NumeroCuenta: cuentaBancaria.NUMERO,
                TipoCuenta: cuentaBancaria.IDTIPOCUENTA
              });
              this.service.cuentaBancariaFormData = Object.assign({}, cuentaBancaria);
            }
          },
          (err: any) => {
            this.showspinner = false;
            this.notificationsService.error('Error', 'Ocurrio un error al leer la información');
          }
        );
      }
    }
  }

  onKeyPressNumber(event) {
    if ( this.convertNumberFormat($(event.target).val()).length >= 15 ) {
      return false;
    }
    return (/[0-9]/.test(String.fromCharCode(event.which)));
  }

  currencyFormat(num) {
    $(num.target).val($(num.target).val().toString().replace(/\./g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'));
  }

  convertCurrencyFormat(num) {
    return num.toString().replace(/\./g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  convertNumberFormat(num) {
    return num.toString().replace(/\./g, '');
  }

  registrar() {
    this.submitted = true;
    this.showspinner = true;
    this.CuentaBancariaArray.controls.forEach((key, index) => {
      const CuentaBancaria: FormGroup = key as FormGroup;

      if ((CuentaBancaria.controls.NumeroCuenta.value + '') === '' &&
      CuentaBancaria.controls.TipoCuenta.value === null &&
      CuentaBancaria.controls.EntidadFinanciera.value  === null && this.ProveedorAuth.IDTIPOPROVEEDOR !== 12) {
        this.notificationsService.error('Error', 'La información de la cuenta bancaria es obligatoria');
      }
    });
    if (!this.infoFinancieraForm.valid) {
      this.submitted = false;
      this.showspinner = false;
      return false;
    }

    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    const infoFinanciera = {
      'IDINFOFINANCIERA': this.convertNumberFormat(this.infoFinancieraForm.controls.IdInfoFinanciera.value),
      'TOTALACTIVOS': this.convertNumberFormat(this.infoFinancieraForm.controls.TotalActivos.value),
      'TOTALACTIVOCORRIENTE': this.convertNumberFormat(this.infoFinancieraForm.controls.TotalActivoCorriente.value),
      'UTILIDADANTESIMP': this.convertNumberFormat(this.infoFinancieraForm.controls.UtilidadAntesImp.value),
      'TOTALPASIVOS': this.convertNumberFormat(this.infoFinancieraForm.controls.TotalPasivos.value),
      'TOTALPASIVOCORRIENTE': this.convertNumberFormat(this.infoFinancieraForm.controls.TotalPasivoCorriente.value),
      'TOTALPATRIMONIO': this.convertNumberFormat(this.infoFinancieraForm.controls.TotalPatrimonio.value),
      'UNIDADNETA': this.convertNumberFormat(this.infoFinancieraForm.controls.UnidadNeta.value),
      'DECLARACIONFONDOS': 0,
      'USUARIO_CREACION': this.tokenService.getNomUser(),
      'FECHA_CREACION': Date.now(),
      'USUARIO_ACTUALIZACION': this.tokenService.getNomUser() ,
      'FECHA_ACTUALIZACION': this.service.esEdicion ? this.tokenService.getNomUser() : null
    };
    proveedor.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();
    if (this.infoFinancieraForm.controls.IdInfoFinanciera.value > 0) {
      this.proveedorApiClient.UpdateInfoFinanciera(infoFinanciera).subscribe(
        (responseInfoFinanciera: any) => {
          let cuentaBancariaForm: any;
          this.CuentaBancariaArray.controls.forEach((key, index) => {
            const CuentaBancaria: FormGroup = key as FormGroup;
            cuentaBancariaForm = {
            IDCUENTABANCARIA: proveedor.IDCUENTABANCARIA,
            NUMERO: CuentaBancaria.controls.NumeroCuenta.value,
            IDTIPOCUENTA: CuentaBancaria.controls.TipoCuenta.value,
            ID_ENTIDAD_FINANCIERA: CuentaBancaria.controls.EntidadFinanciera.value,
            USUARIO_ACTUALIZACION: this.tokenService.getNomUser()
            };
          });
          if (cuentaBancariaForm.NUMERO !== '' && cuentaBancariaForm.IDTIPOCUENTA !== null
          && cuentaBancariaForm.ID_ENTIDAD_FINANCIERA !== null) {
            if (proveedor.IDCUENTABANCARIA != null) {
              this.registroRequest$ = this.proveedorApiClient.UpdateCuentaBancaria(cuentaBancariaForm);
              this.registroRequest$.subscribe(
              (responseCuenta: any) => {
                this.notificationsService.success('Exito', 'Se ha guardado correctamente');
                this.showspinner = false;
              },
              (err: any) => {
                this.notificationsService.error('Error', 'Ocurrio un error en el sistema');
                this.showspinner = false;
              }
              );
            } else {
              cuentaBancariaForm.USUARIO_CREACION = cuentaBancariaForm.USUARIO_ACTUALIZACION;
              cuentaBancariaForm.USUARIO_ACTUALIZACION = null;
              this.registroRequest$ = this.proveedorApiClient.CreateCuentaBancaria(cuentaBancariaForm);
            this.registroRequest$.subscribe(
              (responseCuenta: any) => {
                proveedor.IDCUENTABANCARIA = responseCuenta;
                this.proveedorApiClient.UpdateProveedor(proveedor).subscribe(
                  (res) => {
                    this.notificationsService.success('Exito', 'Se ha guardado correctamente');
                    this.showspinner = false;
                  },
                  (err: any) => {
                    this.notificationsService.error('Error', 'Ocurrio un error guardando la información');
                    this.showspinner = false;
                  }
                );
                this.tokenService.updateLoggedUser(proveedor);
              },
              (err: any) => {
                this.notificationsService.error('Error', 'Ocurrio un error guardando la información');
                this.showspinner = false;
              }
            );
            }
          } else {
            this.notificationsService.success('Exito', 'Se ha guardado correctamente');
              this.showspinner = false;
          }
        },
        (err: any) => {
          this.notificationsService.error('Error', 'Ocurrio un error guardando la información');
          this.showspinner = false;
        }
      );
    } else {
      this.proveedorApiClient.CreateInfoFinanciera(infoFinanciera).subscribe(
        (responseInfoFinanciera: any) => {
          proveedor.IDINFOFINANCIERA = responseInfoFinanciera;
          let cuentaBancariaForm: any;
          this.CuentaBancariaArray.controls.forEach((key, index) => {
            const CuentaBancaria: FormGroup = key as FormGroup;
            cuentaBancariaForm = {
              NUMERO: CuentaBancaria.controls.NumeroCuenta.value,
              IDTIPOCUENTA: CuentaBancaria.controls.TipoCuenta.value,
              ID_ENTIDAD_FINANCIERA: CuentaBancaria.controls.EntidadFinanciera.value,
              USUARIO_CREACION: this.tokenService.getNomUser()
            };
          });
          if (cuentaBancariaForm.NUMERO !== '' && cuentaBancariaForm.IDTIPOCUENTA !== null 
          && cuentaBancariaForm.ID_ENTIDAD_FINANCIERA !== null) {
            this.registroRequest$ = this.proveedorApiClient.CreateCuentaBancaria(cuentaBancariaForm);
            this.registroRequest$.subscribe(
              (responseCuenta: any) => {
                proveedor.IDCUENTABANCARIA = responseCuenta;
                this.proveedorApiClient.UpdateProveedor(proveedor).subscribe(
                  (res) => {
                    this.notificationsService.success('Exito', 'Se ha guardado correctamente');
                    this.showspinner = false;
                  },
                  (err: any) => {
                    this.notificationsService.error('Error', 'Ocurrio un error guardando la información');
                    this.showspinner = false;
                  }
                );
                this.tokenService.updateLoggedUser(proveedor);
              },
              (err: any) => {
                this.notificationsService.error('Error', 'Ocurrio un error guardando la información');
                this.showspinner = false;
              }
            );
          } else {
            this.proveedorApiClient.UpdateProveedor(proveedor).subscribe(
              (res) => {
                this.notificationsService.success('Exito', 'Se ha guardado correctamente');
                this.showspinner = false;
              },
              (err: any) => {
                this.notificationsService.error('Error', 'Ocurrio un error guardando la información');
                this.showspinner = false;
              }
            );
            this.tokenService.updateLoggedUser(proveedor);
            this.notificationsService.success('Exito', 'Se ha guardado correctamente');
            this.showspinner = false;
          }
          this.isEdit = true;
        },
        (err: any) => {
          this.notificationsService.error('Error', 'Ocurrio un error guardando la información');
          this.showspinner = false;
        }
      );

    }
  }

  private _InitInfoFinancieraForm() {
    this.ProveedorAuth = this.tokenService.getLoggedUser() as Proveedor;
    this.infoFinancieraForm = this.formBuilder.group({
      IdInfoFinanciera: this.formBuilder.control('', []),
      TotalActivos: this.formBuilder.control('', [Validators.required]),
      TotalActivoCorriente: this.formBuilder.control('', [Validators.required]),
      UtilidadAntesImp: this.formBuilder.control('', [Validators.required]),
      TotalPasivos: this.formBuilder.control('', [Validators.required]),
      TotalPasivoCorriente: this.formBuilder.control('', [Validators.required]),
      TotalPatrimonio: this.formBuilder.control('', [Validators.required]),
      UnidadNeta: this.formBuilder.control('', [Validators.required]),
      Usuario_Creacion: this.formBuilder.control('', []),
      Usuario_Actualizacion: this.formBuilder.control('', []),
      CuentaBancaria: this.formBuilder.array([])
    });
    this.CuentaBancariaArray = this.infoFinancieraForm.controls.CuentaBancaria as FormArray;
  }

  showModalCancel() {
    $('#CancelModal').modal('show');
  }

  ConfirmCancel() {
    $('#CancelModal').modal('hide');
    this.ngOnInit();
  }

  private _PreloadData() {
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    if (proveedor.IDINFOFINANCIERA) {
      this.isEdit = true;
      if (proveedor.IDESTADO === EstadoProveedor.PREREGISTRO || proveedor.IDESTADO === EstadoProveedor.PENDIENTEPROVEEDOR
        || (proveedor.IDESTADO === EstadoProveedor.APROBADO && proveedor.DEBEACTUALIZAR === 1)) {
        this.permiteEditar = true;
      }
      this.consultarInformacion();
    } else {
      this.permiteEditar = true;
      this.isEdit = false;
    }
  }

  private _ShowPais() {
    this.showspinner = true;
    const proveedor: Proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.dataApiClient.getInfoBasica(`${proveedor.IDINFOBASICA}`).pipe(
      switchMap(
        (resInfoBasica: Array<InfoBasica>) => {
          if (resInfoBasica[0].IDUBICACION !== 0) {
            return this.dataApiClient.getUbicacion(`${resInfoBasica[0].IDUBICACION}`);
          } else {
            return EMPTY;
          }
        }
      )
    )
      .subscribe(
        (resUbicacion: Array<Ubicacion>) => {
          this.showspinner = false;
          this.dataApiClient.getPaises().pipe(
            map(
              (paises: Array<any>) => {
                const pais = paises.filter(p => p.IDPAIS === `${resUbicacion[0].IDPAIS}`);
                this.pais = pais[0].NOMBRE;
                return paises;
              }
            )
          ).subscribe();
        },
        (err: any) => {
          this.showspinner = false;
          this.notificationsService.error('Error', 'Ocurrio un error al leer la información');
        }
      );
  }
}
