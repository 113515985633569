import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { AuthenticationApiClientService } from './authentication-api-client.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { ComponentsModule } from '../shared/components';
import { InfoBasicaRegisterComponent } from './info-basica-register/info-basica-register.component';
import { DatosUsuarioRegisterComponent } from './datos-usuario-register/datos-usuario-register.component';
import { UbicacionRegisterComponent } from './ubicacion-register/ubicacion-register.component';
import { OlvidoDatosComponent } from './olvido-datos/olvido-datos.component';


@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    InfoBasicaRegisterComponent,
    DatosUsuarioRegisterComponent,
    UbicacionRegisterComponent,
    OlvidoDatosComponent
  ],
  imports: [
    CommonModule,
    FormsModule,

    ComponentsModule,

    ReactiveFormsModule,
    AuthenticationRoutingModule
  ],
  providers: [
    AuthenticationApiClientService
  ]
})
export class AuthenticationModule { }
