import { InfoBasica } from "./info-basica";
import { Ubicacion } from "./ubicacion";
import { Usuario } from "./usuario";

export class Proveedor {
    IDPROVEEDOR: number;
    IDTIPOPROVEEDOR: number;
    NOMBRECOMERCIAL: string;
    CORREO: string;
    TELEFONOCELULAR: string;
    IDINFOBASICA: number;
    IDUSUARIO: number;
    IDESTADO: number;
    IDCLASEPROVEEDOR: number;
    IDINFOJURIDICA: number;
    IDINFOFINANCIERA: number;
    CODIGO_CIIU: number;
    IDCUENTABANCARIA: number;
    DECLARACIONCONFLICTOS: number;
    IDDDECLARACIONCONFLICTOS: number;
    AUTORIZACIONDATOS: number;
    IDUSUARIORESPONSABLE: number;
    HABILITADOREGISTRO: number;
    USUARIO_CREACION: string;
    USUARIO_ACTUALIZACION: string;
    IDTIPOEMPRESA: number;
    FILES: Array<string>;
    DEBEACTUALIZAR: number;
    INFOBASICA: InfoBasica;
    USUARIO: Usuario;
    UBICACION: Ubicacion;

    constructor(
        IDPROVEEDOR?: number,
        IDTIPOPROVEEDOR?: number,
        NOMBRECOMERCIAL?: string,
        CORREO?: string,
        TELEFONOCELULAR?: string,
        IDINFOBASICA?: number,
        IDUSUARIO?: number,
        IDESTADO?: number,
        IDCLASEPROVEEDOR?: number,
        IDINFOJURIDICA?: number,
        IDINFOFINANCIERA?: number,
        CODIGO_CIIU?: number,
        IDCUENTABANCARIA?: number,
        DECLARACIONCONFLICTOS?: number,
        IDDECLARACIONCONFLICTOS?: number,
        AUTORIZACIONDATOS?: number,
        IDUSUARIORESPONSABLE?: number,
        HABILITADOREGISTRO?: number,
        USUARIO_CREACION?: string,
        USUARIO_ACTUALIZACION?: string,
        IDTIPOEMPRESA?: number,
        FILES?: Array<string>,
        DEBEACTUALIZAR?: number,
        INFOBASICA?: InfoBasica,
        USUARIO?: Usuario,
        UBICACION?: Ubicacion
    ) {
        this.IDPROVEEDOR = (IDPROVEEDOR) ? IDPROVEEDOR : null;
        this.IDTIPOPROVEEDOR = (IDTIPOPROVEEDOR) ? IDTIPOPROVEEDOR : null;
        this.NOMBRECOMERCIAL = (NOMBRECOMERCIAL) ? NOMBRECOMERCIAL : null;
        this.CORREO = (CORREO) ? CORREO : null;
        this.TELEFONOCELULAR = (TELEFONOCELULAR) ? TELEFONOCELULAR : null;
        this.IDINFOBASICA = (IDINFOBASICA) ? IDINFOBASICA : null;
        this.IDUSUARIO = (IDUSUARIO) ? IDUSUARIO : null;
        this.IDESTADO = (IDESTADO) ? IDESTADO : null;
        this.IDCLASEPROVEEDOR = (IDCLASEPROVEEDOR) ? IDCLASEPROVEEDOR : null;
        this.IDINFOJURIDICA = (IDINFOJURIDICA) ? IDINFOJURIDICA : null;
        this.IDINFOFINANCIERA = (IDINFOFINANCIERA) ? IDINFOFINANCIERA : null;
        this.CODIGO_CIIU = (CODIGO_CIIU) ? CODIGO_CIIU : null;
        this.IDCUENTABANCARIA = (IDCUENTABANCARIA) ? IDCUENTABANCARIA : null;
        this.DECLARACIONCONFLICTOS = (DECLARACIONCONFLICTOS) ? DECLARACIONCONFLICTOS : null;
        this.IDDDECLARACIONCONFLICTOS = (IDDECLARACIONCONFLICTOS) ? IDDECLARACIONCONFLICTOS : null;
        this.AUTORIZACIONDATOS = (AUTORIZACIONDATOS) ? AUTORIZACIONDATOS : 0;
        this.IDUSUARIORESPONSABLE = (IDUSUARIORESPONSABLE) ? IDUSUARIORESPONSABLE : null;
        this.HABILITADOREGISTRO = (HABILITADOREGISTRO) ? HABILITADOREGISTRO : null;
        this.USUARIO_CREACION = (USUARIO_CREACION) ? USUARIO_CREACION : null;
        this.USUARIO_ACTUALIZACION = (USUARIO_ACTUALIZACION) ? USUARIO_ACTUALIZACION : null;
        this.IDTIPOEMPRESA = (IDTIPOEMPRESA) ? IDTIPOEMPRESA : null;
        this.FILES = (FILES) ? FILES : null;
        this.DEBEACTUALIZAR = (DEBEACTUALIZAR) ? DEBEACTUALIZAR : null;
        this.INFOBASICA = (INFOBASICA) ? INFOBASICA : null;
        this.USUARIO = (USUARIO) ? USUARIO : null;
        this.UBICACION = (UBICACION) ? UBICACION : null;
    }
}
