import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreRegistroComponent } from './pre-registro/pre-registro.component';
import { InformacionFinancieraComponent } from './informacion-financiera/informacion-financiera.component';
import { DeclaracionConflictosComponent } from './declaracion-conflictos/declaracion-conflictos.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { CargarDocumentacionComponent } from './cargar-documentacion/cargar-documentacion.component';
import { InfoJuridicaComponent } from './info-juridica/info-juridica.component';

const routes: Routes = [
  {
    path: 'proveedor',
    children: [
      { path: 'info-basica', component: PreRegistroComponent, canActivate: [AuthGuard] },
      { path: 'info-financiera', component: InformacionFinancieraComponent, canActivate: [AuthGuard] },
      { path: 'declaracion-conflictos', component: DeclaracionConflictosComponent, canActivate: [AuthGuard] },
      { path: 'cargar-documentacion', component: CargarDocumentacionComponent },
      { path: 'informacion-juridica', component: InfoJuridicaComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionProveedorRoutingModule { }
