import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components';
import { LayoutComponent } from './layout/layout.component';

export const CONTAINERS = [
    LayoutComponent
];

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule
    ],
    declarations: CONTAINERS,
    exports: CONTAINERS
})
export class ContainersModule { }
