import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent implements OnInit {

  @Input() route: string;
  @Input() tituloModulo: string;
  breadCrumbArr: Array<string>;

  constructor() { }

  ngOnInit() {
    this.breadCrumbArr = (this.route !== null && this.route !== undefined) ? this.route.split('/') : [];
  }

}
