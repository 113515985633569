export class InfoBasica {
  IDINFOBASICA: number;
  IDTIPOPERSONA: number;
  IDTIPOIDENTIFICACION: number;
  NUMEROIDENTIFICACION: string;
  DIGITOVERIFICACION: string;
  RAZONSOCIAL: string;
  SIGLA: string;
  PRIMERNOMBRE: string;
  SEGUNDONOMBRE: string;
  PRIMERAPELLIDO: string;
  SEGUNDOAPELLIDO: string;
  IDUBICACION: number;
  CONTACTO: string;
  USUARIO_CREACION: string;
  FECHA_CREACION: string;
  USUARIO_ACTUALIZACION: string;
  FECHA_ACTUALIZACION: string;

  constructor(
    IDINFOBASICA?: number,
    IDTIPOPERSONA?: number,
    IDTIPOIDENTIFICACION?: number,
    NUMEROIDENTIFICACION?: string,
    DIGITOVERIFICACION?: string,
    RAZONSOCIAL?: string,
    SIGLA?: string,
    PRIMERNOMBRE?: string,
    SEGUNDONOMBRE?: string,
    PRIMERAPELLIDO?: string,
    SEGUNDOAPELLIDO?: string,
    IDUBICACION?: number,
    CONTACTO?: string,
    USUARIO_CREACION?: string,
    FECHA_CREACION?: string,
    USUARIO_ACTUALIZACION?: string,
    FECHA_ACTUALIZACION?: string
  ) {
    this.IDINFOBASICA = (IDINFOBASICA) ? IDINFOBASICA : null;
    this.IDTIPOPERSONA = (IDTIPOPERSONA) ? IDTIPOPERSONA : null;
    this.IDTIPOIDENTIFICACION = (IDTIPOIDENTIFICACION) ? IDTIPOIDENTIFICACION : null;
    this.NUMEROIDENTIFICACION = (NUMEROIDENTIFICACION) ? NUMEROIDENTIFICACION : null;
    this.DIGITOVERIFICACION = (DIGITOVERIFICACION != null) ? DIGITOVERIFICACION : null;
    this.RAZONSOCIAL = (RAZONSOCIAL) ? RAZONSOCIAL : null;
    this.SIGLA = (SIGLA) ? SIGLA : null;
    this.PRIMERNOMBRE = (PRIMERNOMBRE) ? PRIMERNOMBRE : null;
    this.SEGUNDONOMBRE = (SEGUNDONOMBRE) ? SEGUNDONOMBRE : null;
    this.PRIMERAPELLIDO = (PRIMERAPELLIDO) ? PRIMERAPELLIDO : null;
    this.SEGUNDOAPELLIDO = (SEGUNDOAPELLIDO) ? SEGUNDOAPELLIDO : null;
    this.IDUBICACION = (IDUBICACION) ? IDUBICACION : null;
    this.CONTACTO = (CONTACTO) ? CONTACTO : null;
    this.USUARIO_CREACION = (USUARIO_CREACION) ? USUARIO_CREACION : null;
    this.FECHA_CREACION = (FECHA_CREACION) ? FECHA_CREACION : null;
    this.USUARIO_ACTUALIZACION = (USUARIO_ACTUALIZACION) ? USUARIO_ACTUALIZACION : null;
    this.FECHA_ACTUALIZACION = (FECHA_ACTUALIZACION) ? FECHA_ACTUALIZACION : null;
  }
}
