import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormGroupDirective, FormBuilder, FormArray, Validators } from '@angular/forms';
import { DataApiClientService } from 'src/app/shared/utils/data-api-client.service';
import { Observable } from 'rxjs';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { TokenService } from 'src/app/authentication/token.service';

@Component({
  selector: 'app-cuenta-bancaria',
  templateUrl: './cuenta-bancaria.component.html',
  styleUrls: ['./cuenta-bancaria.component.css']
})
export class CuentaBancariaComponent implements OnInit, OnChanges, OnDestroy {

  @Input() form: FormGroup;
  @Input() isEdit: boolean;
  @Input() cuentaBancaria: Observable<any>;
  @Input() formDirective: FormGroupDirective;
  ProveedorAuth: Proveedor;
  BankAccountForm: FormGroup;
  BankArray: FormArray;

  entidadesFinancieras$: Observable<any>;

  constructor(
    private dataApiClient: DataApiClientService,
    private tokenService: TokenService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this._InitLists();
    this._InitBankAccountForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const cuentaBancaria: SimpleChange = changes.cuentaBancaria;

    if (cuentaBancaria !== undefined && cuentaBancaria.currentValue !== undefined) {
      this._PreloadData();
    }
  }

  ngOnDestroy() {
    this.BankArray.removeAt(this.BankArray.length - 1);
  }

  get f() { return this.BankAccountForm.controls; }

  IsValid(control: string): boolean {
    return this.BankAccountForm.controls[control].valid;
  }

  onKeyPressNumber(event) {
    return (/[0-9]/.test(String.fromCharCode(event.which)));
  }

  private _InitLists() {
    this.entidadesFinancieras$ = this.dataApiClient.getEntidadesFinancieras();
  }

  private _InitBankAccountForm() {
    this.ProveedorAuth = this.tokenService.getLoggedUser() as Proveedor;
    this.BankArray = this.form.controls.CuentaBancaria as FormArray;
    const arrayLength = this.BankArray.length;

    if (this.ProveedorAuth.IDTIPOPROVEEDOR === 12) {
      this.BankAccountForm = this.formBuilder.group({
        IdCuentaBancaria: ['', []],
        NumeroCuenta: ['', []],
        TipoCuenta:  [null, []],
        EntidadFinanciera:  [null, []]
      });
    } else {
      this.BankAccountForm = this.formBuilder.group({
        IdCuentaBancaria: ['', []],
        NumeroCuenta: ['', [
          Validators.required,
          Validators.maxLength(21)
        ]],
        TipoCuenta: [null, Validators.required],
        EntidadFinanciera: [null, Validators.required]
      });
    }

    this.BankArray.insert(arrayLength, this.BankAccountForm);
  }

  private _PreloadData() {
    if (this.isEdit) {
      this.cuentaBancaria.subscribe(
        (resCuenta: Array<any>) => {

          this.BankAccountForm.setValue({
            IdCuentaBancaria: resCuenta[0].IDCUENTABANCARIA,
            NumeroCuenta: resCuenta[0].NUMERO,
            TipoCuenta: (resCuenta[0].IDTIPOCUENTA === 0) ? null : resCuenta[0].IDTIPOCUENTA,
            EntidadFinanciera: (resCuenta[0].ID_ENTIDAD_FINANCIERA === 0) ? null : resCuenta[0].ID_ENTIDAD_FINANCIERA
          });
        }
      );
    }
  }
}
