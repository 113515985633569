import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Observable, Subscription, EMPTY, of } from 'rxjs';
import { DataApiClientService } from '../../utils/data-api-client.service';
import { Proveedor } from 'src/app/shared/models/proveedor';
import { TokenService } from 'src/app/authentication/token.service';
import { ProveedorApiClientService } from '../../../gestion-proveedor/proveedor-api-client.service';
import { NotificationsService } from 'angular2-notifications';
import { FormGroup, FormGroupDirective, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ValidationService, MustMatch } from 'src/app/shared/utils/validation.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() formDirective: FormGroupDirective;
  @Input() imgRoot: string;
  @Output() logout: EventEmitter<any> = new EventEmitter();

  updateProveedorConUsuarioRequest$: Observable<any>;
  cuentaUsuario$: Observable<any>;
  proveedor: Proveedor;
  password: string;
  repeatPassword: string;
  user: string;
  CambioUsuarioForm: FormGroup;
  submitted: Boolean;

  constructor(
    private notificationsService: NotificationsService,
    private proveedorApiClient: ProveedorApiClientService,
    private dataApiClient: DataApiClientService,
    private tokenService: TokenService,
    private formBuilder: FormBuilder,

  ) { }

  ngOnInit() {
    this.submitted = false;
    this._PreloadData();
    this._InitUserAccountForm();
  }

  get f() { return this.CambioUsuarioForm.controls; }

  IsValid(control: string): boolean {
    return this.CambioUsuarioForm.controls[control].valid;
  }
  showHidePassword(event, source: string) {
    const inputPassword = $('[name=\'' + source + '\']');
    if (inputPassword.attr('type') === 'password') {
      inputPassword.attr('type', 'text');
      inputPassword.next().find('i').removeClass('fa-eye-slash').addClass('fa-eye');
    } else {
      inputPassword.attr('type', 'password');
      inputPassword.next().find('i').removeClass('fa-eye').addClass('fa-eye-slash');
    }
  }

  private _InitUserAccountForm() {
    this.CambioUsuarioForm = this.formBuilder.group({
      Password: ['', [Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,12}$/)]],
        RepeatPassword: ['', [Validators.required]]
    },
      {
        validators: [
          MustMatch('Password', 'RepeatPassword')
        ]
      }
    );
  }

  CambiarContrasenia() {
    this.submitted = true;

    if (this.CambioUsuarioForm.invalid) {
      return;
    }

    const cambiarContrasena = {
      'IdUsuario' : this.proveedor.IDUSUARIO,
      'NuevaContrasena' : this.CambioUsuarioForm.controls.Password.value
    };
     // Valida el usuario y la contrasenia
     this.dataApiClient.CambiarContrasenia(cambiarContrasena).subscribe(
      (result: any) => {
        if (result) {
          this.notificationsService.success('Exito', 'Se actualizó la contraseña con éxito');
          this.resetForm();
          $('#ContraseniaModal').modal('hide');
        } else {
          this.notificationsService.success('Error', 'Se encontró un problema al actualizar la contraseña');
        }
      }
     );

  }
  private _PreloadData() {
    this.proveedor = this.tokenService.getLoggedUser() as Proveedor;
    this.user = this.tokenService.getNombre();
    if (this.proveedor !== null && this.user == null) {
      this.proveedorApiClient.FindInfoBasica(this.proveedor.IDPROVEEDOR + '').subscribe(
          ((response: any) => {
            if (response.length > 0) {
              this.user = (response[0].PRIMERNOMBRE !== '' && response[0].PRIMERNOMBRE !== undefined &&
              response[0].PRIMERNOMBRE !== null) ? (response[0].PRIMERNOMBRE + ' ' + response[0].PRIMERAPELLIDO) :
              response[0].RAZONSOCIAL;
              this.tokenService.setNombre(this.user);
            }
        })
      );
    }
  }
  resetForm() {
    $('input').each(function () {
      $($(this)[0]).val('');
    });
    this.CambioUsuarioForm.reset();
    this.submitted = false;
  }
  DoLogout(event: Event) {
    this.logout.emit(event);
  }

  showModal(e) {
    e.preventDefault();
    $('#ContraseniaModal').modal('show');
  }
}
