import { Injectable } from '@angular/core';
import { ConfigService } from '../app-config.service';
import * as jwt_decode from 'jwt-decode';
import { Proveedor } from 'src/app/shared/models/proveedor';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  timeSession = 0;
  constructor(
    private configService: ConfigService,
  ) {
   }

  getToken(): string {
    return sessionStorage.getItem('_token');
  }

  setToken(token: string): void {
    sessionStorage.setItem('_token', token);
  }

  getTokenExpirationDate(token: string): Date {
    let date = new Date(0);
    if (sessionStorage.getItem('_timeSession') === null) {
      const decoded = jwt_decode(token);

      if (decoded.exp === undefined) { return null; }

      date.setUTCSeconds(decoded.exp);
      sessionStorage.setItem('_timeSession', date.toString());
    } else {
      this.timeSession = this.configService.get('parameters').timeSession;
      date = new Date(sessionStorage.getItem('_timeSession'));

      if ((date.valueOf() > new Date().valueOf())) {
        date = moment(Date.now()).add(this.timeSession, 'm').toDate();
        sessionStorage.setItem('_timeSession', date.toString());
      }
    }

    return date;
  }

  getInfoToken() {
    const token = this.getToken();
    const decoded = jwt_decode(token);
    return (JSON.parse(decoded.unique_name)); // IDPROVEEDOR
  }

  setLoggedUser(proveedor) {
    sessionStorage.setItem('_currentUser', JSON.stringify(proveedor));
    sessionStorage.setItem('_nom_usuario', proveedor != null ?
      proveedor.NOM_USUARIO : '');
  }

  updateLoggedUser(proveedor: Proveedor) {
    sessionStorage.setItem('_currentUser', JSON.stringify(proveedor));
  }

  getLoggedUser() {
    return JSON.parse(sessionStorage.getItem('_currentUser'));
  }

  getNomUser() {
    return sessionStorage.getItem('_nom_usuario') + '';
  }

  setNombre(nombre: string) {
    sessionStorage.setItem('nombre_proveedor', nombre);
  }

  getNombre() {
    return sessionStorage.getItem('nombre_proveedor');
  }

  setLoggedOut() {
    sessionStorage.clear();
  }

  isTokenExpired(token?: string): boolean {
    if (!token) { token = this.getToken(); }
    if (!token) { return true; }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }
}
