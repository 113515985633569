import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TokenService } from 'src/app/authentication/token.service';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private notificationsService: NotificationsService
  ) { }

  canActivate(): boolean {
    return this.checkLogin();
  }

  checkLogin(): boolean {
    const token = sessionStorage.getItem('_token');
    const isExpired = this.tokenService.isTokenExpired(token);


    if (token && !isExpired) {
      return true;
    }

    this.tokenService.setLoggedOut();
    if (token) {
      this.notificationsService.warn('Alerta', 'El tiempo de sesion ha expirado');
    }

    // Navigate to the login page with extras
    this.router.navigate(['/authentication/login']);
    return false;
  }

}
