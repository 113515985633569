import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.sass']
})
export class DatepickerComponent implements OnInit {

  @Input() config: any;
  @Input() form: FormGroup;
  @Input() formDirective: FormGroupDirective;

  defaults: any;
  settings: any;
  isConfigured: boolean;

  ngOnInit() {
    this.defaults = {
      isRange: false,
      ids: ['datepicker'],
      labels: ['Fecha 1'],
      dateFormat: 'yy-mm-dd',
      required: false
    };

    this.settings = Object.assign({}, this.defaults, this.config);
    this.isConfigured = false;
  }

  get f() { return this.form.controls; }

  IsValid(control: string): boolean {
    return this.form.get(control).valid;
  }

  onKey(): boolean {
    return false;
  }

  ConfigureDatePicker() {
    if (!this.isConfigured) {

      if (!this.settings.isRange) {
        this._SingleDatePicker();
      } else {
        this._RangeDatePicker();
      }
      this.isConfigured = true;
    }
  }

  OnDateSelect(event: Event) {
    const el: HTMLInputElement = event.target as HTMLInputElement;
    this.form.get(el.name).setValue(el.value);
  }

  private _SingleDatePicker(): void {
    const el = $('#' + this.settings.ids[0]);
    el.datepicker(
      {
        changeMonth: true,
        changeYear: true,
        dateFormat: this.settings.dateFormat,
        showOtherMonths: true,
        selectOtherMonths: true,
        showButtonPanel: true,
        onSelect(dateText, inst) {

          const evt = document.createEvent('HTMLEvents');
          evt.initEvent('change', false, true);
          inst.input[0].dispatchEvent(evt);
          el.trigger('change');
        }
      }
    );
  }

  private _RangeDatePicker(): void {
    let from: any;
    let to: any;

    const dateFormat = this.settings.dateFormat;

    from = $('#' + this.settings.ids[0]);
    to = $('#' + this.settings.ids[1]);

    from.datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: this.settings.dateFormat,
      showOtherMonths: true,
      selectOtherMonths: true,
      showButtonPanel: true,
      onSelect(dateText, inst) {

        const evt = document.createEvent('HTMLEvents');
        evt.initEvent('change', false, true);
        inst.input[0].dispatchEvent(evt);
        from.trigger('change');
      }
    })
      .on('change', () => {
        to.datepicker(
          'option',
          'minDate',
          $.datepicker.parseDate(dateFormat, from.val())
        );
      });

    to.datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: this.settings.dateFormat,
      showOtherMonths: true,
      selectOtherMonths: true,
      showButtonPanel: true,
      onSelect(dateText, inst) {

        const evt = document.createEvent('HTMLEvents');
        evt.initEvent('change', false, true);
        inst.input[0].dispatchEvent(evt);
        from.trigger('change');
      }
    })
      .on('change', () => {
        from.datepicker(
          'option',
          'maxDate',
          $.datepicker.parseDate(dateFormat, to.val())
        );
      });
  }

}
