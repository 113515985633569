import { Component, OnInit, Input, ɵConsole } from '@angular/core';
import { AuthenticationApiClientService } from '../authentication-api-client.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { Observable, of, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { TokenService } from '../token.service';
import { ConfigService } from 'src/app/app-config.service';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { ProveedorApiClientService } from 'src/app/gestion-proveedor/proveedor-api-client.service';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  @Input() form: FormGroup;

  getProveedorRequest$: Observable<any>;
  loginRequest$: Observable<any>;
  loginForm: FormGroup;
  submitted: boolean;
  submittedPassword: boolean;
  showspinner: boolean;
  showspinnerContrasenia: boolean;
  userInactive: boolean;
  isLoginValid$: Observable<boolean>;
  imgRoot: any;
  cssRoot: any;
  CuentaUsuarioForm: FormGroup;
  Url: string;
  constructor(
    private proveedorApiClient: ProveedorApiClientService,
    private notificationsService: NotificationsService,
    private authApiClient: AuthenticationApiClientService,
    private dataApiClient: DataApiClientService,
    private formBuilder: FormBuilder,
    private router: Router,
    private tokenService: TokenService,
    private configService: ConfigService
  ) {
    this.imgRoot = this.configService.get('paths').images;
    this.cssRoot = this.configService.get('paths').css;
  }

  ngOnInit() {
    this.Url = this.configService.get('parameters').Url;
    this._InitLoginForm();
    this._InitUserAccountForm();
    this.isLoginValid$ = EMPTY;
  }

  login() {
    this.submitted = true;
    this.showspinner = true;
    if (!this.loginForm.valid) {
      this.submitted = false;
      this.showspinner = false;
      return false;
    }

    this.loginRequest$ = this.authApiClient.Login(this.loginForm.getRawValue());
    this.isLoginValid$ = of(true);
    this.loginRequest$.subscribe(
      (response: any) => {
        if (response == null) {
          this.isLoginValid$ = of(false);
          this.showspinner = false;
        } else {
          if (response === 1) {
            this.showspinner = false;
            this.userInactive = true;
          } else {
            this.showspinner = false;
            // Almacena en el session storage
            this.tokenService.setToken(response);
            const infoToken = this.tokenService.getInfoToken();
            // // Almacena la información del proveedor en el session storage
            this.proveedorApiClient.proveedorGetAllData(infoToken.IDPROVEEDOR).subscribe(
              (proveedor) => {
                this.tokenService.setLoggedUser(proveedor);
                // Redirige a la ruta de info básica
                this.router.navigate(['/']);
              });
          }
        }
      },
      (err: any) => {
        this.isLoginValid$ = of(false);
        this.showspinner = false;
      }
    );
  }

  showHidePassword(event, source: string) {
    const inputPassword = $('[name=\'' + source + '\']');
    if (inputPassword.attr('type') === 'password') {
      inputPassword.attr('type', 'text');
    } else {
      inputPassword.attr('type', 'password');
    }
  }

  get f() { return this.CuentaUsuarioForm.controls; }

  _IsValid(control: string): boolean {
    return this.CuentaUsuarioForm.controls[control].valid;
  }

  IsValid(control: string): boolean {
    return this.loginForm.get(control).valid;
  }

  private _InitLoginForm() {
    this.loginForm = this.formBuilder.group({
      Username: ['', [Validators.required]],
      Password: ['', [Validators.required]]
    });
  }

  private _InitUserAccountForm() {
    this.CuentaUsuarioForm = this.formBuilder.group({
      NumeroIdentificacion: ['', [
        Validators.required,
        Validators.maxLength(12)]
      ],
      Email: ['', [Validators.required,
        Validators.pattern(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/)]]
    });
  }

  showModal() {
    $('#ContraseniaModal').modal('show');
  }

  RecordarContrasenia() {
    this.showspinnerContrasenia = true;
    this.submittedPassword = true;

    if (!this.CuentaUsuarioForm.valid) {
      this.showspinnerContrasenia = false;
      return false;
    }

    this.getProveedorRequest$ = this.proveedorApiClient.GetProveedorById(this.CuentaUsuarioForm.controls.NumeroIdentificacion.value);
    this.getProveedorRequest$.subscribe(
      (responseIdUsusario: any) => {
        if (responseIdUsusario.length === 0) {
          this.notificationsService.error('El proveedor ingresado no se encuentra registrado en el sistema.');
          this.showspinnerContrasenia = false;
          this.submittedPassword = false;
          return;
        } else {
          if (responseIdUsusario[0].CORREO.toUpperCase().trim() === this.CuentaUsuarioForm.controls.Email.value.toUpperCase().trim()) {
            const usuario = btoa(this.CuentaUsuarioForm.controls.NumeroIdentificacion.value);
            const recordarContrasena = {
              'NumeroIdentificacion' : this.CuentaUsuarioForm.controls.NumeroIdentificacion.value,
              'Correo' : this.CuentaUsuarioForm.controls.Email.value,
              'UrlFormulario' : this.Url + '#/authentication/olvido-datos' + '?NumeroIdentificacion=' + usuario
            };
            this.dataApiClient.recordarContrasenia(recordarContrasena).subscribe(
              (result: any) => {
                if (result) {
                  this.notificationsService.success('Exito', 'Se ha enviado una notificación al correo electrónico ingresado.');
                  this.showspinnerContrasenia = false;
                  $('#ContraseniaModal').modal('hide');
                } else {
                  this.notificationsService.success('Error', 'Se encontró un problema al enviar notificación al correo ingresado.');
                  this.showspinnerContrasenia = false;
                }
              }
             );
          } else {
            this.notificationsService.error('El correo ingresado no coincide con el número de identificación registrado.');
            this.showspinnerContrasenia = false;
            this.submittedPassword = false;
          }
        }
      }
    );
  }

}
