import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionProveedorRoutingModule } from './gestion-proveedor-routing.module';
import { PreRegistroComponent } from './pre-registro/pre-registro.component';
import { InfoBasicaComponent } from './info-basica/info-basica.component';
import { ProveedorApiClientService } from './proveedor-api-client.service';
import { UbicacionComponent } from './ubicacion/ubicacion.component';
import { DatosUsuarioComponent } from './datos-usuario/datos-usuario.component';
import { CuentaBancariaComponent } from './cuenta-bancaria/cuenta-bancaria.component';
import { ContainersModule } from '../shared/containers';
import { ComponentsModule } from '../shared/components';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { DataApiClientService } from '../shared/utils/data-api-client.service';
import { ProveedorDataService } from './proveedor-data.service';
import { InformacionFinancieraComponent } from './informacion-financiera/informacion-financiera.component';
import { DeclaracionConflictosComponent } from './declaracion-conflictos/declaracion-conflictos.component';
import { CargarDocumentacionComponent } from './cargar-documentacion/cargar-documentacion.component';
import { InfoJuridicaComponent } from './info-juridica/info-juridica.component';
import { PersonaJuridicaComponent } from './persona-juridica/persona-juridica.component';

@NgModule({
  declarations: [
    PreRegistroComponent,
    InfoBasicaComponent,
    UbicacionComponent,
    DatosUsuarioComponent,
    CuentaBancariaComponent,
    InformacionFinancieraComponent,
    DeclaracionConflictosComponent,
    CargarDocumentacionComponent,
    InfoJuridicaComponent,
    PersonaJuridicaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ContainersModule,

    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,

    GestionProveedorRoutingModule
  ],
  providers: [
    ProveedorApiClientService,
    DataApiClientService,
    FormBuilder,
    ProveedorDataService
  ]
})
export class GestionProveedorModule { }
