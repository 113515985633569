export class Ubicacion {
    IDUBICACION: string;
    IDABREVIATURADIR: string;
    NUMERO1: string;
    LETRA1: string;
    NUMERO2: string;
    LETRA2: string;
    NUMERO3: string;
    CONTINUIDAD: string;
    IDCIUDAD: string;
    IDDEPARTAMENTO: string;
    IDPAIS: string;
    TELEFONOFIJO: string;
    USUARIO_CREACION: string;
    USUARIO_ACTUALIZACION: string;
    ESTADO: string;
    CONTACTO: string;

    constructor(IDUBICACION?: string,
        IDABREVIATURADIR?: string,
        NUMERO1?: string,
        LETRA1?: string,
        NUMERO2?: string,
        LETRA2?: string,
        NUMERO3?: string,
        CONTINUIDAD?: string,
        IDCIUDAD?: string,
        IDDEPARTAMENTO?: string,
        IDPAIS?: string,
        TELEFONOFIJO?: string,
        USUARIO_CREACION?: string,
        USUARIO_ACTUALIZACION?: string,
        ESTADO?: string,
        CONTACTO?: string) {

        this.IDUBICACION = (IDUBICACION) ? IDUBICACION : null;
        this.IDABREVIATURADIR = (IDABREVIATURADIR) ? IDABREVIATURADIR : null;
        this.NUMERO1 = (NUMERO1) ? NUMERO1 : null;
        this.LETRA1 = (LETRA1) ? LETRA1 : null;
        this.NUMERO2 = (NUMERO2) ? NUMERO2 : null;
        this.LETRA2 = (LETRA2) ? LETRA2 : null;
        this.NUMERO3 = (NUMERO3) ? NUMERO3 : null;
        this.CONTINUIDAD = (CONTINUIDAD) ? CONTINUIDAD : null;
        this.IDCIUDAD = (IDCIUDAD) ? IDCIUDAD : null;
        this.IDDEPARTAMENTO = (IDDEPARTAMENTO) ? IDDEPARTAMENTO : null;
        this.IDPAIS = (IDPAIS) ? IDPAIS : null;
        this.TELEFONOFIJO = (TELEFONOFIJO) ? TELEFONOFIJO : null;
        this.USUARIO_CREACION = (USUARIO_CREACION) ? USUARIO_CREACION : null;
        this.USUARIO_ACTUALIZACION = (USUARIO_ACTUALIZACION) ? USUARIO_ACTUALIZACION : null;
        this.ESTADO = (ESTADO) ? ESTADO : null;
        this.CONTACTO = (CONTACTO) ? CONTACTO : null;
    }
}
