export class PersonaJuridica {
  IDINFOJURIDICA: number;
  IDPERSONAJURIDICA: number;
  IDTIPOIDENTIFICACION: string;
  NOMBRE: number;
  NUMEROIDENTIFICACION: string;
  PARTEINTERESADA: string;
  PORCENTAJEPARTICIPACION: number;

  constructor(
    IDINFOJURIDICA?: number,
    IDPERSONAJURIDICA?: number,
    IDTIPOIDENTIFICACION?: string,
    NOMBRE?: number,
    NUMEROIDENTIFICACION?: string,
    PARTEINTERESADA?: string,
    PORCENTAJEPARTICIPACION?: number
  ) {
    this.IDINFOJURIDICA = (IDINFOJURIDICA) ? IDINFOJURIDICA : null;
    this.IDPERSONAJURIDICA = (IDPERSONAJURIDICA) ? IDPERSONAJURIDICA : null;
    this.IDTIPOIDENTIFICACION = (IDTIPOIDENTIFICACION) ? IDTIPOIDENTIFICACION : null;
    this.NOMBRE = (NOMBRE) ? NOMBRE : null;
    this.NUMEROIDENTIFICACION = (NUMEROIDENTIFICACION) ? NUMEROIDENTIFICACION : null;
    this.PARTEINTERESADA = (PARTEINTERESADA) ? PARTEINTERESADA : null;
    this.PORCENTAJEPARTICIPACION = (PORCENTAJEPARTICIPACION) ? PORCENTAJEPARTICIPACION : 0;
  }
}


