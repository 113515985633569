import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContainersModule } from '../shared/containers';
import { ComponentsModule } from '../shared/components';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { DataApiClientService } from '../shared/utils/data-api-client.service';
import { ProveedorDataService } from '../gestion-proveedor/proveedor-data.service';
import { CotizacionesRoutingModule } from './cotizaciones-routing.module';
import { ListarCotizacionesComponent } from './listar-cotizaciones/listar-cotizaciones.component';
import { DetalleCotizacionComponent } from './detalle-cotizacion/detalle-cotizacion.component';

@NgModule({
  declarations: [ListarCotizacionesComponent, DetalleCotizacionComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ContainersModule,

    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,

    CotizacionesRoutingModule
  ],
  providers: [
    DataApiClientService,
    FormBuilder,
    ProveedorDataService
  ]
})
export class CotizacionesModule { }
