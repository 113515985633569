import { Component, Input, OnInit } from '@angular/core';
import { DataApiClientService } from '../../shared/utils/data-api-client.service';
import { Observable, of } from 'rxjs';
import { FormGroup, FormGroupDirective, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { ValidationService, MustMatch } from 'src/app/shared/utils/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/app-config.service';

declare var $: any;

@Component({
  selector: 'app-olvido-datos',
  templateUrl: './olvido-datos.component.html',
  styleUrls: ['./olvido-datos.component.sass']
})
export class OlvidoDatosComponent implements OnInit {
  imgRoot: any;

  @Input() form: FormGroup;
  CambioUsuarioForm: FormGroup;
  IdUsario: string;
  submitted: Boolean;

  constructor(
    private dataApiClient: DataApiClientService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService) {
    this.imgRoot = this.configService.get('paths').images;
  }

  ngOnInit() {
    this.submitted = false;
    this.ObtenerUsuario();
    this._InitUserAccountForm();
  }

  get f() { return this.CambioUsuarioForm.controls; }

  IsValid(control: string): boolean {
    return this.CambioUsuarioForm.controls[control].valid;
  }

  showHidePassword(event, source: string) {
    const inputPassword = $('[name=\'' + source + '\']');
    if (inputPassword.attr('type') === 'password') {
      inputPassword.attr('type', 'text');
      inputPassword.next().find('i').removeClass('fa-eye-slash').addClass('fa-eye');
    } else {
      inputPassword.attr('type', 'password');
      inputPassword.next().find('i').removeClass('fa-eye').addClass('fa-eye-slash');
    }
  }
  private _InitUserAccountForm() {
    this.CambioUsuarioForm = this.formBuilder.group({
      Password: ['', [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,12}$/)]],
      RepeatPassword: ['', [Validators.required]]
    },
      {
        validators: [
          MustMatch('Password', 'RepeatPassword')
        ]
      }
    );
  }

  ObtenerUsuario() {
    const numeroIdent: string = atob(this.route.snapshot.queryParamMap.get('NumeroIdentificacion'));
    const listarusuario = {
      'EstadoUsuario': 0,
      'RolUsuario': 0,
      'Usuario': numeroIdent
    };

    this.dataApiClient.ListarUsuario(listarusuario).subscribe(
      (result: any) => {
        if (result) {
          this.IdUsario = result[0].ID;
        }
      }
    );

  }

  CambiarContrasenia() {
    this.submitted = true;

    if (this.CambioUsuarioForm.invalid) {
      return;
    }

    const cambiarContrasena = {
      'IdUsuario': this.IdUsario,
      'NuevaContrasena': this.CambioUsuarioForm.controls.Password.value
    };
    // Valida el usuario y la contrasenia
    this.dataApiClient.CambiarContrasenia(cambiarContrasena).subscribe(
      (result: any) => {
        if (result) {
          this.notificationsService.success('Exito', 'Se actualizó la contraseña con éxito');
          this.resetForm();
          this.router.navigate(['/authentication/login']);
        } else {
          this.notificationsService.success('Error', 'Se encontró un problema al actualizar la contraseña');
        }
      }
    );
  }

  resetForm() {
    $('input').each(function () {
      $($(this)[0]).val('');
    });
    this.CambioUsuarioForm.reset();
  }
}
