import { Component, OnInit } from '@angular/core';
import { ProveedorApiClientService } from '../../../gestion-proveedor/proveedor-api-client.service';
import { Proveedor } from '../../models/proveedor';
import { TipoProveedor, ClaseProveedor, EstadoProveedor } from '../../utils/general-enums-provider.enum';
import { ConfigService } from 'src/app/app-config.service';
import { TokenService } from 'src/app/authentication/token.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { InfoBasica } from '../../models/info-basica';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  ProveedorAuth: Proveedor;
  headerImageRoot: any;
  documentosProveedor$: Observable<any>;
  constructor(
    private proveedorApiClient: ProveedorApiClientService,
    private configService: ConfigService,
    private notificationsService: NotificationsService,
    private tokenService: TokenService,
    private router: Router
  ) {
    this.headerImageRoot = this.configService.get('paths').images;
  }

  ngOnInit() {
    this.validarTratamientoDatos();
  }

  DoLogout() {
    this.tokenService.setLoggedOut();
    this.router.navigate(['/authentication/login']);
  }

  validarTratamientoDatos() {
    this.ProveedorAuth = this.tokenService.getLoggedUser() as Proveedor;
    const claseProveedor = this.ProveedorAuth.IDCLASEPROVEEDOR;
    const tipoProveedor = this.ProveedorAuth.IDTIPOPROVEEDOR;
    const infoCompleta = this.ProveedorAuth.IDINFOBASICA !== null && this.ProveedorAuth.IDINFOFINANCIERA !== null &&
                         this.ProveedorAuth.IDINFOJURIDICA !== null && this.ProveedorAuth.DECLARACIONCONFLICTOS === 1;
    const faltaPolitica = infoCompleta &&  this.ProveedorAuth.AUTORIZACIONDATOS === 0;
    const preregistro = this.ProveedorAuth.IDESTADO === EstadoProveedor.PREREGISTRO;

    if (this.ProveedorAuth !== null) {
      if ($('#dropdownMenuButton').html().trim() === 'nom usuario') {
        this.proveedorApiClient.FindInfoBasica(this.ProveedorAuth.IDPROVEEDOR + '').subscribe(
          (response: InfoBasica) => {
            $('#dropdownMenuButton').html((response[0].PRIMERNOMBRE !== '' && response[0].PRIMERNOMBRE !== undefined &&
            response[0].PRIMERNOMBRE !== null) ? (response[0].PRIMERNOMBRE + ' ' + response[0].PRIMERAPELLIDO) :
            response[0].RAZONSOCIAL);
          }
        );
      }

      this.documentosProveedor$ = this.proveedorApiClient.getDocumentosProveedorAll(this.ProveedorAuth.IDPROVEEDOR).pipe(
        map(items => items.filter(
          d => ((d.MODULO === 'CARGA-DOCUMENTOS' && (tipoProveedor !== TipoProveedor.PROVEEDOR || (
            tipoProveedor === TipoProveedor.PROVEEDOR &&
            (claseProveedor !== null)
           ))) ||
           (d.MODULO === 'CARGA-DOCUMENTOS-CRITICO' && claseProveedor === ClaseProveedor.CRITICO))
           && d.RUTA === null
         ))
      );

      this.documentosProveedor$.subscribe(
        (Response) => {
            let showWaitClasification = false;
            const esProveedor = tipoProveedor === TipoProveedor.PROVEEDOR;
            const esClienteDeudor = tipoProveedor === TipoProveedor.CLIENTE_DEUDOR;
            const clasificado = (esProveedor && claseProveedor !== null) || esClienteDeudor;
            if ( esProveedor || esClienteDeudor) {
              if (faltaPolitica) {
                if (Response.length === 0 && clasificado) {
                    $('#PoliticaModal').modal('show');
                } else {
                  if (!clasificado && preregistro) {
                      $('#confirmRegistroModal').modal('show');
                      showWaitClasification = true;
                  }
                }
              }
            }
        }
      );
    }
  }

  AutorTratDatos() {
    if ($('#cbkAcept').is(':checked')) {
      this.ProveedorAuth.AUTORIZACIONDATOS = 1;
      this.ProveedorAuth.IDESTADO = EstadoProveedor.REGISTRADO;
      this.ProveedorAuth.USUARIO_ACTUALIZACION = this.tokenService.getNomUser();
      this.proveedorApiClient.UpdateProveedor(this.ProveedorAuth).subscribe(
        (result: any) => {
          $('#PoliticaModal').modal('hide');
          this.notificationsService.success('Exito', 'Ya se encuentra registrado como proveedor de pisa');
          this.tokenService.updateLoggedUser(this.ProveedorAuth);
          sessionStorage.setItem('submenu', 'infoBasica');
          this.router.navigate(['/proveedor/info-basica']);
        }
      );
    } else {
      $('#errorAut').html('Para poder continuar, debe aceptar la política de tratamiento de datos');
    }
  }
}
